import { InputText } from '../../../../../components/InputText';
import { Button } from '../../../../../components/Button';
import { UseFormReturn } from '../../../../../hooks';
import { FormEvent } from 'react';
import { FormInputNameCreditCard } from '../../../../../features/userProfile/types';

interface AddCreditCardUIProps
  extends Pick<UseFormReturn, 'formData' | 'formErrors' | 'onChangeFormInput'> {
  onSubmitRegister: (e: FormEvent) => void;
}

export const CreditCardPaymentAddUI = ({
  formData,
  formErrors,
  onChangeFormInput,
  onSubmitRegister,
}: AddCreditCardUIProps) => {
  return (
    <form
      onSubmit={onSubmitRegister}
      className="flex-col items-center w-full space-y-4"
    >
      <div className="mb-[40px]">
        <InputText
          name="number"
          label="Número do cartão"
          placeholder="1234 5678 90123 3456"
          value={formData[FormInputNameCreditCard.number]}
          onChange={(e) =>
            onChangeFormInput(FormInputNameCreditCard.number)(e.target.value)
          }
          error={formErrors.number && formErrors.number[0]}
          inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
        />
      </div>
      <div className="mb-[20px] grid grid-cols-2 gap-5">
        <InputText
          name="date"
          label="Data de vencimento"
          placeholder="MM/AA"
          value={formData[FormInputNameCreditCard.date]}
          onChange={(e) =>
            onChangeFormInput(FormInputNameCreditCard.date)(e.target.value)
          }
          maxLength={5}
          error={formErrors.date && formErrors.date[0]}
          inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
        />

        <InputText
          name="cvv"
          label="CVV"
          placeholder="000"
          value={formData[FormInputNameCreditCard.cvv]}
          onChange={(e) =>
            onChangeFormInput(FormInputNameCreditCard.cvv)(e.target.value)
          }
          error={formErrors.cvv && formErrors.cvv[0]}
          inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
        />
      </div>
      <div className="mb-[20px]">
        <InputText
          name="name"
          label="Nome do títular"
          placeholder="Maria da Silva"
          value={formData[FormInputNameCreditCard.name]}
          onChange={(e) =>
            onChangeFormInput(FormInputNameCreditCard.name)(e.target.value)
          }
          error={formErrors.name && formErrors.name[0]}
          inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
        />
      </div>
      <div className="mb-[20px]">
        <InputText
          name="document"
          label="CPF do títular"
          placeholder="123.456.789-00"
          value={formData[FormInputNameCreditCard.document]}
          onChange={(e) =>
            onChangeFormInput(FormInputNameCreditCard.document)(e.target.value)
          }
          error={formErrors.document && formErrors.document[0]}
          inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
        />
      </div>
      <div className="mb-[0px]">
        <Button
          type="submit"
          title="Adicionar cartão"
          buttonStyle="red"
          size="lg"
          className="w-full"
          disabled={
            formData.number === '' ||
            formData.date === '' ||
            formData.cvv === '' ||
            formData.name === '' ||
            formData.document === ''
          }
        />
      </div>
    </form>
  );
};
