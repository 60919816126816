import React from 'react';
import { Route } from '../../navigation/Route';
import { CreateAdScreen } from './screens/CreateAd';

export const CREATEAD_ROUTES = {
  createAd: '/create-ad',
};
export const CreateAdNavigation = (): JSX.Element => (
  <React.Fragment>
    <Route path={CREATEAD_ROUTES.createAd} exact component={CreateAdScreen} />
  </React.Fragment>
);
