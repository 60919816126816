import React, { InputHTMLAttributes } from 'react';

interface RadioProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  value: string;
  label?: string;
  error?: string | null | undefined;
  className?: string;
  labelClassName?: string;
  inputClassName?: string;
  id?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const RadioInput: React.FC<RadioProps> = ({
  name,
  value,
  label,
  error,
  className = '',
  labelClassName = '',
  inputClassName = '',
  id,
  onChange,
  disabled,
}) => (
  <div className={`flex items-center space-x-2 ${className}`}>
    <input
      id={id || value}
      type="radio"
      name={name}
      value={value}
      disabled={disabled}
      onChange={onChange}
      aria-invalid={error ? 'true' : 'false'}
      className={`form-radio text-red-500 focus:ring-red-500 ${
        error ? 'border-red-500' : ''
      } ${inputClassName}`}
    />
    {label && (
      <label
        htmlFor={id || value}
        className={`text-gray-700 text-sm font-medium ${labelClassName}`}
      >
        {label}
      </label>
    )}
    {error && <p className="text-red-500 text-xs">{error}</p>}
  </div>
);
