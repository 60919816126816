import React, { FormEvent } from 'react';
import { FormInputSearch } from 'src/components/Search/type';
import validators from 'src/helpers/validators';
import useForm from 'src/hooks/useForm';
import { SearchEnterpriseUI } from './ui';

export const SearchEnterpriseScreen: React.FC = (): JSX.Element => {
  const {
    formData: formData,
    formErrors: formErrors,
    setErrors: setError,
    onChangeFormInput: onChangeFormInput,
    isFormValid: isFormValid,
    resetForm: resetForm,
  } = useForm({
    initialData: {
      search: '',
    },
    validators: {
      search: [validators.required],
    },
  });

  const handleOnSubmitSearch = async (e: FormEvent): Promise<void> => {
    e.preventDefault();
    if (isFormValid()) {
      console.log(formData[FormInputSearch.search]);
    } else {
      setError({ [FormInputSearch.search]: ['Deu ruim...'] });
      resetForm();
    }
  };

  return (
    <SearchEnterpriseUI
      formData={formData}
      formErrors={formErrors}
      onChangeFormInput={onChangeFormInput}
      onSubmitSearch={handleOnSubmitSearch}
    />
  );
};
