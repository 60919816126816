import React from 'react';
import { Select } from 'src/components/Select';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
} from 'recharts';
import { InfoCard } from '../components/InfoCard';
import { ChartCard } from '../components/ChartCard';

interface DashboardUIProps {
  revenueData: { name: string; value: number }[];
  performanceData: {
    name: string;
    value: number;
    month: string;
    year: number;
  }[];
  months: string[];
  years: string[];
  sellers: string[];
  selectedMonth: string;
  selectedYear: string;
  selectedSeller: string;
  onMonthChange: (month: string) => void;
  onYearChange: (year: string) => void;
  onSellerChange: (seller: string) => void;
}

export const DashboardUI: React.FC<DashboardUIProps> = ({
  revenueData,
  performanceData,
  months,
  years,
  sellers,
  selectedMonth,
  selectedYear,
  selectedSeller,
  onMonthChange,
  onYearChange,
  onSellerChange,
}) => {
  const monthOptions = months.map((month) => ({ value: month, label: month }));
  const yearOptions = years.map((year) => ({ value: year, label: year }));
  const sellerOptions = sellers.map((seller) => ({
    value: seller,
    label: seller,
  }));

  return (
    <div className="w-full min-h-screen">
      {/* Filtros */}
      <div className="flex flex-wrap gap-4 p-4">
        <div className="">
          <Select
            name="month"
            label="Filtrar por Mês"
            value={selectedMonth}
            onChange={(e) => onMonthChange(e.target.value)}
            options={[{ value: 'Todos', label: 'Todos' }, ...monthOptions]}
            placeholder="Selecione o mês"
            selectClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner border-gray-300"
          />
        </div>

        <div className="">
          <Select
            name="year"
            label="Filtrar por Ano"
            value={selectedYear}
            onChange={(e) => onYearChange(e.target.value)}
            options={[{ value: 'Todos', label: 'Todos' }, ...yearOptions]}
            placeholder="Selecione o ano"
            selectClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner border-gray-300"
          />
        </div>

        <div className="">
          <Select
            name="seller"
            label="Filtrar por Vendedor"
            value={selectedSeller}
            onChange={(e) => onSellerChange(e.target.value)}
            options={[{ value: 'Todos', label: 'Todos' }, ...sellerOptions]}
            placeholder="Selecione ou digite um vendedor"
            selectClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner border-gray-300"
          />
        </div>
      </div>

      {/* Conteúdo */}
      <div className="mx-auto py-6 px-4">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-6">
          <InfoCard title="Total de Vendas" value="R$ 15738.48" isHighlighted />
          <InfoCard title="Usuários Ativos" value={76} isHighlighted />
          <InfoCard title="Pedidos Pendentes" value={42} isHighlighted />
          <InfoCard title="Produtos Vendidos" value={1118} isHighlighted />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <ChartCard title="Receita Mensal">
            <ResponsiveContainer width="100%" height={200}>
              <LineChart data={revenueData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="name"
                  angle={-45}
                  textAnchor="end"
                  tick={{ dx: -10, dy: 10, fontSize: 12 }}
                  height={60}
                  interval={0}
                />
                <YAxis />
                <Tooltip />
                <Line type="monotone" dataKey="value" stroke="#F14A3E" />
              </LineChart>
            </ResponsiveContainer>
          </ChartCard>

          <ChartCard title="Performance dos Vendedores">
            <ResponsiveContainer width="100%" height={200}>
              <BarChart data={performanceData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="name"
                  angle={-45}
                  textAnchor="end"
                  tick={{ dx: -10, dy: 10, fontSize: 12 }}
                  height={60}
                  interval={0}
                />
                <YAxis />
                <Tooltip cursor={{ fill: 'rgba(253, 122, 112, 0.1)' }} />
                <Bar dataKey="value" fill="#F14A3E" />
              </BarChart>
            </ResponsiveContainer>
          </ChartCard>
        </div>
      </div>
    </div>
  );
};
