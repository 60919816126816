import React, { useState } from 'react';
import { Product } from 'src/model/Product';
import { Button } from '../Button';
import { Select } from '../Select';
import { RatingStars2 } from '../StarRating2';
import { toCurrency } from 'src/helpers/currency/toCurrency';

interface ProductInfoProps {
  products: Product;
  quantity: number;
  addCart: () => void;
  quantityChange: (increment: boolean) => void;
}

export const ProductInfo: React.FC<ProductInfoProps> = ({
  products,
  quantity,
  addCart,
  quantityChange,
}) => {
  const [selectedGrade, setSelectedGrade] = useState<string>('');

  const calculateDiscountPercentage = (
    originalPrice: number,
    discountedPrice: number
  ): string => {
    const original = originalPrice;
    const discount = discountedPrice;

    if (original > 0) {
      return ((1 - discount / original) * 100).toFixed(0);
    }
    return '0';
  };

  const installmentValue = (price: number): string => {
    const installmentAmount = (price / 12).toFixed(2);
    return `ou R$ ${price.toLocaleString('pt-BR', { minimumFractionDigits: 2 })} em 12x de R$ ${parseFloat(installmentAmount).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}`;
  };

  const handleSelectGrade = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedGrade(e.target.value);
  };

  console.log('Adicionado ao carrinho:', selectedGrade);
  const limitText = (text: string, maxLength: number) => {
    return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
  };

  const gradeOptions =
    (products.grade &&
      products.grade.map((grade) => ({
        value: grade.value,
        label: limitText(
          `Tamanho: ${grade.atributo} Detalhes: ${grade.value} (${grade.quantity} disponíveis)`,
          50
        ),
        title: `Tamanho: ${grade.atributo} | Detalhes: ${grade.value} | (${grade.quantity} disponíveis)`,
      }))) ||
    [];

  return (
    <div className="p-4 rounded-lg w-full mx-auto bg-white">
      {/* Mais Vendido */}
      {/* <div className="flex items-center gap-2 mb-2">
        <span className="bg-orange-500 text-white text-xs font-bold py-1 px-2 rounded-full">
          MAIS VENDIDO
        </span>
        <span className="text-blue-500 text-xs">8º em Notebooks</span>
      </div> */}

      <h1 className="text-xl font-bold mb-1">{products.name}</h1>
      <div className="flex items-center gap-1 text-sm mb-2">
        {products.ratings && products.ratings.length > 0 ? (
          <>
            <div className="flex items-center">
              <RatingStars2 rating={products.ratings[0].count} />
            </div>
            <span>({products.ratings[0].count})</span>
          </>
        ) : (
          <>
            <div className="flex items-center">
              <RatingStars2 rating={0} />
            </div>
            <span>({0})</span>
          </>
        )}
      </div>
      {products.promotion !== null && products.price.discount !== null ? (
        <>
          <div className="flex items-center text-xl font-bold mb-2">
            <span>{`${toCurrency(products.price.discount)}`}</span>
            <span className="text-gray-500 text-sm line-through font-normal ml-2">{`${toCurrency(products.price.price)}`}</span>
            <span className="text-green-500 text-base ml-2">{`${calculateDiscountPercentage(
              products.price.price,
              products.price.discount
            )}% OFF no PIX`}</span>
          </div>
        </>
      ) : (
        <div className="flex items-center text-xl font-bold mb-2">
          {`${toCurrency(products.price.price)}`}
          {/* <span className="text-green-500 text-base ml-2">{discount}</span> */}
        </div>
      )}

      <div className="text-sm mb-2">
        {installmentValue(products.price.price)}
      </div>
      <div className="bg-blue-100 text-blue-500 text-xs p-2 rounded-md">
        10% OFF no Debito
      </div>
      {products.grade && products.grade.length > 0 && (
        <div className="mt-4">
          <Select
            label="Opções de Produto"
            name="grade"
            value={selectedGrade}
            onChange={handleSelectGrade}
            options={gradeOptions}
            placeholder="Selecione uma opção"
            selectClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
          />
        </div>
      )}

      <div className="mt-4 flex items-center py-5 gap-4">
        <span className="text-lg font-medium text-gray-700">QTD:</span>
        <div className="flex items-center border rounded-md shadow-sm">
          <button
            className="w-7 h-7 flex items-center justify-center bg-gray-100 text-gray-600 hover:bg-gray-200 transition-colors rounded-l-md"
            onClick={() => quantityChange(false)}
          >
            −
          </button>
          <span className="px-4 text-lg font-semibold text-gray-800">
            {quantity}
          </span>
          <button
            className="w-7 h-7 flex items-center justify-center bg-gray-100 text-gray-600 hover:bg-gray-200 transition-colors rounded-r-md"
            onClick={() => quantityChange(true)}
          >
            +
          </button>
        </div>
      </div>

      <div>
        <Button
          title="Comprar"
          type="button"
          className="w-full bg-red-600 hover:bg-[#ef4444] shadow-md"
        />
      </div>
      <div className="mt-4">
        <Button
          title="Add ao Carrinho"
          onClick={() => addCart()}
          type="button"
          buttonStyle="yellow"
          className="w-full"
        />
      </div>
    </div>
  );
};
