import React, { FormEvent } from 'react';
import { InputText } from 'src/components/InputText';
import { Button } from 'src/components/Button';
import AppQrCode from 'src/components/AppQrCode';
import { FormInputAppQrCode } from 'src/features/admin/types';
import { UseFormReturn } from 'src/hooks';
import { NameFiles } from '..';
import { InputFile } from 'src/components/InputFile';
import { QrCodeData } from 'src/model/QrCode';

interface AppQrCodeManagerUIProps
  extends Pick<UseFormReturn, 'formData' | 'formErrors' | 'onChangeFormInput'> {
  formNameFiles?: NameFiles;
  qrCode?: QrCodeData;
  onSubmitRegister: (e: FormEvent) => void;
  onChangeFormFileInput: (
    inputName: string
  ) => (file: File | undefined) => void;
}

export const AppQrCodeManagerUI: React.FC<AppQrCodeManagerUIProps> = ({
  qrCode,
  formData,
  formNameFiles,
  formErrors,
  onSubmitRegister,
  onChangeFormInput,
  onChangeFormFileInput,
}) => {
  const imageRef = React.useRef<HTMLInputElement>(null);
  return (
    <div className="p-4 h-full">
      <form onSubmit={onSubmitRegister} className="space-y-4">
        {/* Campo para a URL da Imagem */}
        <InputFile
          refInput={imageRef}
          name="image"
          label="Upload da Imagem do QR Code:"
          fileName={formNameFiles?.image}
          onChange={(e) =>
            onChangeFormFileInput(FormInputAppQrCode.imageUrl)(
              e.target.files?.[0]
            )
          }
          error={formErrors.image && formErrors.image[0]}
          wrapperClass="file:border file:border-gray-300 file:rounded file:px-4 file:py-2"
        />

        {/* Campo para o Link */}
        <InputText
          name="link"
          label="Link de Redirecionamento"
          placeholder="Insira o link para o redirecionamento"
          value={formData[FormInputAppQrCode.link]}
          onChange={(e) =>
            onChangeFormInput(FormInputAppQrCode.link)(e.target.value)
          }
          error={formErrors[FormInputAppQrCode.link]?.[0]}
          inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
        />

        {/* Botão de Salvar */}
        <Button
          type="submit"
          title="Salvar Alterações"
          buttonStyle="red"
          size="lg"
        />
      </form>

      {/* Pré-visualização do QR Code */}
      <div className="w-[20%] mt-4">
        <h3 className="text-lg font-semibold mb-2">Pré-visualização:</h3>
        {qrCode && <AppQrCode qrCode={qrCode} />}
      </div>
    </div>
  );
};
