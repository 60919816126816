import React from 'react';
// import { Button } from 'src/components/Button';
// import { InputText } from 'src/components/InputText';
import { AuthWrapper } from 'src/features/core/auth/screen/AuthWrapper';
import { toCurrency } from 'src/helpers/currency/toCurrency';
import { Cart } from 'src/model/Cart';

interface CartUIProps {
  cart?: Cart[];
  calculateCartTotal: () => number;
  calculateItemTotal: (price: number, quantity: number) => number;
  onRemoveItem: (id: string) => void;
  goCheckout: (id: string) => void;
  //   onCuponChange: (value: string) => void;
  //   onApplyCupon: () => void;
  //   cupon?: string;
  auth?: boolean;
  title?: string;
  showTitle?: boolean;
}

export const CartUI: React.FC<CartUIProps> = ({
  calculateCartTotal,
  calculateItemTotal,
  onRemoveItem,
  goCheckout,
  //   onCuponChange,
  //   onApplyCupon,
  //   cupon,
  cart,
  auth = true,
  title = 'Meu Carrinho',
  showTitle = true,
}): JSX.Element => {
  // Agrupa os itens do carrinho por loja
  const groupedByStore = cart?.reduce(
    (acc, item) => {
      const storeName = item.store?.name || 'Loja Desconhecida'; // Verificação de segurança
      if (!acc[storeName]) acc[storeName] = [];
      acc[storeName].push(item);
      return acc;
    },
    {} as Record<string, Cart[]>
  );

  const content = (
    <div className="container px-2 py-4 m-4 bg-white rounded-lg  w-full">
      {showTitle && (
        <h2 className="text-3xl font-bold text-gray-800 mb-6 text-center">
          {title}
        </h2>
      )}
      {cart && cart.length > 0 ? (
        <div>
          {/* Renderiza cada loja e seus produtos */}
          {Object.entries(groupedByStore || {}).map(([storeName, products]) => (
            <div
              key={storeName}
              className="mb-6 border border-red-100 p-4 rounded-sm shadow-sm"
            >
              <h2 className="font-semibold text-lg mb-4">
                {storeName || 'Loja Desconhecida'}
              </h2>
              {products.map((item, index) => {
                const priceWithDiscount = item.products.price.discount ?? false;

                return (
                  <div key={index} className="border-b border-red-200 mb-4 p-4">
                    <div className="flex justify-between items-center flex-wrap">
                      <div className="flex items-center">
                        <img
                          src={item.products.imagens[0]}
                          alt={item.products.name}
                          className="w-20 h-20 rounded-lg object-cover mr-4"
                        />
                        <div>
                          <h3 className="font-semibold text-lg text-gray-800">
                            {item.products.name}
                          </h3>
                          <p className="text-sm text-gray-600">
                            <span className="font-medium">Qtde:</span>{' '}
                            <span className="text-black ">{item.quantity}</span>
                          </p>
                          <p className="text-sm text-gray-600 flex flex-col gap-0 sm:gap-2 sm:flex-row">
                            <span className="font-medium">Preço Unitário:</span>{' '}
                            {priceWithDiscount || priceWithDiscount === 0 ? (
                              <span className="text-black flex gap-1 items-center font-semibold">
                                {toCurrency(item.products.price.discount)}
                                <p className="text-gray-500 text-xs line-through font-normal">
                                  {toCurrency(item.products.price.price)}
                                </p>
                              </span>
                            ) : (
                              <span className="text-black font-semibold">
                                {toCurrency(item.products.price.price)}
                              </span>
                            )}
                          </p>
                          <p className="text-sm text-gray-600">
                            <span className="font-medium">Total:</span>{' '}
                            <span className="text-green-600 font-bold">
                              R${' '}
                              {priceWithDiscount || priceWithDiscount === 0
                                ? calculateItemTotal(
                                    item.products.price.discount,
                                    item.quantity
                                  ).toLocaleString('pt-BR', {
                                    minimumFractionDigits: 2,
                                  })
                                : calculateItemTotal(
                                    item.products.price.price,
                                    item.quantity
                                  ).toLocaleString('pt-BR', {
                                    minimumFractionDigits: 2,
                                  })}
                            </span>
                          </p>
                        </div>
                      </div>
                      <div>
                        <button
                          onClick={() => onRemoveItem(item.products.id)}
                          className="bg-red-500 text-white px-4 py-1 mt-3 sm:mt-0 rounded shadow-md hover:bg-red-600 transition duration-200"
                        >
                          Remover
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ))}
          {/* Campo para Cupom e Botão de Aplicar */}
          {/* <div className="grid grid-cols-[1fr_auto] items-center gap-2">
            <InputText
              name="cupon"
              value={cupon}
              onChange={(e) => onCuponChange(e.target.value)}
              placeholder="Insira o Codigo"
              inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
            />
            <Button
              type="button"
              title="Aplicar"
              onClick={onApplyCupon}
              buttonStyle="red"
            />
          </div> */}
          {/* Resumo do Carrinho */}
          <div className="flex flex-col sm:flex-row justify-between items-center mt-6 border-t border-gray-200 pt-4">
            <h3 className="text-xl font-bold text-gray-800">
              Total do Carrinho:{' '}
              <span className="text-green-600">
                R${' '}
                {calculateCartTotal().toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                })}
              </span>
            </h3>
            <button
              onClick={() => goCheckout(cart[0].id)}
              className="bg-red-500 text-white py-2 px-6 rounded-lg shadow-lg hover:bg-red-600 transition duration-200 mt-4 sm:mt-0"
            >
              Finalizar Compra
            </button>
          </div>
        </div>
      ) : (
        <div className="text-center py-10">
          <p className="text-gray-500 text-lg">
            Seu carrinho está vazio. Explore nossos produtos e adicione ao
            carrinho!
          </p>
        </div>
      )}
    </div>
  );

  return auth ? <AuthWrapper>{content}</AuthWrapper> : content;
};
