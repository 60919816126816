import React, { useState, useEffect, useRef } from 'react';
import { Banners } from 'src/model/Banners';

interface BannerProps {
  images: Banners[];
  className?: string;
  interval?: number;
}

const Banner: React.FC<BannerProps> = ({
  images,
  className = '',
  interval = 3000,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const bannerRef = useRef<HTMLDivElement>(null);

  // Automatic image slide change
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, interval);
    return () => clearInterval(timer);
  }, [images, interval]);

  // Manual slide navigation
  const goToNextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const goToPrevSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  return (
    <div
      className={`relative w-full overflow-hidden ${className}`}
      ref={bannerRef}
    >
      {/* Manual Controls */}
      <button
        className="absolute top-1/2 left-2 transform -translate-y-1/2 hidden p-2 rounded"
        onClick={goToPrevSlide}
      >
        &#8249;
      </button>
      <button
        className="absolute top-1/2 right-2 transform -translate-y-1/2 hidden p-2 rounded"
        onClick={goToNextSlide}
      >
        &#8250;
      </button>

      {/* Image Slides */}
      <div
        className="flex transition-transform duration-1000 ease-in-out"
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {images &&
          images.length > 0 &&
          images.map((image, index) => (
            <div
              key={index}
              className=" relative aspect-[18/4] md:aspect-[64/10] w-full md:h-full"
            >
              {image && (
                <a href={image.id} target="_blank" rel="noopener noreferrer">
                  <img
                    src={image.image}
                    alt={image.altText || ''}
                    className="w-full h-full object-cover "
                    loading="lazy"
                  />
                </a>
              )}
            </div>
          ))}
      </div>

      {/* Slide Indicators */}
      <div className="absolute bottom-2 left-1/2 transform -translate-x-1/2 flex space-x-2">
        {images &&
          images.length > 0 &&
          images.map((_, index) => (
            <button
              key={index}
              className={`hidden w-3 h-3 rounded-full ${
                currentIndex === index ? 'bg-blue-500' : 'bg-gray-300'
              }`}
              onClick={() => setCurrentIndex(index)}
            />
          ))}
      </div>
    </div>
  );
};

export default Banner;
