// components/HomeMidleBannerManagerUI.tsx
import React, { FormEvent } from 'react';
import { InputText } from 'src/components/InputText';
import { InputFile } from 'src/components/InputFile';
import { Button } from 'src/components/Button';
import Banner from 'src/components/Banner';
import { FormInputBannerMidleBanner } from 'src/features/admin/types';
import { Banners } from 'src/model/Banners';
import { UseFormReturn } from 'src/hooks';
import { OptionProps, Select } from 'src/components/Select';

export interface NameFiles {
  [key: string]: string;
}
interface HomeMidleBannerManagerUIProps
  extends Pick<UseFormReturn, 'formData' | 'formErrors' | 'onChangeFormInput'> {
  formNameFilesMidle?: NameFiles;
  onSubmitRegister: (e: FormEvent) => void;
  onChangeFormFileInput: (
    inputName: string
  ) => (file: File | undefined) => void;
  //   onEditBanner: (id: string) => void;
  onRemoveBanner: (id: string) => void;
  banners: Banners[];
  categoryMidle: OptionProps[];
}

export const HomeMidleBannerManagerUI: React.FC<
  HomeMidleBannerManagerUIProps
> = ({
  banners,
  formData,
  formNameFilesMidle,
  formErrors,
  categoryMidle,
  onSubmitRegister,
  onChangeFormInput,
  onChangeFormFileInput,
  //   onEditBanner,
  onRemoveBanner,
}) => {
  const imageMidleBanner64 = React.useRef<HTMLInputElement>(null);

  return (
    <div className="mb-4 space-y-2 grid grid-cols-1 ">
      <form onSubmit={onSubmitRegister} className="space-y-4">
        <InputText
          name="altTextMidleBanner"
          label="Texto Alternativo"
          placeholder="Digite o texto alternativo..."
          value={formData[FormInputBannerMidleBanner.altTextMidleBanner]}
          onChange={(e) =>
            onChangeFormInput(FormInputBannerMidleBanner.altTextMidleBanner)(
              e.target.value
            )
          }
          error={
            formErrors.altTextMidleBanner && formErrors.altTextMidleBanner[0]
          }
          inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
        />

        <InputFile
          refInput={imageMidleBanner64}
          name="imageBannerMidle"
          label="Upload da Imagem Utilize uma imagem de tamanho 1152px Larg x 180px Alt"
          fileName={formNameFilesMidle?.imageMidleBanner}
          onChange={(e) =>
            onChangeFormFileInput(FormInputBannerMidleBanner.imageMidleBanner)(
              e.target.files?.[0]
            )
          }
          error={formErrors.imageMidleBanner && formErrors.imageMidleBanner[0]}
        />
        <Select
          name="categoryMidleBanner"
          label="Selecione categoria"
          value={formData[FormInputBannerMidleBanner.categoryMidleBanner]}
          onChange={(e) =>
            onChangeFormInput(FormInputBannerMidleBanner.categoryMidleBanner)(
              e.target.value
            )
          }
          error={
            formErrors.categoryMidleBanner && formErrors.categoryMidleBanner[0]
          }
          options={categoryMidle}
          className=""
          selectClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
          optionClassName=""
        />

        <Button
          type="submit"
          title="Salvar Banner"
          buttonStyle="red"
          size="lg"
        />
      </form>
      {/* Exibição dos banners inseridos */}
      <div className="overflow-x-auto pb-4">
        <div className="grid grid-rows-2 gap-4 auto-cols-[minmax(200px,_1fr)] grid-flow-col">
          {banners.map((banner) => (
            <div
              key={banner.id}
              className="relative border p-4 rounded-lg shadow-md"
            >
              <img
                src={banner.image}
                alt={banner.altText}
                className="w-full h-32 object-contain rounded mb-2"
              />
              <p className="text-center text-sm">{banner.altText}</p>
              <div className="flex justify-between mt-2">
                {/* <button
                onClick={() => onEditBanner(banner.id)}
                className="text-yellow-500"
              >
                Editar
              </button> */}
                <button
                  onClick={() => onRemoveBanner(banner.id)}
                  className="text-red-500"
                >
                  Excluir
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Componente de preview */}
      <div className="w-full">
        {banners.length > 0 ? (
          <Banner images={banners} interval={3000} />
        ) : (
          <p>Nenhuma pré-visualização disponível</p>
        )}
      </div>
    </div>
  );
};
