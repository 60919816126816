import React from 'react';

interface Announcement {
  title: string;
  url: string;
}

interface CardEnterpriseProps {
  logo: string;
  nameEnterprise: string;
  phoneNumber: string;
  address: string;
  announcements: Announcement[];
}

export const CardEnterprise: React.FC<CardEnterpriseProps> = ({
  logo,
  nameEnterprise,
  phoneNumber,
  address,
  announcements,
}): JSX.Element => {
  return (
    <div className="w-full bg-white shadow-sm rounded-lg p-4 ">
      <div className="flex items-center mb-4 gap-3">
        <img
          src={logo}
          alt="Logo"
          className="w-16 h-16 object-contain rounded-full mr-4"
        />
        <h2 className="text-lg font-bold">{nameEnterprise}</h2>
      </div>
      <div className="mb-2 gap-3">
        <p className="text-sm font-bold">
          <span className="font-semibold text-gray-500">Fone:</span>
          {phoneNumber}
        </p>
        <p className="text-sm gap-3 font-bold">
          <span className="font-semibold text-gray-500">Como chegar:</span>
          {address}
        </p>
      </div>
      <div className="flex items-center  gap-3 font-bold">
        <h3 className="font-semibold text-gray-500 text-sm">Anúncios:</h3>
        <p className="">
          {announcements.map((announcement, index) => (
            <span key={index}>
              <a
                href={announcement.url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:underline"
              >
                {announcement.title}
              </a>
              {index < announcements.length - 1 && ', '}
            </span>
          ))}
        </p>
      </div>
    </div>
  );
};
