import React, { FormEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { FormContentTextarea } from 'src/features/admin/types';
import validators from 'src/helpers/validators';
import useForm from 'src/hooks/useForm';
import { PrivacyManager } from 'src/model/PrivacyManager';
import { setLoading } from 'src/redux/loading/loadingSlice';
import { api } from 'src/services/api';
import { PrivacyManagerUI } from './ui';

export const PrivacyManagerScreen: React.FC = () => {
  const dispatch = useDispatch();
  const [id, setId] = useState<string>();

  const {
    formData: formDataPrivacy,
    formErrors: formErrorsPrivacy,
    setErrors: setErrorsPrivacy,
    onChangeFormInput: onChangeFormInputPrivacy,
    isFormValid: isFormValidPrivacy,
    resetForm: resetFormPrivacy,
  } = useForm({
    initialData: {
      [FormContentTextarea.HTML]: '',
    },
    validators: {
      [FormContentTextarea.HTML]: [validators.required],
    },
  });

  const getPrivacyManager = async (): Promise<void> => {
    dispatch(setLoading(true));
    try {
      const response = await api.get<PrivacyManager>(
        '/privacy-policy/find-all'
      );
      if (response.status === 200) {
        const advertisingPolicies: PrivacyManager = response.data;
        onChangeFormInputPrivacy(FormContentTextarea.HTML)(
          advertisingPolicies.description
        );
        setId(advertisingPolicies.id);
      }
    } catch (error) {
      console.error('Erro ao buscar categoria:', error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleRegisterPrivacy = async (e: FormEvent): Promise<void> => {
    e.preventDefault();
    try {
      if (isFormValidPrivacy() && id) {
        dispatch(setLoading(true));
        const payload = {
          id: id,
          description: formDataPrivacy[FormContentTextarea.HTML],
        };
        const response = await api.post('/privacy-policy/update', payload);
        if (response.status === 200 || response.status === 201) {
          toast.success('Política de privacidade atualizada com sucesso!');
          getPrivacyManager();
          resetFormPrivacy();
        } else {
          toast.error('Erro ao atualizar a política de privacidade.');
        }
      } else {
        toast.error('Verifique o conteúdo da política de privacidade.');
      }
    } catch (error) {
      console.error('Erro ao registrar a política de privacidade:', error);
      toast.error('Falha ao atualizar a política, tente novamente mais tarde.');
      setErrorsPrivacy({
        [FormContentTextarea.HTML]: ['Erro ao salvar os dados'],
      });
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    getPrivacyManager();
    // eslint-disable-next-line
  }, []);

  return (
    <PrivacyManagerUI
      formData={formDataPrivacy}
      formErrors={formErrorsPrivacy}
      onChangeFormInput={onChangeFormInputPrivacy}
      onSubmitRegister={handleRegisterPrivacy}
    />
  );
};
