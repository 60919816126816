import React, { useState } from 'react';
interface ProductSlider {
  imagens: string[];
}

export const ProductSlider: React.FC<ProductSlider> = ({
  imagens,
}): JSX.Element => {
  const limitedImages = imagens.slice(0, 5);
  const [activeImg, setActiveImage] = useState(limitedImages[0]);
  const [hoverImg, setHoverImage] = useState<string>();
  const [zoomStyle, setZoomStyle] = useState<React.CSSProperties>({});

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const x = ((e.clientX - rect.left) / rect.width) * 100;
    const y = ((e.clientY - rect.top) / rect.height) * 100;
    setZoomStyle({
      backgroundPosition: `${x}% ${y}%`,
      backgroundSize: '200%', // Ajuste o nível de zoom aqui
    });
  };

  const handleMouseLeave = () => {
    setZoomStyle({});
  };

  return (
    <div className="w-full flex flex-col-reverse md:flex-row justify-around gap-2 ">
      <div className="w-[82px] flex flex-col justify-between space-y-2">
        {limitedImages.map((src, index) => (
          <div
            key={index}
            className="w-20 h-20 flex items-center justify-center bg-white rounded-md shadow-md "
          >
            <img
              src={src}
              alt={`Thumbnail ${index + 1}`}
              className="object-contain w-full h-full rounded-md cursor-pointer transition-transform duration-200 hover:scale-105"
              onClick={() => setActiveImage(src)}
              onMouseEnter={() => setHoverImage(src)}
              onMouseLeave={() => setHoverImage(src)}
            />
          </div>
        ))}
      </div>
      <div
        className="w-full h-[300px] md:w-[800px] md:h-[500px] flex items-center justify-center rounded-md bg-white shadow-lg"
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        style={{
          backgroundImage: `url(${hoverImg || activeImg})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: zoomStyle.backgroundPosition || 'center',
          backgroundSize: zoomStyle.backgroundSize || 'contain',
          ...zoomStyle,
        }}
      ></div>
    </div>
  );
};
