import React from 'react';
import { Route } from 'src/navigation/Route';
import { ProductsByCategoryScreen } from './screens/ProductsByCategory';
import { ProductsFindScreen } from './screens/ProductsFind';

export const PRODUCTSBYCATEGORY_ROUTES = {
  itself: '/products-by-category/:id',
};

export const PRODUCTSFIND_ROUTES = {
  itself: '/products-find/:keyword',
};

export const ProductsSearchNavigation = (): JSX.Element => (
  <React.Fragment>
    <Route
      exact
      path={PRODUCTSBYCATEGORY_ROUTES.itself}
      component={ProductsByCategoryScreen}
      //   isPrivateRoute={true}
    />
    <Route
      exact
      path={PRODUCTSFIND_ROUTES.itself}
      component={ProductsFindScreen}
      //   isPrivateRoute={true}
    />
  </React.Fragment>
);
