import React from 'react';
import { Route } from 'src/navigation/Route';
import { LoginScreen } from './screens/Login';

export const LOGIN_ROUTES = {
  itself: '/login',
};

export const LoginNavigation = (): JSX.Element => (
  <React.Fragment>
    <Route
      exact
      path={LOGIN_ROUTES.itself}
      component={LoginScreen}
      //   isPrivateRoute={true}
    />
  </React.Fragment>
);
