import { AxiosError } from 'axios';
import { Buffer } from 'buffer';
import React, { FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { setItem } from 'src/helpers/common/localStorage';
import validators from 'src/helpers/validators';
import { useDialog } from 'src/hooks/useDialog';
import useForm from 'src/hooks/useForm';
import { Auth } from 'src/model/Auth';
import { api } from 'src/services/api';
import { REACT_APP_AUTH, REACT_APP_USER } from 'src/utils/config';
import { setLoading, useLoading } from '../../../../redux/loading/loadingSlice';

import { FormInputNameLogin } from '../../types';
import { LoginUI } from './ui';

export const LoginScreen: React.FC = (): JSX.Element => {
  const [shouldShowPasswordToText, setShouldShowPasswordToText] =
    useState(false);
  const { loading } = useSelector(useLoading);
  const dispatch = useDispatch();
  const { onToggle } = useDialog();

  const history = useHistory();

  const handleOnGoTo = (href: string): void => {
    history.replace(href);
  };

  const {
    formData: formDataLogin,
    formErrors: formErrorsLogin,
    setErrors: setErrorsLogin,
    onChangeFormInput: onChangeFormInputLogin,
    isFormValid: isFormValidLogin,
    resetForm: resetFormLogin,
  } = useForm({
    initialData: {
      email: '',
      password: '',
    },
    validators: {
      email: [validators.required],
      password: [
        validators.required,
        /*
        validators.minLength(8),
        validators.maxLength(15),
        validators.hasPasswordOnlyNumberCharacteres,
        */
      ],
    },
  });

  const handleOnSubmit = async (e: FormEvent): Promise<void> => {
    console.log(
      formDataLogin[FormInputNameLogin.email],
      formDataLogin[FormInputNameLogin.password],
      isFormValidLogin()
    );
    try {
      e.preventDefault();

      if (isFormValidLogin()) {
        dispatch(setLoading(true));
        await login(
          formDataLogin[FormInputNameLogin.email],
          formDataLogin[FormInputNameLogin.password]
        );
        resetFormLogin();
        handleOnGoTo('/');
        onToggle();
      }
    } catch (error) {
      const err = error as AxiosError;
      if (err.code && err.code === 'ERR_BAD_REQUEST') {
        setErrorsLogin({
          email: ['E-mail ou Senha inválida'],
          password: ['E-mail ou Senha inválida'],
        });
      } else {
        toast.warn('Falha ao realizar login, tentar novamente mais tarde');
      }
    } finally {
      dispatch(setLoading(false));
    }
  };

  const login = async (email: string, password: string): Promise<void> => {
    const payload = Buffer.from(`${email}:${password}`, 'utf8').toString(
      'base64'
    );
    console.log(payload);
    const { data } = await api.post<Auth>(
      '/auth',
      {
        grant_type: 'client_credentials',
      },
      {
        headers: {
          Authorization: `Basic ${payload}`,
        },
      }
    );
    console.log(data);
    setItem(String(REACT_APP_AUTH), data.token);
    setItem(String(REACT_APP_USER), data.user);
  };

  const handleOnTogglePasswordToText = (): void =>
    setShouldShowPasswordToText(!shouldShowPasswordToText);

  //   const handleOnTogglePasswordToText2 = (): void =>
  //     setShouldShowPasswordToText2(!shouldShowPasswordToText2);

  //   const showModal = ({ value, title }: ShouldShowModalProps): void => {
  //     setShouldShowModal(value);
  //     onChangeTitle(title);
  //     onSetVisible(true);
  //   };

  useEffect(() => {
    console.log('aqui');
    if (REACT_APP_AUTH) {
      const storedValue = localStorage.getItem(REACT_APP_AUTH);
      console.log(storedValue);
    }
  }, []);

  return (
    <LoginUI
      state={loading}
      shouldShowPasswordToText={shouldShowPasswordToText}
      formData={formDataLogin}
      formErrors={formErrorsLogin}
      onChangeFormInput={onChangeFormInputLogin}
      // enableLoginPassword={enableLoginPassword}
      // onCheckCPF={onCheckCPF}
      onSubmit={handleOnSubmit}
      onTogglePasswordToText={handleOnTogglePasswordToText}
    />
  );
};
