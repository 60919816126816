import React, { FormEvent, useState } from 'react';
import { Button } from 'src/components/Button';
import { InputFile } from 'src/components/InputFile';
import { InputText } from 'src/components/InputText';
import { Select } from 'src/components/Select';
import { AuthWrapper } from 'src/features/core/auth/screen/AuthWrapper';
import { MultiSelect } from 'src/components/SelectMulti';
import { UseFormReturn } from 'src/hooks';
import { NameFiles } from 'src/features/createAccount/screens/CreateAccount/ui';

interface CreateAdUIProps
  extends Pick<UseFormReturn, 'formData' | 'formErrors' | 'onChangeFormInput'> {
  state: boolean;
  shouldShowPasswordToText: boolean;
  shouldShowPasswordToText2: boolean;
  formNameFiles?: NameFiles;
  onChangeCEP: (value: string) => void;
  onTogglePasswordToText: () => void;
  onTogglePasswordToText2: () => void;
  //   onShouldShowModal: ({ value, title }: ShouldShowModalProps) => void;
  onSubmitRegister: (e: FormEvent) => void;
  onChangeFormFileInput: (
    inputName: string
  ) => (file: File | undefined) => void;
}

export const CreateAdUI: React.FC<CreateAdUIProps> = ({
  state,
  formData,
  formErrors,
  formNameFiles,
  onChangeFormInput,
  onSubmitRegister,
  onChangeFormFileInput,
}) => {
  const [previewImage, setPreviewImage] = useState<string | undefined>();

  const adTypeOptions = [
    { value: 'product', label: 'Produto' },
    { value: 'service', label: 'Serviço' },
  ];

  const categoryOptions = [
    { value: 'electronics', label: 'Eletrônico' },
    { value: 'food', label: 'Comida' },
    { value: 'home', label: 'Casa' },
    { value: 'real_estate', label: 'Imóvel' },
  ];

  const tagOptions = [
    { value: 'new', label: 'Novo' },
    { value: 'used', label: 'Usado' },
    { value: 'promo', label: 'Promoção' },
    { value: 'limited', label: 'Edição Limitada' },
  ];

  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const handleCategoryChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedValue = event.target.value;
    if (selectedValue && !selectedCategories.includes(selectedValue)) {
      setSelectedCategories([...selectedCategories, selectedValue]);
    }
  };

  const removeCategory = (category: string) => {
    setSelectedCategories(
      selectedCategories.filter((item) => item !== category)
    );
  };

  const handleTagChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    if (selectedValue && !selectedTags.includes(selectedValue)) {
      setSelectedTags([...selectedTags, selectedValue]);
    }
  };

  const removeTag = (tag: string) => {
    setSelectedTags(selectedTags.filter((item) => item !== tag));
  };

  // Função para lidar com o upload de imagem e atualização da pré-visualização
  const handleImageUpload = (file?: File) => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    } else {
      setPreviewImage(undefined);
    }
    // Atualiza o campo de imagem no formulário (se necessário)
    onChangeFormFileInput('image')(file);
  };

  return (
    <AuthWrapper>
      <section className="flex flex-wrap items-center justify-between w-full container m-4 bg-white p-4 rounded-lg shadow-sm">
        <form
          onSubmit={onSubmitRegister}
          className="flex-col items-center w-full space-y-4"
        >
          <h1 className="text-gray-800 text-4xl font-bold mb-8 text-center">
            Cadastro de Anúncio
          </h1>

          {/* Título do Anúncio */}
          <InputText
            name="title"
            label="Título do Anúncio *"
            placeholder="Digite o título do anúncio..."
            maxLength={100}
            value={formData.title}
            onChange={(e) => onChangeFormInput('title')(e.target.value)}
            error={formErrors.title && formErrors.title[0]}
            inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
          />

          {/* Descrição */}
          <InputText
            name="description"
            label="Descrição *"
            placeholder="Descreva o produto ou serviço..."
            maxLength={500}
            value={formData.description}
            onChange={(e) => onChangeFormInput('description')(e.target.value)}
            error={formErrors.description && formErrors.description[0]}
            inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
          />

          {/* Tipo de Anúncio */}
          <Select
            name="adType"
            label="Tipo de Anúncio *"
            value={formData.adType}
            onChange={(e) => onChangeFormInput('adType')(e.target.value)}
            error={formErrors.adType && formErrors.adType[0]}
            options={adTypeOptions}
            selectClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
          />

          {/* Categorias */}
          <MultiSelect
            label="Categorias *"
            options={categoryOptions}
            selectedValues={selectedCategories}
            onChange={handleCategoryChange}
            onRemove={removeCategory}
            selectClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
          />

          {/* Tags */}
          <MultiSelect
            label="Tags *"
            options={tagOptions}
            selectedValues={selectedTags}
            onChange={handleTagChange}
            onRemove={removeTag}
            selectClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
          />

          {/* Preço */}
          <InputText
            name="price"
            label="Preço *"
            placeholder="Digite o preço..."
            value={formData.price}
            onChange={(e) => onChangeFormInput('price')(e.target.value)}
            error={formErrors.price && formErrors.price[0]}
            inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
          />

          {/* Imagem */}
          <InputFile
            name="image"
            label="Imagem do Produto *"
            fileName={formNameFiles?.image}
            onChange={(e) => handleImageUpload(e.target.files?.[0])}
            error={formErrors.image && formErrors.image[0]}
          />

          {previewImage && (
            <div className="flex justify-center mt-4">
              <img
                src={previewImage}
                alt="Pré-visualização"
                className="max-w-xs rounded-md shadow-lg"
              />
            </div>
          )}

          <div className="w-full flex justify-center">
            <Button
              type="submit"
              title="Cadastrar Anúncio"
              buttonStyle="red"
              size="xlg"
              className="w-[80%]"
              disabled={
                formData.title === '' ||
                formData.description === '' ||
                formData.adType === '' ||
                selectedCategories.length === 0 ||
                formData.price === '' ||
                state
              }
            />
          </div>
        </form>
      </section>
    </AuthWrapper>
  );
};
