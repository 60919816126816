import React, { FormEvent } from 'react';
import { InputText } from '../InputText';
import { FormInputSearch } from './type';
import { ButtonOnlyIcon } from '../ButtonOnlyIcon';
import { UseFormReturn } from 'src/hooks';

interface SearchProps
  extends Pick<UseFormReturn, 'formData' | 'formErrors' | 'onChangeFormInput'> {
  onSubmitSearch: (e: FormEvent) => void;
  icon: React.ReactNode;
  iconClassName?: string;
}

export const Search: React.FC<SearchProps> = ({
  formData,
  formErrors,
  onChangeFormInput,
  onSubmitSearch,
  icon,
  iconClassName = '',
}): JSX.Element => {
  return (
    <form
      onSubmit={onSubmitSearch}
      className="flex items-center justify-center bg-white border rounded-full p-1 px-2 w-full h-full shadow-md"
    >
      <InputText
        name="search"
        placeholder="Buscar..."
        value={formData[FormInputSearch.search]}
        onChange={(e) =>
          onChangeFormInput(FormInputSearch.search)(e.target.value)
        }
        error={formErrors.search && formErrors.search[0]}
        className="border-none focus:outline-none w-full h-auto bg-transparent"
      />
      <ButtonOnlyIcon
        icon={React.cloneElement(icon as React.ReactElement, {
          className: `w-[90%] h-auto scale-zoom-2 ${iconClassName}`,
        })}
        className=""
        buttonStyle="text"
        size="sm"
        type="submit"
      />
    </form>
  );
};
