import React from 'react';
import { Submenu } from '../Submenu';
import { ListMethodsPayments } from 'src/features/core/auth/components/ListMethodsPayments';

interface FooterNewProps {
  onLinkClick: (href: string) => void;
}

export const FooterNew: React.FC<FooterNewProps> = ({
  onLinkClick,
}): JSX.Element => (
  <div className="w-full bg-[#F14A3E] flex flex-col items-center justify-center p-4 mt-4">
    <div className="w-full flex flex-wrap md:justify-around justify-between gap-2">
      <div>
        <h3 className="font-bold text-white text-lg mb-2">Contato</h3>
        <Submenu
          links={[
            {
              text: 'Fale Conosco',
              href: 'https://api.whatsapp.com/send/?phone=5527999311873&text&type=phone_number&app_absent=0',
            },
            { text: 'Elogios', href: '/elogio' },
            { text: 'Sugestões', href: '/suggestion' },
            { text: 'Reclamações', href: '/reclamation' },
          ]}
          onLinkClick={onLinkClick}
          className="flex flex-col  h-auto gap-2  "
          liClassName="scale-zoom-1 text-white hover:text-black "
        />
      </div>

      <div>
        <h3 className="font-bold text-white text-lg mb-2">Segurança</h3>
        <Submenu
          links={[
            {
              text: 'Políticas de Anúncio',
              href: '/advertising',
            },
            {
              text: 'LGPD',
              href: 'https://www.planalto.gov.br/ccivil_03/_ato2015-2018/2018/lei/l13709.htm',
            },
            {
              text: 'Política de Privacidade',
              href: '/privacy-policy',
            },
          ]}
          onLinkClick={onLinkClick}
          className="flex flex-col  h-auto gap-2 "
          liClassName="scale-zoom-1 text-white hover:text-black "
        />
      </div>

      <div>
        <h3 className="font-bold text-white text-lg mb-2">Acessos</h3>

        <Submenu
          links={[
            {
              text: 'Tutorial',
              href: '/tutorial',
            },
            { text: 'Loja Oficial Buunpo', href: '/' },
          ]}
          onLinkClick={onLinkClick}
          className="flex flex-col  h-auto gap-2 "
          liClassName="scale-zoom-1 text-white hover:text-black "
        />
      </div>

      <div>
        <h3 className="font-bold text-white text-lg mb-2">Redes Sociais</h3>

        <Submenu
          links={[
            {
              text: 'Instagram',
              href: 'https://www.instagram.com/buunpo/',
            },
            { text: 'Facebook', href: '/' },
            {
              text: 'YouTube',
              href: 'https://www.youtube.com/@buunpo?si=-qKz3qrD6hJlLzf5',
            },
          ]}
          onLinkClick={onLinkClick}
          className="flex flex-col  h-auto gap-2"
          liClassName="scale-zoom-1 text-white hover:text-black "
        />
      </div>
      <div className="w-full md:max-w-[250px]">
        <h3 className="font-bold text-white text-lg mb-2">Pagamanentos</h3>
        <ListMethodsPayments />
      </div>
    </div>
    <div className="w-[90%]  border-t border-gray-400 mt-5 mb-5"></div>

    <div className="flex flex-col items-center justify-center text-white ">
      <h3>
        BUUP - Todos os Direitos Reservados BUUNPO | CNPJ 22.772.854/0001-29
      </h3>
      <h3>São Gabriel da Palha - ES | CEP: 29780-000</h3>
    </div>
  </div>
);
