import React, { useState } from 'react';

// Interface para os itens do pedido
interface OrderItem {
  id: string;
  productName: string;
  quantity: number;
  price: string;
  imageUrl?: string; // Opcional
}

// Interface para os dados do destinatário
interface Recipient {
  name: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  phone: string;
}

// Interface para o pedido completo
interface OrderGroup {
  orderId: string;
  recipient: Recipient;
  items: OrderItem[];
}

const orderData: OrderGroup = {
  orderId: 'BR2410-5GXP72',
  recipient: {
    name: 'Lucas Henrique Rosa',
    address: 'Rua Engenheiro Vagner Banheti, 98, Residencial Santa Paula',
    city: 'Jacareí',
    state: 'São Paulo',
    zipCode: '12302228',
    phone: '(+55) 12 98222 8440',
  },
  items: [
    {
      id: '1',
      productName: 'Proteína Texturizada de Soja Vegana 1kg',
      quantity: 2,
      price: 'R$ 34,90',
      imageUrl: 'https://via.placeholder.com/100', // Exemplo de imagem
    },
    {
      id: '2',
      productName: 'Gengibre Moído Importado 250g',
      quantity: 1,
      price: 'R$ 12,90',
      imageUrl: 'https://via.placeholder.com/100',
    },
  ],
};

const OrderPreparation: React.FC = () => {
  const { orderId, recipient, items } = orderData;
  const [orderStatus, setOrderStatus] = useState<
    'Aguardando' | 'Preparando' | 'Enviado' | 'Cancelado'
  >('Aguardando'); // Status inicial "Aguardando"

  // Função para definir a cor de acordo com o status
  const getStatusColor = (status: string) => {
    switch (status) {
      case 'Aguardando':
        return 'text-yellow-500';
      case 'Preparando':
        return 'text-blue-500';
      case 'Enviado':
        return 'text-green-500';
      case 'Cancelado':
        return 'text-red-500';
      default:
        return 'text-black';
    }
  };

  return (
    <div className="w-full border-b border-red-200 hover:border-red-500 p-4 mb-4 bg-white">
      {/* Status do Pedido */}
      <div className="mb-4 flex justify-between items-center">
        <h2 className="text-lg font-semibold">Preparação do Pedido</h2>
        <p className="text-sm text-black">
          Status:{' '}
          <span className={`font-bold ${getStatusColor(orderStatus)}`}>
            {orderStatus}
          </span>
        </p>
      </div>

      {/* Grupo do Pedido */}
      <div className="mb-4">
        <h3 className="text-sm font-medium text-gray-700">Grupo do Pedido:</h3>
        <p className="text-base font-semibold text-gray-900">{orderId}</p>
      </div>

      {/* Dados do Destinatário */}
      <div className="mb-6">
        <h3 className="text-sm font-medium text-gray-700">Destinatário:</h3>
        <p className="text-base font-semibold text-gray-900">
          {recipient.name}
        </p>
        <p className="text-sm text-gray-600">
          {recipient.address}, {recipient.city} - {recipient.state},{' '}
          {recipient.zipCode}
        </p>
        <p className="text-sm text-gray-600">Telefone: {recipient.phone}</p>
      </div>

      {/* Lista de Itens do Pedido */}
      <div>
        <h3 className="text-sm font-medium text-gray-700 mb-2">
          Itens do Pedido:
        </h3>
        {items.map((item) => (
          <div
            key={item.id}
            className="flex items-center justify-between p-4 mb-4 border border-gray-100 rounded-md"
          >
            <div className="flex items-center">
              {item.imageUrl && (
                <img
                  src={item.imageUrl}
                  alt={item.productName}
                  className="w-16 h-16 object-cover rounded-sm mr-4"
                />
              )}
              <div>
                <h4 className="font-medium">{item.productName}</h4>
                <p className="text-sm text-gray-500">
                  Quantidade: {item.quantity}
                </p>
              </div>
            </div>
            <div className="text-sm font-semibold text-gray-700">
              {item.price}
            </div>
          </div>
        ))}
      </div>

      {/* Botões para Alterar o Status */}
      <div className="mt-6 flex justify-end space-x-2">
        <button
          className="px-4 py-2 border border-blue-500 text-blue-500 rounded hover:border-blue-600 hover:bg-[#2282ff75] hover:text-white"
          onClick={() => setOrderStatus('Preparando')}
        >
          Preparando
        </button>
        <button
          className="px-4 py-2 border border-green-500 text-green-500 rounded hover:border-green-600 hover:bg-[#22ff5275] hover:text-white"
          onClick={() => setOrderStatus('Enviado')}
        >
          Enviado
        </button>
        <button
          className="px-4 py-2 border border-red-500 text-red-500 rounded hover:border-red-600 hover:bg-[#ff222275] hover:text-white"
          onClick={() => setOrderStatus('Cancelado')}
        >
          Cancelar
        </button>
      </div>
    </div>
  );
};

export default OrderPreparation;
