import React from 'react';
import { Route } from 'src/navigation/Route';
import { CartScreen } from './screens/Cart';
import { CheckoutScreen } from './screens/Checkout';

export const CHECKOUT_ROUTES = {
  itself: '/checkout',
  carts: '/carts',
};

export const CheckoutNavigation = (): JSX.Element => (
  <React.Fragment>
    <Route
      exact
      path={CHECKOUT_ROUTES.itself}
      component={CheckoutScreen}
      isPrivateRoute={true}
    />
    <Route
      exact
      path={CHECKOUT_ROUTES.carts}
      component={CartScreen}
      isPrivateRoute={true}
    />
  </React.Fragment>
);
