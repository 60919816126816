import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as CellIcon } from '../../assets/icons/cell.svg';
import { Dropdown2 } from '../Dropdown2';
import { QrCodeData } from 'src/model/QrCode';

interface AppQrCodeProps {
  qrCode: QrCodeData;
}

const AppQrCode: React.FC<AppQrCodeProps> = ({ qrCode }) => {
  return (
    <Link to={qrCode.link}>
      <Dropdown2
        triggerContent={
          <div className="flex items-center space-x-2">
            <CellIcon className="w-6 h-6" />
            <span className="text-white text-sm font-semibold">
              Baixe o App
            </span>
          </div>
        }
      >
        <img src={qrCode.imageUrl} alt="QR Code" />
      </Dropdown2>
    </Link>
  );
};

export default AppQrCode;
