// components/HomePrimaryBannerManagerUI.tsx
import React, { FormEvent } from 'react';
import { Button } from 'src/components/Button';
import { CarouselBannerUI } from 'src/components/CarouselBanner';
import { InputFile } from 'src/components/InputFile';
import { InputText } from 'src/components/InputText';
import { OptionProps, Select } from 'src/components/Select';
import { FormInputBannerHome } from 'src/features/admin/types';
import { UseFormReturn } from 'src/hooks';
import { Banners } from 'src/model/Banners';
import { NameFiles } from '..';

interface HomePrimaryBannerManagerUIProps
  extends Pick<UseFormReturn, 'formData' | 'formErrors' | 'onChangeFormInput'> {
  formNameFilesBannerHome?: NameFiles;
  onSubmitRegister: (e: FormEvent) => void;
  onChangeFormFileInput: (
    inputName: string
  ) => (file: File | undefined) => void;
  //   onEditBanner: (id: string) => void;
  onRemoveBanner: (id: string) => void;
  banners: Banners[];
  category: OptionProps[];
}

export const HomePrimaryBannerManagerUI: React.FC<
  HomePrimaryBannerManagerUIProps
> = ({
  banners,
  formData,
  formNameFilesBannerHome,
  formErrors,
  category,
  onSubmitRegister,
  onChangeFormInput,
  onChangeFormFileInput,
  //   onEditBanner,
  onRemoveBanner,
}) => {
  const imageBase64Ref = React.useRef<HTMLInputElement>(null);

  return (
    <div className="mb-4 space-y-2 grid grid-cols-1 ">
      <form onSubmit={onSubmitRegister} className="space-y-4">
        <InputText
          name="altTextBannerHome"
          label="Texto Alternativo"
          placeholder="Digite o texto alternativo..."
          value={formData[FormInputBannerHome.altTextBannerHome]}
          onChange={(e) =>
            onChangeFormInput(FormInputBannerHome.altTextBannerHome)(
              e.target.value
            )
          }
          error={
            formErrors.altTextBannerHome && formErrors.altTextBannerHome[0]
          }
          inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
        />

        <InputFile
          refInput={imageBase64Ref}
          name="imageBannerHome"
          label="Upload da Imagem Utilize uma imagem de tamanho 1920px Larg x 720px Alt"
          fileName={formNameFilesBannerHome?.imageBannerHome}
          onChange={(e) =>
            onChangeFormFileInput(FormInputBannerHome.imageBannerHome)(
              e.target.files?.[0]
            )
          }
          error={formErrors.imageBannerHome && formErrors.imageBannerHome[0]}
          wrapperClass="file:border file:border-gray-300 file:rounded file:px-4 file:py-2"
        />

        <Select
          name="categoryBannerHome"
          label="Selecione categoria"
          value={formData[FormInputBannerHome.categoryBannerHome]}
          onChange={(e) =>
            onChangeFormInput(FormInputBannerHome.categoryBannerHome)(
              e.target.value
            )
          }
          error={
            formErrors.categoryBannerHome && formErrors.categoryBannerHome[0]
          }
          options={category}
          className=""
          selectClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
          optionClassName=""
        />

        <Button
          type="submit"
          title="Salvar Banner"
          buttonStyle="red"
          size="lg"
        />
      </form>

      {/* Exibição dos banners inseridos */}
      <div className="overflow-x-auto pb-4">
        <div className="grid grid-rows-2 gap-4 auto-cols-[minmax(200px,_1fr)] grid-flow-col">
          {banners &&
            banners.map((banner) => (
              <div
                key={banner.id}
                className="relative border p-4 rounded-lg shadow-md"
              >
                <img
                  src={banner.image}
                  alt={banner.altText}
                  className="w-full h-32 object-contain rounded mb-2"
                />
                <p className="text-center text-sm">{banner.altText}</p>
                <div className="flex justify-between mt-2">
                  {/* <button
                onClick={() => onEditBanner(banner.id)}
                className="text-yellow-500"
              >
                Editar
              </button> */}
                  <button
                    onClick={() => onRemoveBanner(banner.id)}
                    className="text-red-500"
                  >
                    Excluir
                  </button>
                </div>
              </div>
            ))}
        </div>
      </div>

      {/* Componente Carousel exibindo os banners */}
      <div className="w-full ">
        {CarouselBannerUI && (
          <CarouselBannerUI
            slides={banners}
            hideIndicators={false}
            hideControls={false}
            className="aspect-[4/2] md:aspect-[10/3]"
          />
        )}
      </div>
    </div>
  );
};
