import React from 'react';
// import { ProductCard3 } from 'src/components/ProductCard3';
// import { ProfileSideMenu } from 'src/components/ProfileSideMenu';
// import { ProductCard2 } from 'src/components/ProductCard2';
import { AuthWrapper } from 'src/features/core/auth/screen/AuthWrapper';
import { SidebarProductScreen } from 'src/features/storeProfile/components/StoreProfileSideMenu';
import { Product } from 'src/model/Product';

type StoreProfileUIProps = {
  storeName: string;
  productCount: number;
  products: Product[];
};
export const StoreProfileUI: React.FC<StoreProfileUIProps> = ({
  storeName,
  products,
}) => {
  //   const productList: Product[] = [];

  //   const menuItems = [
  //     {
  //       title: 'Todos Produtos',
  //       content: (
  //         <div className="grid grid-cols-[repeat(auto-fit,minmax(220px,1fr))]  gap-y-4 w-full">
  //           {productList.map((product) => (
  //             <ProductCardOnly key={product.id} product={product} />
  //           ))}
  //         </div>
  //       ),
  //     },
  //     {
  //       title: 'Eletronicos',
  //       content: <div>Este é o conteúdo para Eletrônicos.</div>,
  //     },
  //     {
  //       title: 'Casa',
  //       content: <div>Este é o conteúdo para Casa.</div>,
  //     },
  //     {
  //       title: 'Cosméticos',
  //       content: <div>Este é o conteúdo para Cosméticos.</div>,
  //     },
  //   ];
  return (
    <AuthWrapper>
      <div className="container p-0 m-4 bg-white rounded-lg shadow-sm w-full">
        {/* Banner */}
        <div className="relative bg-blue-500 h-36 sm:h-64 flex items-center justify-center rounded-t-lg">
          <div className="absolute inset-0 bg-[url('https://via.placeholder.com/1280x256')] bg-cover bg-center opacity-20"></div>
          <img
            src="https://via.placeholder.com/100"
            alt={`${storeName} Logo`}
            className="w-32 h-32 rounded border-4 border-white shadow-lg absolute top-[45%] sm:top-[65%]"
          />
        </div>

        {/* Store Info */}
        <div className="flex flex-col items-center justify-center align-middle mt-16 text-center ">
          <div className="w-[90%] sm:w-[60%] justify-center items-center rounded  bg-[#eeeeee86] grid grid-cols-3 grid-rows-1 px-2 py-4">
            <div>87.0% Avaliação</div>
            <div>Starline Smart Store</div>
            <div>5.1K Vendas</div>
          </div>
          <div className=" mt-6 ">
            <span>descrição</span>
          </div>
        </div>

        <div className="w-full">
          {/* <ProfileSideMenu subTitle="Categorias" menuItems={menuItems} /> */}
          <SidebarProductScreen products={products} />
        </div>
      </div>
    </AuthWrapper>
  );
};
