import React from 'react';
import { Route } from '../../navigation/Route';
import { CreateProductScreen } from './screens/CreateProduct';

export const CREATEPRODUCT_ROUTES = {
  createProduct: '/create-product',
};
export const CreateProductNavigation = (): JSX.Element => (
  <React.Fragment>
    <Route
      path={CREATEPRODUCT_ROUTES.createProduct}
      exact
      component={CreateProductScreen}
      isPrivateRoute={true}
    />
  </React.Fragment>
);
