// src/components/CategorySelector.tsx
import React from 'react';
import { Category } from 'src/model/Category';

interface CategorySelectorProps {
  categorys: Category[];
  selectedCategories: Category[];
  error?: string | null;
  selectClassName?: string;
  styleCategory?: string;
  selectCategory: (e: React.FocusEvent<HTMLSelectElement>) => void;
  removeCategory: (value: string) => void;
}

const CategorySelector: React.FC<CategorySelectorProps> = ({
  categorys,
  selectedCategories,
  error,
  styleCategory,
  selectCategory,
  removeCategory,
}) => {
  return (
    <div className="w-full">
      {/* Renderização condicional do rótulo */}
      <label className="block text-gray-500 text-base font-dmsans font-regular mb-2">
        Categorias*
      </label>

      <select
        aria-invalid={error ? 'true' : 'false'}
        onChange={selectCategory}
        defaultValue=""
        className={`rounded-lg p-2 w-full bg-[#F8F8F8] shadow-inner appearance-none focus:outline-none ${
          error ? 'border-red-500' : 'border-none'
        } ${styleCategory}`}
        style={{ backgroundImage: 'none' }}
      >
        <option value="" disabled>
          Selecione uma categoria...
        </option>
        {categorys.map((cat) => (
          <option key={cat.id} value={cat.id}>
            {cat.name}
          </option>
        ))}
      </select>
      {error && <p className="text-red-500 text-xs mt-1">{error}</p>}

      <div className="flex flex-wrap mt-4">
        {selectedCategories.map((category) => (
          <div
            key={category.id}
            className="bg-gray-200 rounded-lg px-3 py-1 m-1 flex items-center space-x-2"
          >
            <span>{category.name}</span>
            <button
              type="button"
              onClick={() => removeCategory(category.id)}
              className="text-red-500 hover:text-red-700"
            >
              ✕
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CategorySelector;
