import classNames from 'classnames';
import React, { FormEvent } from 'react';
import { IconEye, IconEyeClose } from 'src/assets/icons';
import { Button } from 'src/components/Button';
import { InputFile } from 'src/components/InputFile';
import { InputText } from 'src/components/InputText';
import { FormInputNameRegister } from 'src/features/core/auth/types';
import { UseFormReturn } from 'src/hooks';

// Interface para os dados do usuário

export interface NameFiles {
  [key: string]: string;
}
export interface UserProfileManagerUIProps
  extends Pick<UseFormReturn, 'formData' | 'formErrors' | 'onChangeFormInput'> {
  formNameFiles?: NameFiles;
  shouldShowPasswordToText: boolean;
  shouldShowPasswordToText2: boolean;
  onSubmitRegister: (e: FormEvent) => void;
  onTogglePasswordToText: () => void;
  onTogglePasswordToText2: () => void;
  onChangeFormFileInput: (
    inputName: string
  ) => (file: File | undefined) => void;
  selectedImage?: string;
  isEditingImage: boolean;
  onToggleEdit: () => void;
}
export const UserProfileManagerUI: React.FC<UserProfileManagerUIProps> = ({
  formData,
  formErrors,
  formNameFiles,
  isEditingImage,
  selectedImage,
  shouldShowPasswordToText,
  shouldShowPasswordToText2,
  onToggleEdit,
  onChangeFormInput,
  onSubmitRegister,
  onChangeFormFileInput,
  onTogglePasswordToText,
  onTogglePasswordToText2,
}) => {
  const imageBase64Ref = React.useRef<HTMLInputElement>(null);

  // export const UserProfileManagerUI: React.FC<User> = ({ userData }) => {
  //   const user = {
  //     username: userData.name,
  //     name: userData.name,
  //     email: userData.email,
  //     phone: userData.cellPhone,
  //     cpf: userData.cpf,
  //     birthDate: dayjs(userData.birthDate).locale('pt-br').format('DD MMM AA'),
  //     verified: true,
  //     profileImageUrl: userData.imageBase64,
  //   };

  //   const [selectedProfileImage, setSelectedProfileImage] = useState<File | null>(
  //     null
  //   );

  //   // Função para selecionar a imagem do perfil
  //   const handleProfileImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //     if (e.target.files && e.target.files.length > 0) {
  //       setSelectedProfileImage(e.target.files[0]);
  //     }
  //   };

  //   };
  return (
    <>
      <form
        onSubmit={onSubmitRegister}
        className="w-full grid grid-cols-1 md:grid-cols-2 gap-6"
      >
        {/* Coluna 1 */}
        <div>
          <p className="text-sm text-gray-600 mb-4">
            Gerenciar e proteger sua conta
          </p>

          <div className="mb-4">
            <InputText
              name="name"
              label="Nome"
              placeholder="Digite seu nome completo..."
              maxLength={100}
              value={formData[FormInputNameRegister.name]}
              onChange={(e) =>
                onChangeFormInput(FormInputNameRegister.name)(e.target.value)
              }
              error={formErrors.name && formErrors.name[0]}
              className=""
              inputClassName=" rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
            />
          </div>

          <div className="mb-4">
            <InputText
              name="email"
              label="E-mail"
              placeholder="Digite seu e-mail..."
              value={formData[FormInputNameRegister.email]}
              onChange={(e) =>
                onChangeFormInput(FormInputNameRegister.email)(e.target.value)
              }
              error={formErrors.email && formErrors.email[0]}
              className=""
              inputClassName=" rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
            />
          </div>

          <div className="mb-4">
            <InputText
              name="phone"
              label="Telefone"
              placeholder="Digite seu telefone..."
              value={formData[FormInputNameRegister.phone]}
              onChange={(e) =>
                onChangeFormInput(FormInputNameRegister.phone)(e.target.value)
              }
              error={formErrors.phone && formErrors.phone[0]}
              className=""
              inputClassName=" rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
            />
          </div>

          <div>
            <InputText
              name="password"
              label="Senha"
              type={shouldShowPasswordToText ? 'text' : 'password'}
              placeholder="**********"
              value={formData[FormInputNameRegister.password]}
              maxLength={15}
              className=""
              inputClassName=" rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
              onChange={(e) =>
                onChangeFormInput(FormInputNameRegister.password)(
                  e.target.value
                )
              }
              error={formErrors.password && formErrors.password[0]}
              renderForward={
                <button
                  className={classNames(
                    formErrors.password && formErrors.password[0]
                      ? 'right-0'
                      : 'right-0 ',
                    'absolute cursor-pointer mr-4'
                  )}
                  onClick={onTogglePasswordToText}
                  type="button"
                >
                  <div className="h-8 flex flex-col justify-center items-center">
                    {shouldShowPasswordToText ? (
                      <img src={IconEye} alt="Mostrar senha" />
                    ) : (
                      <img src={IconEyeClose} alt="Ocultar senha" />
                    )}
                  </div>
                </button>
              }
            />

            <InputText
              name="confirmPassword"
              label="Confirme a sua senha"
              type={shouldShowPasswordToText2 ? 'text' : 'password'}
              placeholder="**********"
              value={formData[FormInputNameRegister.confirmPassword]}
              maxLength={15}
              className=""
              inputClassName=" rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
              onChange={(e) =>
                onChangeFormInput(FormInputNameRegister.confirmPassword)(
                  e.target.value
                )
              }
              error={
                formErrors.confirmPassword && formErrors.confirmPassword[0]
              }
              renderForward={
                <button
                  className={classNames(
                    formErrors.confirmPassword && formErrors.confirmPassword[0]
                      ? 'right-0 '
                      : 'right-0 ',
                    'absolute cursor-pointer mr-4'
                  )}
                  onClick={onTogglePasswordToText2}
                  type="button"
                >
                  <div className="h-8 flex flex-col justify-center items-center">
                    {shouldShowPasswordToText2 ? (
                      <img src={IconEye} alt="Mostrar senha" />
                    ) : (
                      <img src={IconEyeClose} alt="Ocultar senha" />
                    )}
                  </div>
                </button>
              }
            />
          </div>
        </div>

        {/* <div className="mb-4">
          <label className="block text-gray-700">
            Nome/CPF/Data de nascimento
          </label>
          <div className="flex items-center justify-between">
            <span className="text-gray-600">
              {user.cpf} / {user.birthDate}
            </span>
            {user.verified && (
              <span className="bg-green-100 text-green-600 px-2 py-1 rounded text-xs">
                Verificado
              </span>
            )}
          </div>
        </div> */}

        {/* Coluna para upload de imagens */}
        <div className="flex flex-col items-center ">
          {/* Seção de Foto do Perfil */}
          <div className="flex flex-col items-center justify-center mb-4 w-full">
            <label className="block text-gray-700 mb-2 text-center">
              Foto do Perfil
            </label>
            <div className="relative w-40 h-40 mb-4 items-center">
              {isEditingImage ? (
                <div className="flex flex-col items-center w-full ">
                  <InputFile
                    refInput={imageBase64Ref}
                    name="imageBase64"
                    fileName={formNameFiles?.imageBase64}
                    onChange={(e) =>
                      onChangeFormFileInput(FormInputNameRegister.imageBase64)(
                        e.target.files?.[0]
                      )
                    }
                    error={formErrors.imageBase64 && formErrors.imageBase64[0]}
                    wrapperClass="file:border file:border-gray-300 file:rounded file:px-4 file:py-2 text-ellipsis w-56"
                  />
                  <button
                    onClick={onToggleEdit}
                    className="mt-2 bg-red-500 text-white px-4 py-1 rounded-full hover:bg-red-600 text-xs"
                  >
                    Cancelar
                  </button>
                </div>
              ) : (
                // Visualização do preview da imagem
                <>
                  {selectedImage ? (
                    <img
                      src={`https://sandbox.buunpsgpsystem.com.br/uploads/${selectedImage}`}
                      alt="Selected Profile"
                      className="w-full h-full object-cover rounded-sm shadow-lg"
                    />
                  ) : (
                    <div className="w-full h-full bg-gray-200 rounded-sm flex items-center justify-center shadow-lg">
                      <span className="text-gray-400">Nenhuma imagem</span>
                    </div>
                  )}
                  <button
                    onClick={onToggleEdit}
                    className="absolute bottom-0 right-0 bg-blue-500 text-white p-2 rounded-full cursor-pointer hover:bg-blue-600 text-xs"
                    title="Alterar imagem"
                  >
                    Editar
                  </button>
                </>
              )}
            </div>
            <p className="text-xs text-gray-500 text-center">
              Tamanho máximo: 1 MB. Formatos: JPEG, PNG.
            </p>
          </div>
        </div>
        <div className="w-[50%]">
          <Button type="submit" title="Salvar" buttonStyle="red" size="md" />
        </div>
      </form>
    </>
  );
};
