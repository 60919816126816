import React, { FormEvent, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo-buunpo/Logo1.png';
import { ReactComponent as LupaIcon } from '../../assets/icons/lupa.svg';
import { ReactComponent as CartIcon } from '../../assets/icons/cart.svg';
import { ReactComponent as BellIcon } from '../../assets/icons/bell.svg';
import { ReactComponent as HelpIcon } from '../../assets/icons/help-circle.svg';
// import { ReactComponent as MenuIcon } from '../../assets/icons/menu.svg';
import { ReactComponent as DefaultUser } from '../../assets/icons/user3.svg';

import { UseFormReturn } from 'src/hooks';
import { Submenu } from '../Submenu';
import { Dropdown2 } from '../Dropdown2';
import { Search2 } from '../Search2';
import NotificationComponentMenor from '../NotificationMenor';
import SocialLinks from '../SocialLinks';
import AppQrCode from '../AppQrCode';
import { QrCodeData } from 'src/model/QrCode';
import { Button } from '../Button';
import { ProductListUI } from 'src/features/checkout/screens/Checkout/components/ProductList/ui';
import { Cart } from 'src/model/Cart';
import Client from 'src/model/User';
import { api } from 'src/services/api';
import { getBoolean } from 'src/helpers/common/localStorage';
import { REACT_APP_AUTH } from 'src/utils/config';

// TODO "nao estou conseguindo fazer o menu mobile funcionar, ainda nao sei pq quando clico fora ele fechar o menu ele só fecha se eu clicar no botao de abrir o menu novamente"
interface HeaderMenuShopeeProps
  extends Pick<UseFormReturn, 'formData' | 'formErrors' | 'onChangeFormInput'> {
  signed: boolean;
  carts: Cart[];
  cartCount: number;
  onSubmitSearch: (e: FormEvent) => void;
  onLinkClick: (href: string) => void;
  qrCode?: QrCodeData;
  onSubmitLogout: () => void;
}

export const HeaderMenuShopee: React.FC<HeaderMenuShopeeProps> = ({
  qrCode,
  formData,
  formErrors,
  signed,
  cartCount,
  carts,
  onChangeFormInput,
  onSubmitSearch,
  onLinkClick,
  onSubmitLogout,
}) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const mobileMenuRef = useRef<HTMLDivElement>(null);
  const menuButtonRef = useRef<HTMLButtonElement>(null);
  const [users, setUsers] = useState<Client[]>([]);

  const handleOutsideClick = (event: MouseEvent) => {
    if (
      mobileMenuRef.current &&
      !mobileMenuRef.current.contains(event.target as Node) &&
      menuButtonRef.current &&
      !menuButtonRef.current.contains(event.target as Node)
    ) {
      setIsMobileMenuOpen(false);
    }
  };

  const getUser = async (): Promise<void> => {
    try {
      const response = await api.get<Client>(`/user/findeone`);
      if (response.status === 200) {
        setUsers([response.data]);
      }
    } catch (error) {
      console.error('Erro ao buscar usuários:', error);
    }
  };

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isMobileMenuOpen]);

  const socialLinks = {
    instagram: 'https://www.instagram.com',
    whatsapp: 'https://wa.me/',
    tiktok: 'https://www.tiktok.com',
    youtube: 'https://www.youtube.com',
  };

  const isAuth = async (): Promise<void> => {
    setTimeout(async () => {
      if (getBoolean(String(REACT_APP_AUTH)) !== signed) {
        return;
      } else {
        isAuth();
      }
    }, 500);
  };

  useEffect(() => {
    isAuth();
    // eslint-disable-next-line
  }, [signed]);
  useEffect(() => {
    getUser();
    // eslint-disable-next-line
  }, []);

  const profilePhotoUrl = `https://sandbox.buunpsgpsystem.com.br/uploads/${users[0]?.image}`; // Exemplo de URL da foto do usuário

  return (
    <header className="w-full bg-[#F14A3E]  z-50">
      {/* Desktop Top Bar */}
      <div className="hidden md:block w-full container mx-auto px-4 py-2">
        <div className="flex justify-between items-center">
          <div className="flex w-full items-center ml-20 gap-4">
            <span className="text-white">Siga-nos no</span>
            <SocialLinks links={socialLinks} />
            {qrCode && <AppQrCode qrCode={qrCode} />}
          </div>

          <div className="flex items-center gap-6">
            <Dropdown2
              triggerContent={
                <div className="flex items-center space-x-2">
                  <BellIcon className="w-6 h-6" />
                  <span className="text-white text-sm font-semibold">
                    Notificações
                  </span>
                </div>
              }
              notificationsCount={9}
            >
              <NotificationComponentMenor />
            </Dropdown2>

            <Link to={'/'} className="flex items-center gap-2">
              <HelpIcon className="w-6 h-6" />
              <span className="text-white text-sm font-semibold">Ajuda</span>
            </Link>
            {signed ? (
              // Dropdown para usuários logados
              <Dropdown2
                triggerContent={
                  <div className="flex items-center space-x-2">
                    {profilePhotoUrl ? (
                      <img
                        className="w-8 h-8 rounded-full"
                        src={profilePhotoUrl}
                        alt="User photo"
                        onError={(e) => {
                          // Substitui a imagem quebrada pelo ícone SVG
                          e.currentTarget.onerror = null;
                          e.currentTarget.src = ''; // Pode definir como vazia para esconder a imagem
                        }}
                      />
                    ) : (
                      <DefaultUser className="w-8 h-8" />
                    )}
                    <span className="text-white text-sm font-semibold">
                      Olá, {users[0]?.name.split(' ')[0]}!
                    </span>
                  </div>
                }
                userName={users[0]?.name}
                userEmail={users[0]?.email}
              >
                <Submenu
                  links={[
                    { text: 'Perfil', href: '/user-profile' },
                    { text: 'Perfil da Loja', href: '/store-profile' },
                    { text: 'Tutorial', href: '/tutorial' },
                    { text: 'Política', href: '/advertising' },
                  ]}
                  onLinkClick={onLinkClick}
                  className="p-2.5 px-5 flex-col bg-[#ffffffb5] rounded-md text-black fade-in divide-y divide-gray-200"
                  liClassName="scale-zoom-1 hover:text-gray-400 p-1"
                />
                <div className="w-full flex justify-center items-center mt-3 mb-3">
                  <Button
                    type="button"
                    title="Sair"
                    buttonStyle="outline"
                    size="sm"
                    onClick={onSubmitLogout}
                  />
                </div>
              </Dropdown2>
            ) : (
              // Dropdown para usuários deslogados com opções de Login e Criar Conta
              <Dropdown2
                triggerContent={
                  <div className="flex items-center space-x-2">
                    <DefaultUser className="w-6 h-6" />
                    <span className="text-white text-sm font-semibold">
                      Entrar
                    </span>
                  </div>
                }
              >
                <Submenu
                  links={[
                    { text: 'Login', href: '/login' },
                    { text: 'Criar Conta', href: '/create-account' },
                  ]}
                  onLinkClick={onLinkClick}
                  className="p-2.5 px-5 flex-col bg-[#ffffffb5] rounded-md text-black fade-in divide-y divide-gray-200"
                  liClassName="scale-zoom-1 hover:text-gray-400 p-1"
                />
              </Dropdown2>
            )}
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="md:block hidden w-full container mx-auto px-4 py-3">
        <div className="flex items-center justify-between md:justify-center gap-4">
          <div className="flex items-center md:block hidden ">
            <Link to="/">
              <img
                src={logo}
                alt="Logo"
                className="w-32 md:w-52 pulse cursor-pointer"
              />
            </Link>
          </div>

          <div className="hidden md:block flex-1 max-w-2xl">
            <Search2
              formData={formData}
              formErrors={formErrors}
              onChangeFormInput={onChangeFormInput}
              onSubmitSearch={onSubmitSearch}
              icon={<LupaIcon />}
            />
          </div>
          <div className="hidden md:block  ml-4">
            <a href="/carts">
              <Dropdown2
                triggerContent={<CartIcon className="w-6 h-6 text-white" />}
                notificationsCount={cartCount}
                width="300px"
              >
                {carts && carts.length > 0 && (
                  <ProductListUI cartsProducts={carts} />
                )}
              </Dropdown2>
            </a>
          </div>
        </div>
      </div>

      {/* Mobile Version */}
      <div className=" md:hidden grid grid-cols-[75%_23%] items-center gap-2 p-2 w-full">
        {/* Mobile Search */}
        <div className="w-full flex justify-center">
          <Search2
            formData={formData}
            formErrors={formErrors}
            onChangeFormInput={onChangeFormInput}
            onSubmitSearch={onSubmitSearch}
            icon={<LupaIcon />}
          />
        </div>

        {/* Carrinho */}

        {/* Perfil / Login */}
        <div className="w-full flex items-center">
          <div className="w-full flex justify-center">
            <a href="/carts">
              <CartIcon className="w-6 h-6 text-white absolute" />
              <p className="relative -top-2 right-3 bg-white rounded-full text-[#F14A3E] w-5 h-5 flex justify-center items-center text-center">
                {cartCount}
              </p>
            </a>
          </div>
          {signed ? (
            <div className="flex items-center">
              <a href="/profile">
                {profilePhotoUrl ? (
                  <img
                    className="w-[5.5rem] h-[2.7rem] rounded-full"
                    src={profilePhotoUrl}
                    alt="User photo"
                    onError={(e) => {
                      e.currentTarget.onerror = null;
                      e.currentTarget.src = ''; // Pode definir como vazia para esconder a imagem
                    }}
                  />
                ) : (
                  <DefaultUser className="w-8 h-8" />
                )}
              </a>
            </div>
          ) : (
            <a href="/profile">
              <DefaultUser className="w-6 h-6 cursor-pointer font-bold" />
            </a>
          )}
        </div>
      </div>
    </header>
  );
};
