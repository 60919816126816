import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getBoolean } from 'src/helpers/common/localStorage';
import { Cart } from 'src/model/Cart';
import Client from 'src/model/User';
import { setLoading } from 'src/redux/loading/loadingSlice';
import { api } from 'src/services/api';
import { REACT_APP_AUTH } from 'src/utils/config';
import { CartUI } from './ui';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

interface CartScreenProps {
  auth?: boolean;
  showTitle?: boolean;
}
export const CartScreen: React.FC<CartScreenProps> = ({
  auth = true,
  showTitle = true,
}): JSX.Element => {
  const [cart, setCart] = useState<Cart[]>([]);
  const [signed, setSigned] = useState<boolean>(
    getBoolean(String(REACT_APP_AUTH))
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const isAuth = async (): Promise<void> => {
    setTimeout(async () => {
      if (getBoolean(String(REACT_APP_AUTH)) !== signed) {
        setSigned(getBoolean(String(REACT_APP_AUTH)));
        return;
      } else {
        isAuth();
      }
    }, 500);
  };
  const getUser = async (): Promise<void> => {
    if (signed) {
      dispatch(setLoading(true));
      try {
        const response = await api.get<Client>(`/user/findeone`);
        console.log(response);
        if (response.status === 200) {
          if (response.data.cart && response.data.cart.length > 0) {
            console.log('produto: ', response.data.cart[0].products);
            setCart(response.data.cart);
          }
        }
      } catch (error) {
        console.error('Erro ao buscar o usuario:', error);
      } finally {
        dispatch(setLoading(false));
      }
    } else {
      isAuth();
    }
  };
  const handleCalculateItemTotal = (
    price: number,
    quantity: number
  ): number => {
    if (!price || !quantity) return 0;
    return price * quantity;
  };

  const handleCalculateCartTotal = (): number => {
    if (!cart || cart.length === 0) return 0;

    return cart.reduce((total, item) => {
      const price =
        item.products.price.discount || item.products.price.discount === 0
          ? item.products.price.discount
          : item.products.price.price;

      return total + handleCalculateItemTotal(price, item.quantity);
    }, 0);
  };

  const handleOnRemoveItem = async (id: string): Promise<void> => {
    if (signed) {
      dispatch(setLoading(true));
      try {
        const response = await api.delete<Cart>(`/order/delete-cart/${id}`);
        if (response.status === 200) {
          getUser();
          toast.success('Item removido com sucesso');
        }
      } catch (error) {
        console.error('Erro ao buscar o usuario:', error);
      } finally {
        dispatch(setLoading(false));
      }
    } else {
      isAuth();
    }
  };

  const handleCheckout = (id: string): void => {
    if (signed) {
      const type = 'all-item';
      history.replace(`/checkout/${id}/${type}`);
    }
  };

  useEffect(() => {
    getUser();
    // eslint-disable-next-line
  }, [signed]);

  return (
    <CartUI
      cart={cart}
      calculateCartTotal={handleCalculateCartTotal}
      calculateItemTotal={handleCalculateItemTotal}
      onRemoveItem={handleOnRemoveItem}
      goCheckout={handleCheckout}
      auth={auth}
      showTitle={showTitle}
    />
  );
};
