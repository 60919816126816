import React from 'react';
import { Route } from 'src/navigation/Route';
import { PrivacyPolicyScreen } from './screens/PrivacyPolicy';

export const PIVACYPOLICY_ROUTES = {
  itself: '/privacy-policy',
};

export const PrivacyPolicyNavigation = (): JSX.Element => (
  <React.Fragment>
    <Route
      exact
      path={PIVACYPOLICY_ROUTES.itself}
      component={PrivacyPolicyScreen}
      //   isPrivateRoute={true}
    />
  </React.Fragment>
);
