import React, { useState } from 'react';
import { DashboardUI } from './ui';

const revenueData = [
  { name: 'Janeiro', value: 5000, year: 2024 },
  { name: 'Fevereiro', value: 7000, year: 2024 },
  { name: 'Março', value: 9000, year: 2024 },
  { name: 'Abril', value: 11000, year: 2024 },
  { name: 'Maio', value: 13000, year: 2024 },
  { name: 'Junho', value: 15000, year: 2024 },
  { name: 'Julho', value: 1000, year: 2024 },
  { name: 'Agosto', value: 11000, year: 2024 },
];

const performanceData = [
  { name: 'Vendedor A', value: 100, month: 'Janeiro', year: 2024 },
  { name: 'Vendedor B', value: 200, month: 'Janeiro', year: 2024 },
  { name: 'Vendedor C', value: 150, month: 'Fevereiro', year: 2024 },
  { name: 'Vendedor D', value: 80, month: 'Fevereiro', year: 2024 },
  { name: 'Vendedor E', value: 250, month: 'Março', year: 2024 },
  { name: 'Vendedor F', value: 250, month: 'Abril', year: 2024 },
  { name: 'Vendedor G', value: 250, month: 'Maio', year: 2024 },
];

export const DashboardScreen: React.FC = () => {
  const [selectedMonth, setSelectedMonth] = useState<string>('Todos');
  const [selectedYear, setSelectedYear] = useState<string>('Todos');
  const [selectedSeller, setSelectedSeller] = useState<string>('Todos');

  const months = Array.from(new Set(revenueData.map((item) => item.name)));
  const years = Array.from(
    new Set(revenueData.map((item) => item.year.toString()))
  );
  const sellers = Array.from(new Set(performanceData.map((item) => item.name)));

  const filteredRevenueData = revenueData.filter(
    (item) =>
      (selectedMonth === 'Todos' || item.name === selectedMonth) &&
      (selectedYear === 'Todos' || item.year.toString() === selectedYear)
  );

  const filteredPerformanceData = performanceData.filter(
    (item) =>
      (selectedSeller === 'Todos' || item.name === selectedSeller) &&
      (selectedMonth === 'Todos' || item.month === selectedMonth) &&
      (selectedYear === 'Todos' || item.year.toString() === selectedYear)
  );

  return (
    <DashboardUI
      revenueData={filteredRevenueData}
      performanceData={filteredPerformanceData}
      months={months}
      years={years}
      sellers={sellers}
      selectedMonth={selectedMonth}
      selectedYear={selectedYear}
      selectedSeller={selectedSeller}
      onMonthChange={setSelectedMonth}
      onYearChange={setSelectedYear}
      onSellerChange={setSelectedSeller}
    />
  );
};
