import React from 'react';

interface CardVideoUIProps {
  videoUrl: string;
  numberCard: number;
  titleCard: string;
  subtitle: string;
}

export const CardVideo: React.FC<CardVideoUIProps> = ({
  videoUrl,
  numberCard,
  titleCard,
  subtitle,
}): JSX.Element => {
  return (
    <div className="w-96 md:w-80 bg-white shadow-[0px_0px_15px_rgba(0,0,0,0.09)] p-4 pb-0 space-y-3 rounded-sm relative overflow-hidden">
      <div className="w-24 h-24 bg-[#F14A3E] rounded-full absolute -right-5 -top-7">
        <p className="absolute bottom-6 left-7 text-white text-2xl">
          {numberCard}
        </p>
      </div>

      <h1 className="font-bold text-xl">{titleCard}</h1>

      <p>{subtitle}</p>

      <div className="relative -mx-4 -mb-4">
        <iframe
          width="100%"
          height="240"
          src={videoUrl}
          title={`Video Player ${numberCard}`}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};
