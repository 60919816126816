import React from 'react';
import { Route } from 'src/navigation/Route';
import { StoreProfileScreen } from './screens';

export const STOREPROFILE_ROUTES = {
  itself: '/store-profile',
};

export const StoreProfileNavigation = (): JSX.Element => (
  <React.Fragment>
    <Route
      exact
      path={STOREPROFILE_ROUTES.itself}
      component={StoreProfileScreen}
      //   isPrivateRoute={true}
    />
  </React.Fragment>
);
