import React from 'react';

interface InfoCardProps {
  title: string;
  value: string | number;
  isHighlighted?: boolean;
}

export const InfoCard: React.FC<InfoCardProps> = ({
  title,
  value,
  isHighlighted,
}) => {
  return (
    <div className="bg-white rounded-lg shadow-md p-6 flex flex-col items-center justify-center">
      <h3
        className={`text-md font-semibold ${isHighlighted ? 'text-red-500' : 'text-gray-700'} mb-2`}
      >
        {title}
      </h3>
      <p className="text-2xl font-bold text-gray-900">{value}</p>
    </div>
  );
};
