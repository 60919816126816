import React, { FormEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { OptionProps } from 'src/components/Select';
import validators from 'src/helpers/validators';
import { Banners } from 'src/model/Banners';
import { Category } from 'src/model/Category';
import { setLoading } from 'src/redux/loading/loadingSlice';
import { api } from 'src/services/api';
import { HomePrimaryBannerManageMobileUI } from './ui';
import { BannerBasic, FormInputBannerHome } from '../../types';
import { BannerType } from 'src/model/BannerType';
import { toast } from 'react-toastify';
import useForm from 'src/hooks/useForm';
import { Dimensions, getImageDimensions } from 'src/utils/imageDimension';

export interface NameFiles {
  [key: string]: string;
}

export const HomePrimaryBannerManageMobileScreen: React.FC = () => {
  const [banners, setBanners] = useState<Banners[]>([]);
  const dispatch = useDispatch();
  //   const { loading } = useSelector(useLoading);
  const [categories, setCategories] = useState<OptionProps[]>([]);
  const [
    formNameFilesBannerPrimaryMobile,
    setFormNameFilesBannerPrimaryMobile,
  ] = useState<{
    [key: string]: string;
  }>({});

  const getCategories = async (): Promise<void> => {
    dispatch(setLoading(true));
    try {
      const response = await api.get<Category[]>('/category/find-all');
      if (response.status === 200) {
        console.log('aqui');
        const stateOptions: OptionProps[] = [];
        const data = response.data;
        data.forEach((category) => {
          const select = { value: category.id, label: category.name };
          stateOptions.push(select);
        });
        setCategories(stateOptions);
      }
    } catch (error) {
      console.error('Erro ao buscar categoria:', error);
      resetForm();
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getBannersMobile = async (): Promise<void> => {
    dispatch(setLoading(true));
    try {
      const response = await api.get<Banners[]>(
        '/banner/find-all/PRIMARY_HOME_MOBILE'
      );
      if (response.status === 200) {
        setBanners(response.data);
      }
    } catch (error) {
      console.error('Erro ao buscar banners:', error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const {
    formData: formData,
    formErrors: formErrors,
    setErrors: setErrors,
    onChangeFormInput: onChangeFormInput,
    isFormValid: isFormValid,
    resetForm: resetForm,
  } = useForm({
    initialData: {
      altTextBannerPrimaryMobile: '',
      imageBannerPrimaryMobile: '',
      categoryBannerPrimaryMobile: '',
    },
    validators: {
      altTextBannerPrimaryMobile: [validators.required],
      imageBannerPrimaryMobile: [validators.required],
      categoryBannerPrimaryMobile: [validators.required],
    },
  });

  const handleSubmit = async (e: FormEvent): Promise<void> => {
    try {
      e.preventDefault();
      const category = formData[FormInputBannerHome.categoryBannerHome];
      console.log(category);
      if (isFormValid()) {
        dispatch(setLoading(true));
        const payload: BannerBasic = {
          altText: formData[FormInputBannerHome.altTextBannerHome],
          image: formData[FormInputBannerHome.imageBannerHome],
          category: formData[FormInputBannerHome.categoryBannerHome],
          privatetype: BannerType.PRIMARY_HOME_MOBILE,
        };
        console.log(payload);
        const response = await api.post<Banners>('/banner', payload);
        if (response.status === 201 || response.status === 200) {
          getBannersMobile();
          resetForm();
          setFormNameFilesBannerPrimaryMobile({});
          toast.success('Dados salvos com sucesso!');
        } else if (response.status === 400) {
          const errorMessage =
            typeof response.data === 'string'
              ? response.data
              : JSON.stringify(response.data);
          toast.error(errorMessage);
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      let errorMessage =
        'Falha ao realizar o cadastro, tente novamente mais tarde';
      if (error.response && error.response.data) {
        resetForm();
        errorMessage =
          typeof error.response.data === 'string'
            ? error.response.data
            : JSON.stringify(error.response.data);
      }
      toast.error(errorMessage);
      setErrors({
        name: [errorMessage],
      });
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleOnChangeFileInput =
    (inputName: string) =>
    (file: File | undefined): void => {
      console.log('Arquivo selecionado:', file);
      // validate if file is image
      /* TODO
      Criar IF para Bloqueio de tamanho de imagem */

      if (file && file.type.match(/image\/(jpg|jpeg|png)/)) {
        const readerDimensions: Dimensions = getImageDimensions(file);
        if (readerDimensions.width < 1280 && readerDimensions.height < 380) {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            const base64 = reader.result?.toString();
            if (base64) {
              setFormNameFilesBannerPrimaryMobile({
                ...formNameFilesBannerPrimaryMobile,
                [inputName]: file.name,
              });
              onChangeFormInput(inputName)('');
              onChangeFormInput(inputName)(base64);
            }
          };
        } else {
          toast.error('Imagem maior que 1920x1080');
        }
      } else {
        setErrors({
          [inputName]: ['O formato deve ser .jpg, .jpeg ou .png'],
        });
      }
    };

  //   const handleEditBanner = (id: string) => {
  //     console.log(id);
  //       const banner = banners.find((b) => b.id === id);
  //       if (banner) {
  //         setFormData({ image: banner.image, altText: banner.altText });
  //         setFormNameFiles({ image: banner.image });
  //         setEditingId(id);
  //       }
  //   };

  const handleRemoveBanner = async (id: string): Promise<void> => {
    dispatch(setLoading(true));
    try {
      const response = await api.get<Category[]>(`/banner/delete/${id}`);
      if (response.status === 200) {
        toast.success('Banner apagado com sucesso');
        getBannersMobile();
      }
    } catch (error) {
      console.error('Erro ao apagar o Banner:', error);
      toast.error('Algo deu errado');
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    getBannersMobile();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getCategories();
    // eslint-disable-next-line
  }, []);

  return (
    <HomePrimaryBannerManageMobileUI
      banners={banners}
      formData={formData}
      formErrors={formErrors}
      formNameFilesBannerPrimaryMobile={formNameFilesBannerPrimaryMobile}
      onSubmitRegister={handleSubmit}
      onChangeFormInput={onChangeFormInput}
      onChangeFormFileInput={handleOnChangeFileInput}
      //   onEditBanner={handleEditBanner}
      onRemoveBanner={handleRemoveBanner}
      category={categories}
    />
  );
};
