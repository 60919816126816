import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Product } from 'src/model/Product';
import { setLoading } from 'src/redux/loading/loadingSlice';
import { api } from 'src/services/api';
import { ProductUI } from './ui';

interface Params {
  id: string;
}

export const ProductScreen: React.FC = (): JSX.Element => {
  const { id } = useParams<Params>();
  const [products, setProdutos] = useState<Product>();
  const [quantity, setQuantity] = useState<number>(1);
  const dispatch = useDispatch();

  const getProduct = async (): Promise<void> => {
    dispatch(setLoading(true));
    try {
      const response = await api.get<Product>(`/product/one/${id}`);
      console.log(response.data);
      if (response.status === 200) {
        setProdutos(response.data);
      }
    } catch (error) {
      console.error('Erro ao buscar categoria:', error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  //   const {
  //     formData: formDataReview,
  //     formErrors: formErrorsReview,
  //     setErrors: setErrorsReview,
  //     onChangeFormInput: onChangeFormInputReview,
  //     isFormValid: isFormValidReview,
  //     resetForm: resetFormReview,
  //   } = useForm({
  //     initialData: {
  //       description: '',
  //     },
  //     validators: {
  //       description: [validators.required],
  //     },
  //   });

  //   const handleChangeRating = (value: number): void => {
  //     if (value) {
  //       setRating(value);
  //     }
  //   };

  //   const handleOnSubmit = async (e: FormEvent): Promise<void> => {
  //     try {
  //       e.preventDefault();
  //       if (isFormValidReview()) {
  //         dispatch(setLoading(true));
  //         if (rating && rating < 0) {
  //           setErrorsReview({
  //             [FormInputReview.description]: ['Escola uma avaliação'],
  //           });
  //           toast.error('Escolha uma avaliação');
  //         }
  //         if (products) {
  //           const payload: ProductReviewBasic = {
  //             score: rating ? rating : 0,
  //             description: formDataReview[FormInputReview.description],
  //             product: products,
  //           };

  //           console.log(payload);
  //           const response = await api.post<ProductReview>('/review', payload);
  //           if (response.status === 201 || response.status === 200) {
  //             resetFormReview();
  //             setRating(0);
  //             toast.success('Dados salvos com sucesso!');
  //           } else if (response.status === 400) {
  //             const errorMessage =
  //               typeof response.data === 'string'
  //                 ? response.data
  //                 : JSON.stringify(response.data);
  //             toast.error(errorMessage);
  //           }
  //         } else {
  //           setErrorsReview({
  //             [FormInputReview.description]: ['Produto não selecionado'],
  //           });
  //           toast.error('Produto não selecionado');
  //         }
  //       } else {
  //         toast.error('Verifique os dados');
  //       }
  //       // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //     } catch (error: any) {
  //       let errorMessage =
  //         'Falha ao realizar o cadastro, tente novamente mais tarde';
  //       if (error.response && error.response.data) {
  //         errorMessage =
  //           typeof error.response.data === 'string'
  //             ? error.response.data
  //             : JSON.stringify(error.response.data);
  //       }
  //       toast.error(errorMessage);
  //       setErrorsReview({
  //         name: [errorMessage],
  //       });
  //     } finally {
  //       dispatch(setLoading(false));
  //     }
  //   };

  const handleQuantityChange = (increment: boolean) => {
    setQuantity((prev) => Math.max(1, prev + (increment ? 1 : -1)));
  };

  const handleAddCart = async (): Promise<void> => {
    if (products && quantity > 0) {
      try {
        dispatch(setLoading(true));
        const response = await api.post('/order/add-carts', {
          product: products,
          quantity: quantity,
        });
        if (response.status === 201 || response.status === 200) {
          toast.success('Produto adicionado ao carrinho');
        }
      } catch (error) {
        console.error('Erro ao adicionar produto ao carrinho:', error);
      } finally {
        dispatch(setLoading(false));
      }
    }
  };

  useEffect(() => {
    getProduct();
    console.log(products);
    // eslint-disable-next-line
  }, []);
  return (
    <>
      {products ? (
        <ProductUI
          products={products}
          onAddCart={handleAddCart}
          quantity={quantity}
          quantityChange={handleQuantityChange}
        />
      ) : (
        <p>Carregando produto...</p>
      )}
    </>
  );
};
