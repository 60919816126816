import React from 'react';
import { Cart } from '../../../../../../../model/Cart';

interface ProductListUIProps {
  cartsProducts?: Cart[];
}

export const ProductListUI: React.FC<ProductListUIProps> = ({
  cartsProducts,
}) => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <h2 className="text-lg font-semibold mb-4">Lista de Produtos</h2>

      {cartsProducts && cartsProducts.length > 0 ? (
        cartsProducts.map((cart, index) => (
          <>
            <div
              key={index}
              className="flex justify-between items-center py-2 border-b border-red-200 "
            >
              <div className="flex items-center">
                {cart ? (
                  <img
                    src={cart.products.imagens[0]}
                    alt={cart.products.name}
                    className="w-16 h-16 object-cover rounded"
                  />
                ) : (
                  <div className="w-16 h-16 bg-gray-200 rounded"></div>
                )}
                <div className="ml-4">
                  <p className="font-medium">{cart.products.name}</p>
                  <p className="text-gray-600 text-sm">Qtde: {cart.quantity}</p>
                </div>
              </div>
              <p className="font-semibold">
                {cart.products.price.discount !== null &&
                cart.products.promotion !== null
                  ? Number(cart.products.price.discount).toLocaleString(
                      'pt-BR',
                      {
                        style: 'currency',
                        currency: 'BRL',
                      }
                    )
                  : Number(cart.products.price.price).toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
              </p>
            </div>
          </>
        ))
      ) : (
        <p className="text-gray-600">Nenhum produto encontrado.</p>
      )}
    </div>
  );
};
