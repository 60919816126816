import React from 'react';
import { Address } from 'src/model/Address';

interface AddressListProps {
  addresses: Address[] | undefined;
  onSelectAddress: (id: string) => void;
  selectedAddress?: string;
}

export const AddressList: React.FC<AddressListProps> = ({
  addresses,
  onSelectAddress,
  selectedAddress,
}) => {
  if (!addresses || addresses.length === 0) {
    return <p>Sem endereços disponíveis.</p>;
  }

  return (
    <div>
      <h3>Lista de Endereços</h3>
      <ul>
        {addresses &&
          addresses.length > 0 &&
          addresses.map((address) => (
            <li
              key={address.id}
              onClick={() => onSelectAddress(address.id)}
              style={{
                padding: '10px',
                margin: '5px 0',
                cursor: 'pointer',
                backgroundColor:
                  selectedAddress === address.id ? '#e0f7fa' : '#fff',
                border: '1px solid #ddd',
              }}
            >
              <p>
                <strong>
                  {address.street}, {address.number}
                </strong>
              </p>
              <p>
                {address.district} - {address.city}, {address.state}
              </p>
              <p>
                {address.zipcode} - {address.country}
              </p>
            </li>
          ))}
      </ul>
    </div>
  );
};
