import React from 'react';
import { Switch } from 'react-router-dom';
import { HomeNavigation } from 'src/features/home/navigation';
import { ProductNavigation } from 'src/features/product/navigation';
import { AdvertisingNavigation } from 'src/features/advertising/navigation';
import { PrivacyPolicyNavigation } from 'src/features/privacyPolicy/navigation';
import { TutorialNavigation } from 'src/features/tutorial/navigation';
import { SearchEnterpriseNavigation } from 'src/features/searchEnterprise/navigation';
import { ElogioNavigation } from 'src/features/elogio/navigation';
import { ReclamationNavigation } from 'src/features/reclamation/navigation';
import { SuggestionNavigation } from 'src/features/suggestion/navigation';
import { CreateAccountNavigation } from 'src/features/createAccount/navigation';
import { CreateAdNavigation } from 'src/features/createAd/navigation';
import { CreateProductNavigation } from 'src/features/createProduct/navigation';
import { StoreProfileNavigation } from 'src/features/storeProfile/navigation';
import { UserProfileNavigation } from 'src/features/userProfile/navigation';
import { CheckoutNavigation } from 'src/features/checkout/navigation';
import { renderRoutes } from './utils';
import { LoginNavigation } from 'src/features/login/navigation';
import { AdminNavigation } from 'src/features/admin/navigation';
import { ProductsSearchNavigation } from 'src/features/productsSearch/navigation';
import { ProfilePageNavigation } from 'src/features/profilePage/navigation';

const Navigation: React.FC = (): JSX.Element => {
  const homeRoutes = renderRoutes(HomeNavigation);
  const productRoutes = renderRoutes(ProductNavigation);
  const advertisingRoutes = renderRoutes(AdvertisingNavigation);
  const privacyPolicyRoutes = renderRoutes(PrivacyPolicyNavigation);
  const tutorialRoutes = renderRoutes(TutorialNavigation);
  const searchEnterpriseRoutes = renderRoutes(SearchEnterpriseNavigation);
  const elogioRoutes = renderRoutes(ElogioNavigation);
  const reclamationRountes = renderRoutes(ReclamationNavigation);
  const suggestionRoutes = renderRoutes(SuggestionNavigation);
  const createAccountRoutes = renderRoutes(CreateAccountNavigation);
  const createAdRoutes = renderRoutes(CreateAdNavigation);
  const createProductRoutes = renderRoutes(CreateProductNavigation);
  const storeProfileRoutes = renderRoutes(StoreProfileNavigation);
  const userProfileRoutes = renderRoutes(UserProfileNavigation);
  const checkoutRoutes = renderRoutes(CheckoutNavigation);
  const loginRoutes = renderRoutes(LoginNavigation);
  const adminRoutes = renderRoutes(AdminNavigation);
  const productSearchRoutes = renderRoutes(ProductsSearchNavigation);
  const profilePageRoutes = renderRoutes(ProfilePageNavigation);

  return (
    <Switch>
      {homeRoutes}
      {productRoutes}
      {advertisingRoutes}
      {tutorialRoutes}
      {searchEnterpriseRoutes}
      {elogioRoutes}
      {reclamationRountes}
      {suggestionRoutes}
      {createAccountRoutes}
      {createAdRoutes}
      {createProductRoutes}
      {storeProfileRoutes}
      {userProfileRoutes}
      {checkoutRoutes}
      {privacyPolicyRoutes}
      {loginRoutes}
      {adminRoutes}
      {productSearchRoutes}
      {profilePageRoutes}
    </Switch>
  );
};

export { Navigation };
