import React from 'react';
import { Route } from 'src/navigation/Route';
import { AdvertisingScreen } from './screens/Advertising';

export const ADVERTISING_ROUTES = {
  itself: '/advertising',
};

export const AdvertisingNavigation = (): JSX.Element => (
  <React.Fragment>
    <Route
      exact
      path={ADVERTISING_ROUTES.itself}
      component={AdvertisingScreen}
      //   isPrivateRoute={true}
    />
  </React.Fragment>
);
