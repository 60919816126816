import React, { FormEvent } from 'react';
import { Button } from 'src/components/Button';
import { CardVideo } from 'src/components/CardVideo';
import { InputText } from 'src/components/InputText';
import { FormInputVideo } from 'src/features/admin/types';
import { UseFormReturn } from 'src/hooks';
import { VideoData } from 'src/model/VideoTutorial';

interface VideoTutorialManagerProps
  extends Pick<UseFormReturn, 'formData' | 'formErrors' | 'onChangeFormInput'> {
  videos: VideoData[];
  //   editIndex: number | null;
  //   onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  //   onEdit: (index: number) => void;
  //   onRemove: (index: number) => void;
  onSubmitRegister: (e: FormEvent) => void;
}

export const VideoTutorialManager: React.FC<VideoTutorialManagerProps> = ({
  videos,
  //   editIndex,
  formData,
  formErrors,
  //   onEdit,
  //   onRemove,
  onChangeFormInput,
  onSubmitRegister,
}) => {
  return (
    <>
      <form onSubmit={onSubmitRegister}>
        <div className="mb-4 space-y-2">
          <InputText
            name="name"
            label="Link do video"
            placeholder="URL do vídeo..."
            value={formData[FormInputVideo.videoUrl]}
            onChange={(e) =>
              onChangeFormInput(FormInputVideo.videoUrl)(e.target.value)
            }
            error={formErrors.videoUrl && formErrors.videoUrl[0]}
            inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
          />
          <InputText
            name="title"
            label="Nome do tutorial"
            placeholder="Digite aqui..."
            value={formData[FormInputVideo.title]}
            onChange={(e) =>
              onChangeFormInput(FormInputVideo.title)(e.target.value)
            }
            error={formErrors.title && formErrors.title[0]}
            inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
          />
          <InputText
            name="subtitle"
            label="Nome do subtitulo"
            placeholder="Digite aqui..."
            value={formData[FormInputVideo.subtitle]}
            onChange={(e) =>
              onChangeFormInput(FormInputVideo.subtitle)(e.target.value)
            }
            error={formErrors.subtitle && formErrors.subtitle[0]}
            inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
          />
          <InputText
            name="position"
            label="Posição"
            placeholder="Digite aqui..."
            value={formData[FormInputVideo.position]}
            onChange={(e) =>
              onChangeFormInput(FormInputVideo.position)(e.target.value)
            }
            error={formErrors.position && formErrors.position[0]}
            inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
          />
          <Button title="Cadastrar" buttonStyle="red" size="lg" type="submit" />
        </div>
      </form>

      <div className="grid grid-cols-[repeat(auto-fit,minmax(320px,1fr))] gap-x-10 gap-y-6 justify-items-center  w-full flex-wrap">
        {videos.map((video, index) => (
          <div key={index} className="space-y-2">
            <CardVideo
              videoUrl={video.videoUrl}
              numberCard={index + 1}
              titleCard={video.title}
              subtitle={video.subtitle}
            />
            {/* <div className="flex items-center justify-center gap-2">
              <button
                onClick={() => onEdit(index)}
                className=" bg-yellow-500 text-white p-2 rounded"
              >
                Editar
              </button>
              <button
                onClick={() => onRemove(index)}
                className=" bg-red-500 text-white p-2 rounded"
              >
                Remover
              </button>
            </div> */}
          </div>
        ))}
      </div>
    </>
  );
};
