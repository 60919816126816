import React, { FormEvent } from 'react';
import { Search } from 'src/components/Search';
import { AuthWrapper } from 'src/features/core/auth/screen/AuthWrapper';
import { UseFormReturn } from 'src/hooks';
import { ReactComponent as LupaIcon } from '../../../../../assets/icons/lupa.svg';
import Logo from '../../../../../assets/images/LogoApp.png';
import { CardEnterprise } from 'src/components/CardEnterprise';
import { Link } from 'react-router-dom';

interface SearchEnterpriseUIProps
  extends Pick<UseFormReturn, 'formData' | 'formErrors' | 'onChangeFormInput'> {
  onSubmitSearch: (e: FormEvent) => void;
}

export const SearchEnterpriseUI: React.FC<SearchEnterpriseUIProps> = ({
  formData,
  formErrors,
  onChangeFormInput,
  onSubmitSearch,
}): JSX.Element => {
  return (
    <AuthWrapper>
      <div className="flex flex-col w-full container m-4 gap-4 p-4 rounded-lg">
        <div className=" flex items-center justify-center content-center">
          <Link to="/">
            <img src={Logo} alt="Logo da Empresa" className="w-80 h-auto " />
          </Link>
        </div>
        <div className="h-14">
          <Search
            formData={formData}
            formErrors={formErrors}
            onChangeFormInput={onChangeFormInput}
            onSubmitSearch={onSubmitSearch}
            icon={<LupaIcon />}
          />
        </div>

        <div>
          <CardEnterprise
            logo={Logo}
            nameEnterprise="Nome da Empresa"
            phoneNumber="(11) 1234-5678"
            address="Rua Exemplo, 123"
            announcements={[
              { title: 'Anúncio 1', url: 'https://link1.com' },
              { title: 'Anúncio 2', url: 'https://link2.com' },
            ]}
          />
        </div>
      </div>
    </AuthWrapper>
  );
};
