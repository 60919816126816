import { FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { NameFiles } from 'src/features/createAccount/screens/CreateAccount/ui';
import validators from 'src/helpers/validators';
import { setLoading, useLoading } from 'src/redux/loading/loadingSlice';
import { api } from 'src/services/api';
import { CreateProductUI } from './ui';
import { Category } from 'src/model/Category';
import { Brand } from 'src/model/Brand';
import {
  FormInputGrade,
  FormRegisterProduct,
  ProductBasic,
} from '../../types/types';
import useForm from 'src/hooks/useForm';
import { Dimensions } from 'src/model/Dimensions';
import { Price } from 'src/model/Price';
import { Warranty } from 'src/model/Warranty';
import { ProductStatus } from 'src/model/Product';
import { Grade } from 'src/model/Grade';

// Define um tipo seguro para erros de Axios
interface AxiosError {
  response?: {
    data?: string | Record<string, unknown>;
  };
}

export const CreateProductScreen = () => {
  const { loading } = useSelector(useLoading);
  const dispatch = useDispatch();
  const [formNameFiles, setFormNameFiles] = useState<NameFiles>({});
  const [files, setFiles] = useState<File[]>([]);
  const [filesBase64, setFilesBase64] = useState<string[]>([]);
  const [previewFiles, setPreviewFiles] = useState<string[]>([]);
  //   const [selectTags, setSelectTags] = useState<string[]>([]);
  const [inputTags, setInputTags] = useState<string[]>([]);
  const [category, setCategory] = useState<Category[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<Category[]>([]);
  const [grade, setGrade] = useState<Grade[]>([]);

  const getCategory = async (): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const response = await api.get<Category[]>('/category/find-all');

      if (response.data) {
        setCategory(response.data);
      }
      // eslint-disable-next-line
    } catch (error: any) {
      // resetFormRegister();
      let errorMessage =
        'Falha ao realizar o cadastro, tente novamente mais tarde';
      if (error.response && error.response.data) {
        errorMessage =
          typeof error.response.data === 'string'
            ? error.response.data
            : JSON.stringify(error.response.data);
      }
      toast.error(errorMessage);
      setErrorRegister({
        document: [errorMessage],
      });
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const newFiles = Array.from(e.target.files);
      const totalFiles = [...files, ...newFiles];
      const images = totalFiles.filter(
        (file) => file.type.startsWith('image/') && totalFiles.length <= 4
      );
      const videos = totalFiles.filter((file) =>
        file.type.startsWith('video/')
      );

      if (images.length > 4 || videos.length > 1 || totalFiles.length > 5) {
        alert('Máximo de 4 imagens e 1 vídeo ou 5 arquivos no total.');
        return;
      }

      setFiles(totalFiles.slice(0, 5));

      totalFiles.forEach((file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const base64 = reader.result?.toString();
          if (base64) {
            setFilesBase64((prevBase64) => [...prevBase64, base64]);
          }
        };
      });

      const previews = totalFiles.map((file) => URL.createObjectURL(file));
      setPreviewFiles(previews);
    }
  };

  const handleDeleteFile = (index: number) => {
    const updatedFiles = files.filter((_, i) => i !== index);
    setFiles(updatedFiles);
    const updatedPreviews = previewFiles.filter((_, i) => i !== index);
    setPreviewFiles(updatedPreviews);
  };

  const {
    formData: formDataRegister,
    formErrors: formErrorRegister,
    setErrors: setErrorRegister,
    onChangeFormInput: onChangeFormInputRegister,
    isFormValid: isFormValidRegister,
    resetForm: resetFormRegister,
  } = useForm({
    initialData: {
      name: '',
      description: '',
      stock: '',
      price: '',
      priceDiscount: '',
      sku: '',
      status: '',
      promotion: '',
      brandName: '',
      dimensionsWidth: '',
      dimensionsHeight: '',
      dimensionsDepth: '',
      dimensionsWeight: '',
      discount: '',
      warranty: '',
      parcel: '',
      tag: '',
    },
    validators: {
      name: [validators.required],
      description: [validators.required],
      stock: [validators.required],
      price: [validators.required],
      status: [validators.required],
      brandName: [validators.required],
      dimensionsWidth: [validators.required],
      dimensionsHeight: [validators.required],
      dimensionsDepth: [validators.required],
      dimensionsWeight: [validators.required],
      warranty: [validators.required],
    },
  });

  const handleOnChangeFileInput =
    (inputName: string) => (file: File | undefined) => {
      if (file && file.type.match(/image\/(jpg|jpeg|png)/)) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const base64 = reader.result?.toString();
          if (base64) {
            setFormNameFiles({ ...formNameFiles, [inputName]: file.name });
            onChangeFormInputRegister(inputName)(base64);
          }
        };
      } else {
        setErrorRegister({
          [inputName]: ['O formato deve ser .jpg, .jpeg ou .png'],
        });
      }
    };

  const handleStatusType = (value: string): ProductStatus => {
    switch (value) {
      case 'Ativo':
        return ProductStatus.ACTIVE;
      case 'Inativo':
        return ProductStatus.INACTIVE;
      case 'Estoque Zerado':
        return ProductStatus.DISCONTINUED;
      default:
        return ProductStatus.INACTIVE;
    }
  };

  const handleOnSubmitRegister = async (e: FormEvent): Promise<void> => {
    e.preventDefault();
    if (isFormValidRegister()) {
      try {
        dispatch(setLoading(true));
        const brand = {
          name: formDataRegister[FormRegisterProduct.brandName],
        } as Brand;
        const dimensions = {
          width: formDataRegister[FormRegisterProduct.dimensionsWidth],
          height: formDataRegister[FormRegisterProduct.dimensionsHeight],
          depth: formDataRegister[FormRegisterProduct.dimensionsDepth],
          weight: formDataRegister[FormRegisterProduct.dimensionsWeight],
        } as Dimensions;

        const price = {
          price: formDataRegister[FormRegisterProduct.price],
          discount: formDataRegister[FormRegisterProduct.priceDiscount],
        } as unknown as Price;

        const warranty = {
          warranty: formDataRegister[FormRegisterProduct.priceDiscount],
        } as Warranty;

        const gradeDefine: Grade[] = grade;
        console.log(gradeDefine);
        const payload: ProductBasic = {
          name: formDataRegister[FormRegisterProduct.name],
          description: formDataRegister[FormRegisterProduct.description],
          stock: formDataRegister[FormRegisterProduct.stock],
          sku: formDataRegister[FormRegisterProduct.sku],
          promotion:
            formDataRegister[FormRegisterProduct.promotion] === '0'
              ? false
              : true,
          status: handleStatusType(
            formDataRegister[FormRegisterProduct.status]
          ),
          category: selectedCategories,
          brand: brand,
          dimensions: dimensions,
          imagens: filesBase64,
          price: price,
          tags: inputTags,
          warranty: warranty,
          grade: gradeDefine,
        };

        console.log(payload);

        const response = await api.post('/product', payload);

        if (response.status === 201) {
          resetFormRegister();

          // Reset dos estados adicionais
          setSelectedCategories([]);
          setGrade([]);
          setInputTags([]);
          setPreviewFiles([]);
          setFiles([]);
          setFilesBase64([]);
          setFormNameFiles({});
          toast.success('Produto cadastrado com sucesso!');

          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          toast.error('Falha ao cadastrar o produto');
        }
        // eslint-disable-next-line
        if (35 > 50) {
          resetFormRegister();
        }
      } catch (error: unknown) {
        dispatch(setLoading(false));
        let errorMessage =
          'Falha ao realizar o cadastro, tente novamente mais tarde';

        if (error instanceof Error && (error as AxiosError).response) {
          const responseData = (error as AxiosError).response?.data;
          errorMessage =
            typeof responseData === 'string'
              ? responseData
              : JSON.stringify(responseData);
        } else if (error instanceof Error) {
          errorMessage = error.message;
        }

        toast.error(errorMessage);
        setErrorRegister({
          document: [errorMessage],
        });
      } finally {
        dispatch(setLoading(false));
      }
    } else {
      toast.error('Preencha todos os campos obrigatórios corretamente.');
    }
  };

  // Função para adicionar uma tag
  const handleAddTag = (tag: string) => {
    if (tag && !inputTags.includes(tag)) {
      setInputTags([...inputTags, tag]);
    } else {
      toast.error('Tag já adicionada ou inválida.');
    }
  };

  // Função para remover uma tag
  const handleRemoveTag = (tagToRemove: string) => {
    setInputTags(inputTags.filter((tag) => tag !== tagToRemove));
  };

  //   const handleTagSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
  //     const selectedTag = e.target.value;
  //     if (selectedTag && !selectTags.includes(selectedTag)) {
  //       setSelectTags([...selectTags, selectedTag]);
  //     }
  //   };

  //   const handleRemoveTag = (tagToRemove: string) => {
  //     setSelectTags(selectTags.filter((tag) => tag !== tagToRemove));
  //   };

  const handleCategorySelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = e.target.value;
    if (selectedId) {
      const selectedCategory = category.find((cat) => cat.id === selectedId);
      if (
        selectedCategory &&
        !selectedCategories.some((cat) => cat.id === selectedId)
      ) {
        setSelectedCategories([...selectedCategories, selectedCategory]);
      }
    }
  };

  const handleRemoveCategory = (id: string) => {
    setSelectedCategories(selectedCategories.filter((cat) => cat.id !== id));
  };

  const handleAddQuantiValue = (e: FormEvent) => {
    e.preventDefault();
    const newGrade: Grade = {
      atributo: formDataRegister[FormInputGrade.atributo],
      value: formDataRegister[FormInputGrade.value],
      quantity: formDataRegister[FormInputGrade.quantity],
    };
    if (newGrade && !inputTags.includes(newGrade.value)) {
      setGrade([...grade, newGrade]);
      onChangeFormInputRegister(FormInputGrade.atributo)('');
      onChangeFormInputRegister(FormInputGrade.value)('');
      onChangeFormInputRegister(FormInputGrade.quantity)('');
    }
  };

  const handleRemoveGrade = (index: number): void => {
    setGrade(grade.filter((_, i) => i !== index));
  };

  useEffect(() => {
    getCategory();
    // eslint-disable-next-line
  }, []);

  return (
    <CreateProductUI
      state={loading}
      formData={formDataRegister}
      formErrors={formErrorRegister}
      formNameFiles={formNameFiles}
      onChangeFormInput={onChangeFormInputRegister}
      onSubmitRegister={handleOnSubmitRegister}
      onChangeFormFileInput={handleOnChangeFileInput}
      filesImage={files}
      categoryOptions={category}
      onDeleteFile={handleDeleteFile}
      previewFiles={previewFiles}
      selectedCategories={selectedCategories}
      FileUpload={handleFileUpload}
      inputTags={inputTags}
      onAddTag={handleAddTag} // Passa a função correta
      onRemoveTag={handleRemoveTag}
      onSelectCategory={handleCategorySelect}
      onRemoveCategory={handleRemoveCategory}
      addQuantiValue={handleAddQuantiValue}
      removeGrade={handleRemoveGrade}
      grade={grade}
    />
  );
};
