import React, { FormEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getBoolean } from 'src/helpers/common/localStorage';
import {
  updateHolderName,
  updateMaskCVV,
  updateMaskCard,
} from 'src/helpers/masks/cardNumber';
import { updateMask as cpfCnpjMask } from 'src/helpers/masks/cpfCnpj';
import { updateMask as expirationDateMask } from 'src/helpers/masks/expirationDate';
import validators from 'src/helpers/validators';
import useForm from 'src/hooks/useForm';
import { Address } from 'src/model/Address';
import { Cart } from 'src/model/Cart';
import Client from 'src/model/User';
import { setLoading } from 'src/redux/loading/loadingSlice';
import { api } from 'src/services/api';
import { REACT_APP_AUTH } from 'src/utils/config';
import { FormInputNameCreditCard } from '../../types';
import { CheckoutUI } from './ui';

export interface Summary {
  originalPrice: number;
  savings: number;
  storePickup: number;
  tax: number;
  total: number;
}

export const CheckoutScreen: React.FC = (): JSX.Element => {
  const [signed, setSigned] = useState<boolean>(
    getBoolean(String(REACT_APP_AUTH))
  );
  //   const { id, type } = useParams<Params>();
  const dispatch = useDispatch();
  const [addres, setAddress] = useState<Address[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<string | undefined>();
  const [carts, setCarts] = useState<Cart[]>([]);
  const [user, setUser] = useState<Client>();
  const [selectedCard, setSelectedCard] = useState<number>();
  const [addNewCard, setAddNewCard] = useState<boolean>(false);
  const [summary, setSummary] = useState<Summary>({
    originalPrice: 0,
    savings: 0,
    storePickup: 0,
    tax: 0,
    total: 0,
  });
  //   const [products, setProducts] = useState<Product[]>([]);

  const isAuth = async (): Promise<void> => {
    setTimeout(async () => {
      if (getBoolean(String(REACT_APP_AUTH)) !== signed) {
        setSigned(getBoolean(String(REACT_APP_AUTH)));
        return;
      } else {
        isAuth();
      }
    }, 500);
  };

  //   const handleProduct = async (): Promise<void> => {
  //     if (signed) {
  //       dispatch(setLoading(true));
  //       try {
  //         if (type == 'all-item') {
  //           const response = await api.get<Cart>(`/order/${id}`);
  //           if (response.status === 200) {
  //             setProducts([response.data.products]);
  //           }
  //         }
  //       } catch (error) {
  //         console.error('Erro ao buscar o usuario:', error);
  //       } finally {
  //         dispatch(setLoading(false));
  //       }
  //     } else {
  //       isAuth();
  //     }
  //   };

  const handleSelectedCard = (id: number): void => {
    setSelectedCard(id);
  };

  const getUser = async (): Promise<void> => {
    if (signed) {
      dispatch(setLoading(true));
      try {
        const response = await api.get<Client>(`/user/findeone`);
        console.log(response);
        if (response.status === 200) {
          setUser(response.data);
          if (response.data.cart && response.data.cart.length > 0) {
            setCarts(response.data.cart);
          }
          if (response.data.address) {
            setAddress(response.data.address);
          }
        }
      } catch (error) {
        console.error('Erro ao buscar o usuario:', error);
      } finally {
        dispatch(setLoading(false));
      }
    } else {
      isAuth();
    }
  };

  const handleOnSUbmitRegister = async (e: FormEvent): Promise<void> => {
    console.log(e);
    setErrorsAddCreditCard({
      [FormInputNameCreditCard.number]: ['Erro ao salvar os dados'],
    });
    isFormValidAddCreditCard();
    resetFormAddCreditCard();
  };

  const handleSelectAddress = (id: string): void => {
    setSelectedAddress(id);
  };

  const {
    formData: formDataAddCreditCard,
    formErrors: formErrorsAddCreditCard,
    setErrors: setErrorsAddCreditCard,
    onChangeFormInput: onChangeFormInputAddCreditCard,
    isFormValid: isFormValidAddCreditCard,
    resetForm: resetFormAddCreditCard,
  } = useForm({
    initialData: {
      number: '',
      date: '',
      cvv: '',
      name: '',
      document: '',
    },
    validators: {
      number: [validators.required, validators.cardNumber],
      date: [validators.required, validators.cardExpirationDate],
      cvv: [validators.required, validators.cvv],
      name: [validators.required],
      document: [validators.required, validators.cpforcnpj],
    },
    formatters: {
      number: updateMaskCard,
      date: expirationDateMask,
      cvv: updateMaskCVV,
      name: updateHolderName,
      document: cpfCnpjMask,
    },
  });

  const handleOnAddCard = (): void => {
    if (addNewCard === true) {
      setAddNewCard(false);
    } else {
      setAddNewCard(true);
    }
  };

  const handleCalculateItemTotal = (
    price: number,
    quantity: number
  ): number => {
    if (!price || !quantity) return 0;
    return price * quantity;
  };

  const handleCalculateCartTotal = (): void => {
    const totalValue = carts.reduce(
      (total, item) =>
        total +
        handleCalculateItemTotal(item.products.price.price, item.quantity),
      0
    );
    const totalDiscount = carts.reduce(
      (total, item) =>
        total +
        handleCalculateItemTotal(item.products.price.discount, item.quantity),
      0
    );

    const total = totalDiscount ? totalDiscount : totalValue;
    const summary: Summary = {
      originalPrice: totalValue,
      savings: totalDiscount,
      storePickup: 0,
      tax: 0,
      total,
    };

    setSummary(summary);
  };

  useEffect(() => {
    isAuth();
    // eslint-disable-next-line
  }, [signed]);

  useEffect(() => {
    getUser();
    console.log(user);
    // eslint-disable-next-line
  }, [signed]);

  useEffect(() => {
    handleCalculateCartTotal();
    // eslint-disable-next-line
  }, [carts]);

  return user ? (
    <CheckoutUI
      formData={formDataAddCreditCard}
      formErrors={formErrorsAddCreditCard}
      onChangeFormInput={onChangeFormInputAddCreditCard}
      onAddCard={handleOnAddCard}
      addNewCard={addNewCard}
      selectedCard={selectedCard}
      onSelectCard={handleSelectedCard}
      onSubmitRegister={handleOnSUbmitRegister}
      user={user}
      carts={carts}
      addresses={addres}
      selectedAddress={selectedAddress}
      onSelectAddress={handleSelectAddress}
      summary={summary}
    />
  ) : (
    <p>sem produtos</p>
  );
};
