import cep from 'cep-promise';
import React, { FormEvent, useState } from 'react';
import validators from 'src/helpers/validators';
import useForm from 'src/hooks/useForm';
import { Address } from 'src/model/Address';
import { BillingAddressUI } from './ui';
import { useDialog } from 'src/hooks/useDialog';
// import { api } from 'src/services/api';
import { updateMask as updateMaskCEP } from 'src/helpers/masks/cep';
import { updateMask as updateMaskCPF } from 'src/helpers/masks/cpf';
import { updateMask as updateMaskDate } from 'src/helpers/masks/generalDate';
import { updateMask as updateMaskPhone } from 'src/helpers/masks/mobilePhone';
import { FormRegisterDelivery } from 'src/features/checkout/types';

interface BillingAddressProps {
  addresses: Address[] | undefined;
  onSelectAddress: (id: string) => void;
  selectedAddress?: string;
}

export const BillingAddressScreen: React.FC<BillingAddressProps> = ({
  addresses,
  onSelectAddress,
  selectedAddress,
}) => {
  const [showNewAddressForm, setShowNewAddressForm] = useState(false);
  const { visible, onToggle } = useDialog();
  const handleShowNewAddress: () => void = () => {
    onToggle();
  };
  const {
    formData: formDataRegister,
    formErrors: formErrorRegister,
    setErrors: setErrorRegister,
    onChangeFormInput: onChangeFormInputRegister,
    isFormValid: isFormValidRegister,
    resetForm: resetFormRegister,
  } = useForm({
    initialData: {
      name: '',
      description: '',
      stock: '',
      price: '',
      priceDiscount: '',
      sku: '',
      status: '',
      promotion: '',
      brandName: '',
      dimensionsWidth: '',
      dimensionsHeight: '',
      dimensionsDepth: '',
      dimensionsWeight: '',
      discount: '',
      warranty: '',
      zipCode: '',
      state: '',
      city: '',
      street: '',
      complement: '',
      number: '',
      neighborhood: '',
      country: '',
    },
    validators: {
      name: [validators.required],
      addressType: [validators.required],
      nameShipping: [validators.required],
      phoneShipping: [validators.required, validators.mobilePhone],
      zipCode: [validators.required],
      state: [validators.required],
      city: [validators.required],
      street: [validators.required],
      number: [validators.required],
      country: [validators.required],
      neighborhood: [validators.required],
    },
    formatters: {
      document: updateMaskCPF,
      phone: updateMaskPhone,
      date: updateMaskDate,
      zipCode: updateMaskCEP,
    },
  });
  const handleOnChangeCEP = async (value: string): Promise<void> => {
    if (value.length === 9) {
      const cepResponse = await cep(value);
      onChangeFormInputRegister(FormRegisterDelivery.state)(cepResponse.state);
      onChangeFormInputRegister(FormRegisterDelivery.city)(cepResponse.city);
      onChangeFormInputRegister(FormRegisterDelivery.neighborhood)(
        cepResponse.neighborhood
      );
      onChangeFormInputRegister(FormRegisterDelivery.street)(
        cepResponse.street
      );
    }
  };

  // Submeter formulário
  const handleSubmitRegister = (e: FormEvent) => {
    e.preventDefault();
    const fielName = formDataRegister.date.toString();
    setErrorRegister({ [fielName]: ['Data de nascimento Inválida'] });
    isFormValidRegister();
    resetFormRegister();
  };

  return (
    <BillingAddressUI
      onChangeCEP={handleOnChangeCEP}
      address={addresses}
      formData={formDataRegister}
      formErrors={formErrorRegister}
      onChangeFormInput={onChangeFormInputRegister}
      selectedAddress={selectedAddress}
      showNewAddressForm={handleShowNewAddress}
      visible={visible}
      onToggleNewAddressForm={() => setShowNewAddressForm(!showNewAddressForm)}
      onSubmitRegister={handleSubmitRegister}
      onSelectAddress={onSelectAddress}
    />
  );
};
