import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AdvertisingPolicies } from 'src/model/AdvertisingPolicies';
import { setLoading } from 'src/redux/loading/loadingSlice';
import { api } from 'src/services/api';
import { AdvertisingUI } from './ui';

export const AdvertisingScreen: React.FC = (): JSX.Element => {
  const [advertising, setAdvertising] = useState<AdvertisingPolicies>();
  const dispatch = useDispatch();
  const getAdvertisingPolicies = async (): Promise<void> => {
    dispatch(setLoading(true));
    try {
      const response = await api.get<AdvertisingPolicies>(
        '/advertising-policies/find-all'
      );
      if (response.status === 200) {
        setAdvertising(response.data);
      }
    } catch (error) {
      console.error('Erro ao buscar categoria:', error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    getAdvertisingPolicies();
    // eslint-disable-next-line
  }, []);
  return <AdvertisingUI advertising={advertising} />;
};
