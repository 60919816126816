import React, { FormEvent, useEffect, useState } from 'react';
import { VideoTutorialManager } from './ui';
import { VideoData } from 'src/model/VideoTutorial';
import { useDispatch } from 'react-redux';
import { setLoading } from 'src/redux/loading/loadingSlice';
import { api } from 'src/services/api';
import useForm from 'src/hooks/useForm';
import validators from 'src/helpers/validators';
import { FormInputVideo, VideoBasic } from '../../types';
import { toast } from 'react-toastify';

const VideoTutorialManagerScreen = () => {
  const [videos, setVideos] = useState<VideoData[]>([]);
  const dispatch = useDispatch();

  const getVideos = async (): Promise<void> => {
    dispatch(setLoading(true));
    try {
      const response = await api.get<VideoData[]>('/video/find-all');
      if (response.status === 200) {
        setVideos(response.data);
      }
    } catch (error) {
      console.error('Erro ao buscar categoria:', error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const {
    formData: formDataVideo,
    formErrors: formErrorsVideo,
    setErrors: setErrorsVideo,
    onChangeFormInput: onChangeFormInputVideo,
    isFormValid: isFormValidVideo,
    resetForm: resetFormVideo,
  } = useForm({
    initialData: {
      videoUrl: '',
      title: '',
      subtitle: '',
      position: '',
    },
    validators: {
      videoUrl: [validators.required],
      title: [validators.required],
      subtitle: [validators.required],
      position: [validators.required],
    },
  });

  const handleRegisterVideo = async (e: FormEvent): Promise<void> => {
    try {
      e.preventDefault();
      if (isFormValidVideo()) {
        dispatch(setLoading(true));
        const payload: VideoBasic = {
          link: formDataVideo[FormInputVideo.videoUrl],
          description: formDataVideo[FormInputVideo.title],
          name: formDataVideo[FormInputVideo.subtitle],
          position: formDataVideo[FormInputVideo.position],
        };
        console.log(payload);
        const response = await api.post<VideoData>('/tutorial', payload);
        if (response.status === 201 || response.status === 200) {
          getVideos();
          resetFormVideo();
          toast.success('Dados salvos com sucesso!');
        } else if (response.status === 400) {
          const errorMessage =
            typeof response.data === 'string'
              ? response.data
              : JSON.stringify(response.data);
          toast.error(errorMessage);
        }
      } else {
        toast.error('Verifique os dados');
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      let errorMessage =
        'Falha ao realizar o cadastro, tente novamente mais tarde';
      if (error.response && error.response.data) {
        errorMessage =
          typeof error.response.data === 'string'
            ? error.response.data
            : JSON.stringify(error.response.data);
      }
      toast.error(errorMessage);
      setErrorsVideo({
        name: [errorMessage],
      });
    } finally {
      dispatch(setLoading(false));
    }
  };
  useEffect(() => {
    getVideos();
    // eslint-disable-next-line
  }, []);

  return (
    <VideoTutorialManager
      videos={videos}
      formData={formDataVideo}
      formErrors={formErrorsVideo}
      onChangeFormInput={onChangeFormInputVideo}
      onSubmitRegister={handleRegisterVideo}
      //   handleEditVideo={handleEditVideo}
      //   handleDeleteVideo={handleDeleteVideo}
    />
  );
};

export default VideoTutorialManagerScreen;
