import React, { FormEvent } from 'react';
import { Button } from 'src/components/Button';
import { InputText } from 'src/components/InputText';
import { FormRegisterProduct } from 'src/features/createProduct/types/types';
import { UseFormReturn } from 'src/hooks';
import { Tags } from 'src/model/Tags';

interface CreateTagUIProps
  extends Pick<UseFormReturn, 'formData' | 'formErrors' | 'onChangeFormInput'> {
  onSubmitRegister: (e: FormEvent) => void;
  tags: Tags[];
}

export const CreateTagUI: React.FC<CreateTagUIProps> = ({
  formData,
  formErrors,
  tags,
  onChangeFormInput,
  onSubmitRegister,
}) => {
  return (
    <>
      <form
        onSubmit={onSubmitRegister}
        className="flex-col items-center w-full space-y-4"
      >
        <InputText
          name="tag"
          label="Cadastrar Tag"
          placeholder="Digite a tag..."
          value={formData[FormRegisterProduct.tag]}
          onChange={(e) =>
            onChangeFormInput(FormRegisterProduct.tag)(e.target.value)
          }
          error={formErrors.tag && formErrors.tag[0]}
          inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
        />
        {/* Botão de Cadastro */}
        <div className="w-full flex justify-center">
          <Button
            type="submit"
            title="Cadastrar Tag"
            buttonStyle="red"
            size="md"
            className="w-1/4"
          />
        </div>
      </form>

      {/* Preview das Tags */}
      <div className="mt-4 p-4 bg-white shadow-md rounded-lg">
        <div className="flex flex-wrap gap-2">
          {tags.map((tag) => (
            <span
              key={tag.id}
              className="px-3 py-1 bg-gray-100 text-gray-700 rounded-full text-sm shadow-sm"
            >
              {tag.tag}
            </span>
          ))}
        </div>
      </div>
    </>
  );
};
