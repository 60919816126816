// components/PlatformProfitControllerUI.tsx
import React, { FormEvent } from 'react';
import { Button } from 'src/components/Button';
import { InputText } from 'src/components/InputText';
import { FormInputRate } from 'src/features/admin/types';
import { UseFormReturn } from 'src/hooks';
import { Rate } from 'src/model/Rate';

interface PlatformProfitControllerUIProps
  extends Pick<UseFormReturn, 'formData' | 'formErrors' | 'onChangeFormInput'> {
  rate: Rate;
  onSubmitPorcentagem: (e: FormEvent) => void;
}

export const PlatformProfitControllerUI: React.FC<
  PlatformProfitControllerUIProps
> = ({
  rate,
  formData,
  formErrors,
  onSubmitPorcentagem,
  onChangeFormInput,
}) => {
  return (
    <div className="p-6 max-w-md">
      <form
        onSubmit={onSubmitPorcentagem}
        className="mb-10 bg-red-50 p-6 rounded-lg shadow-sm"
      >
        <label
          htmlFor="platform-profit"
          className="block text-lg font-semibold text-red-600 mb-4"
        >
          Defina a Porcentagem dos Ganhos (%)
        </label>
        <div className="flex items-center">
          <InputText
            name="rate"
            type="number"
            value={formData[FormInputRate.rate]}
            onChange={(e) =>
              onChangeFormInput(FormInputRate.rate)(e.target.value)
            }
            error={formErrors.rate && formErrors.rate[0]}
            placeholder="0 - 100"
            min="0"
            max="100"
            inputClassName="flex-1 p-3 text-xl text-red-700 border border-red-200 rounded-lg focus:outline-none focus:ring-1 focus:ring-red-300 transition duration-150"
          />
          <span className="ml-3 text-xl text-red-700 font-medium">%</span>
        </div>
        <Button
          type="submit"
          title="Aplicar Porcentagem"
          buttonStyle="red"
          className="w-full mt-4"
        />
      </form>

      <div className="bg-white text-center text-red-600 p-6 border border-red-300 rounded-lg shadow-sm">
        <h3 className="text-xl font-semibold">Ganhos da Plataforma</h3>
        <p className="text-5xl font-bold mt-4">{rate.value}%</p>
      </div>
    </div>
  );
};
