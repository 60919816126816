import React, { FormEvent } from 'react';
import { CalendarGrey, IconEye, IconEyeClose } from 'src/assets/icons';
import { Button } from 'src/components/Button';
import { Checkbox } from 'src/components/Checkbox';
import { countryOptions } from 'src/components/Country';
import { InputFile } from 'src/components/InputFile';
import { InputText } from 'src/components/InputText';
import { Select } from 'src/components/Select';
import { AuthWrapper } from 'src/features/core/auth/screen/AuthWrapper';
import { FormInputNameRegister } from 'src/features/core/auth/types';
import { classNames } from 'src/helpers/common';
import { UseFormReturn } from 'src/hooks';

export interface NameFiles {
  [key: string]: string;
}

export interface CreateAccountUIProps
  extends Pick<UseFormReturn, 'formData' | 'formErrors' | 'onChangeFormInput'> {
  state: boolean;
  shouldShowPasswordToText: boolean;
  shouldShowPasswordToText2: boolean;
  formNameFiles?: NameFiles;
  onChangeCEP: (value: string) => void;
  onTogglePasswordToText: () => void;
  onTogglePasswordToText2: () => void;
  //   onShouldShowModal: ({ value, title }: ShouldShowModalProps) => void;
  onSubmitRegister: (e: FormEvent) => void;
  onChangeFormFileInput: (
    inputName: string
  ) => (file: File | undefined) => void;
}

export interface OptionProps {
  value?: string | number;
  label: string;
  target?: HTMLInputElement;
}

export const CreateAccountUI: React.FC<CreateAccountUIProps> = ({
  state,
  formData,
  formErrors,
  formNameFiles,
  onChangeFormInput,
  shouldShowPasswordToText,
  shouldShowPasswordToText2,
  onTogglePasswordToText,
  onTogglePasswordToText2,
  //   onShouldShowModal,
  onSubmitRegister,
  onChangeFormFileInput,
  onChangeCEP,
}) => {
  const imageBase64Ref = React.useRef<HTMLInputElement>(null);

  const genderOption: OptionProps[] = [
    { value: 'MALE', label: 'Masculino' },
    { value: 'FAMELE', label: 'Feminino' },
    { value: 'OTHER', label: 'Não Declarar' },
  ];

  const stateOptions: OptionProps[] = [
    { value: 'AC', label: 'Acre' },
    { value: 'AL', label: 'Alagoas' },
    { value: 'AP', label: 'Amapá' },
    { value: 'AM', label: 'Amazonas' },
    { value: 'BA', label: 'Bahia' },
    { value: 'CE', label: 'Ceará' },
    { value: 'DF', label: 'Distrito Federal' },
    { value: 'ES', label: 'Espírito Santo' },
    { value: 'GO', label: 'Goiás' },
    { value: 'MA', label: 'Maranhão' },
    { value: 'MT', label: 'Mato Grosso' },
    { value: 'MS', label: 'Mato Grosso do Sul' },
    { value: 'MG', label: 'Minas Gerais' },
    { value: 'PA', label: 'Pará' },
    { value: 'PB', label: 'Paraíba' },
    { value: 'PR', label: 'Paraná' },
    { value: 'PE', label: 'Pernambuco' },
    { value: 'PI', label: 'Piauí' },
    { value: 'RJ', label: 'Rio de Janeiro' },
    { value: 'RN', label: 'Rio Grande do Norte' },
    { value: 'RS', label: 'Rio Grande do Sul' },
    { value: 'RO', label: 'Rondônia' },
    { value: 'RR', label: 'Roraima' },
    { value: 'SC', label: 'Santa Catarina' },
    { value: 'SP', label: 'São Paulo' },
    { value: 'SE', label: 'Sergipe' },
    { value: 'TO', label: 'Tocantins' },
  ];

  return (
    <AuthWrapper>
      <section className="flex flex-wrap items-center justify-between w-full container m-4 bg-white p-4 rounded-lg shadow-sm">
        <form
          onSubmit={onSubmitRegister}
          className="flex-col items-center w-full space-y-4"
        >
          {/* Parte dados pessoais */}
          <h1 className="text-gray-800 text-4xl font-bold mb-8 text-center">
            Cadastro de Usuário
          </h1>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <InputText
              name="name"
              label="Nome Completo"
              placeholder="Digite seu nome completo..."
              maxLength={100}
              value={formData[FormInputNameRegister.name]}
              onChange={(e) =>
                onChangeFormInput(FormInputNameRegister.name)(e.target.value)
              }
              error={formErrors.name && formErrors.name[0]}
              className=""
              inputClassName=" rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <InputText
              name="document"
              label="CPF"
              placeholder="Digite seu CPF..."
              value={formData[FormInputNameRegister.document]}
              onChange={(e) =>
                onChangeFormInput(FormInputNameRegister.document)(
                  e.target.value
                )
              }
              error={formErrors.document && formErrors.document[0]}
              className=""
              inputClassName=" rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
            />
            <Select
              name="gender"
              label="Sexo"
              value={formData[FormInputNameRegister.gender]}
              onChange={(e) =>
                onChangeFormInput(FormInputNameRegister.gender)(e.target.value)
              }
              error={formErrors.gender && formErrors.gender[0]}
              options={genderOption}
              className=""
              selectClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
              optionClassName=""
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <InputText
              name="date"
              label="Data de Nascimento"
              placeholder="DD/MM/AAAA"
              value={formData[FormInputNameRegister.date]}
              onChange={(e) =>
                onChangeFormInput(FormInputNameRegister.date)(e.target.value)
              }
              className=""
              inputClassName=" rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
              error={formErrors.date && formErrors.date[0]}
              renderForward={
                <button type="button" className="absolute right-4 top-[50%]">
                  <img src={CalendarGrey} alt="Selecionar data" />
                </button>
              }
            />
            <InputText
              name="phone"
              label="Telefone"
              placeholder="Digite seu telefone..."
              value={formData[FormInputNameRegister.phone]}
              onChange={(e) =>
                onChangeFormInput(FormInputNameRegister.phone)(e.target.value)
              }
              error={formErrors.phone && formErrors.phone[0]}
              className=""
              inputClassName=" rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
            />
          </div>

          {/* Parte input file*/}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <InputText
              name="email"
              label="E-mail"
              placeholder="Digite seu e-mail..."
              value={formData[FormInputNameRegister.email]}
              onChange={(e) =>
                onChangeFormInput(FormInputNameRegister.email)(e.target.value)
              }
              error={formErrors.email && formErrors.email[0]}
              className=""
              inputClassName=" rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
            />
            <InputFile
              refInput={imageBase64Ref}
              name="imageBase64"
              label="Foto"
              fileName={formNameFiles?.imageBase64}
              onChange={(e) =>
                onChangeFormFileInput(FormInputNameRegister.imageBase64)(
                  e.target.files?.[0]
                )
              }
              error={formErrors.imageBase64 && formErrors.imageBase64[0]}
            />
          </div>

          {/* Parte endereço */}
          <h2 className="mt-10 text-gray-800 text-lg font-semibold">
            Endereço:
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <InputText
              name="zipCode"
              label="CEP"
              placeholder="00000-000"
              value={formData[FormInputNameRegister.zipCode]}
              onChange={(e) => {
                onChangeFormInput(FormInputNameRegister.zipCode)(
                  e.target.value
                );
                onChangeCEP(e.target.value);
              }}
              error={formErrors.zipCode && formErrors.zipCode[0]}
              className=""
              inputClassName=" rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
            />
            <InputText
              name="street"
              label="Rua/Avenida/Conjunto"
              placeholder="Digite aqui..."
              value={formData[FormInputNameRegister.street]}
              onChange={(e) =>
                onChangeFormInput(FormInputNameRegister.street)(e.target.value)
              }
              error={formErrors.street && formErrors.street[0]}
              className=""
              inputClassName=" rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <InputText
              name="number"
              label="Número"
              placeholder="Digite o número..."
              value={formData[FormInputNameRegister.number]}
              onChange={(e) =>
                onChangeFormInput(FormInputNameRegister.number)(e.target.value)
              }
              error={formErrors.number && formErrors.number[0]}
              className=""
              inputClassName=" rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
            />
            <InputText
              name="neighborhood"
              label="Bairro"
              placeholder="Digite o bairro..."
              value={formData[FormInputNameRegister.neighborhood]}
              onChange={(e) =>
                onChangeFormInput(FormInputNameRegister.neighborhood)(
                  e.target.value
                )
              }
              error={formErrors.neighborhood && formErrors.neighborhood[0]}
              className=""
              inputClassName=" rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <InputText
              name="city"
              label="Cidade"
              placeholder="Digite a cidade..."
              value={formData[FormInputNameRegister.city]}
              onChange={(e) =>
                onChangeFormInput(FormInputNameRegister.city)(e.target.value)
              }
              error={formErrors.city && formErrors.city[0]}
              className=""
              inputClassName=" rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
            />
            <Select
              name="state"
              label="Estado"
              value={formData[FormInputNameRegister.state]}
              onChange={(e) =>
                onChangeFormInput(FormInputNameRegister.state)(e.target.value)
              }
              error={formErrors.state && formErrors.state[0]}
              options={stateOptions}
              className=""
              selectClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
              optionClassName=""
            />
            <Select
              name="country"
              label="Pais"
              placeholder="Selecione..."
              value={formData[FormInputNameRegister.country]}
              onChange={(e) =>
                onChangeFormInput(FormInputNameRegister.country)(
                  e?.target.value as string
                )
              }
              className="col-span-2"
              error={formErrors.country && formErrors.country[0]}
              options={countryOptions}
              selectClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
              optionClassName=""
            ></Select>
          </div>

          {/* Parte acesso e submit */}
          <div className="flex flex-wrap w-full space-y-6">
            <h1 className="w-full mt-10 mb-2 text-gray-700 text-lg font-dmsans font-bold">
              Acesso:
            </h1>

            <div className=" w-full grid grid-cols-1 md:grid-cols-1 space-y-4">
              <div className="  grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className=" space-y-4">
                  <InputText
                    name="password"
                    label="Senha"
                    type={shouldShowPasswordToText ? 'text' : 'password'}
                    placeholder="**********"
                    value={formData[FormInputNameRegister.password]}
                    maxLength={15}
                    className=""
                    inputClassName=" rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
                    onChange={(e) =>
                      onChangeFormInput(FormInputNameRegister.password)(
                        e.target.value
                      )
                    }
                    error={formErrors.password && formErrors.password[0]}
                    renderForward={
                      <button
                        className={classNames(
                          formErrors.password && formErrors.password[0]
                            ? 'right-0'
                            : 'right-0 ',
                          'absolute cursor-pointer mr-4'
                        )}
                        onClick={onTogglePasswordToText}
                        type="button"
                      >
                        <div className="h-8 flex flex-col justify-center items-center">
                          {shouldShowPasswordToText ? (
                            <img src={IconEye} alt="Mostrar senha" />
                          ) : (
                            <img src={IconEyeClose} alt="Ocultar senha" />
                          )}
                        </div>
                      </button>
                    }
                  />

                  <InputText
                    name="confirmPassword"
                    label="Confirme a sua senha"
                    type={shouldShowPasswordToText2 ? 'text' : 'password'}
                    placeholder="**********"
                    value={formData[FormInputNameRegister.confirmPassword]}
                    maxLength={15}
                    className=""
                    inputClassName=" rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
                    onChange={(e) =>
                      onChangeFormInput(FormInputNameRegister.confirmPassword)(
                        e.target.value
                      )
                    }
                    error={
                      formErrors.confirmPassword &&
                      formErrors.confirmPassword[0]
                    }
                    renderForward={
                      <button
                        className={classNames(
                          formErrors.confirmPassword &&
                            formErrors.confirmPassword[0]
                            ? 'right-0 '
                            : 'right-0 ',
                          'absolute cursor-pointer mr-4'
                        )}
                        onClick={onTogglePasswordToText2}
                        type="button"
                      >
                        <div className="h-8 flex flex-col justify-center items-center">
                          {shouldShowPasswordToText2 ? (
                            <img src={IconEye} alt="Mostrar senha" />
                          ) : (
                            <img src={IconEyeClose} alt="Ocultar senha" />
                          )}
                        </div>
                      </button>
                    }
                  />
                </div>

                <div className=" text-neutro-w-600 text-base font-dmsans font-normal">
                  <p className="mb-5">Sua senha deve conter:</p>
                  <ul className="text-neutro-w-500 list-disc list-item ml-6">
                    <li>No mínimo 8 caracteres;</li>
                    <li>Pelo menos um número;</li>
                    <li>Pelo menos um caractere maiúsculo.</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="w-full">
              <Checkbox
                name="terms"
                value={formData[FormInputNameRegister.terms]}
                className="mb-[20px]"
                onChange={(checked) =>
                  onChangeFormInput(FormInputNameRegister.terms)(checked)
                }
                error={formErrors.terms && formErrors.terms[0]}
                renderForward={
                  <div className="ml-3 text-label text-base font-dmsans font-regular mr-2">
                    Eu aceito
                    <a
                      className="underline underline-offset-2 md:inline-block
            align-baseline text-label text-base font-normal font-dmsans mx-1"
                      href="#"
                      //   onClick={() =>
                      //     onShouldShowModal({
                      //       value: ShouldShowModal.TERMS,
                      //       title: 'Termos e Política de Privacidade',
                      //     })
                      //   }
                    >
                      Termos e Política de Privacidade
                    </a>
                    do site
                  </div>
                }
              />
            </div>

            <div className="w-full flex justify-center">
              <Button
                type="submit"
                title="Criar minha conta"
                buttonStyle="red"
                size="xlg"
                className="w-[80%]"
                disabled={
                  formData[FormInputNameRegister.name] === '' ||
                  formData[FormInputNameRegister.document] === '' ||
                  formData[FormInputNameRegister.email] === '' ||
                  formData[FormInputNameRegister.phone] === '' ||
                  formData[FormInputNameRegister.date] === '' ||
                  formData[FormInputNameRegister.password] === '' ||
                  formData[FormInputNameRegister.confirmPassword] === '' ||
                  formData[FormInputNameRegister.terms] === '' ||
                  formData[FormInputNameRegister.terms] === 'false' ||
                  state
                }
              />
            </div>
          </div>
        </form>
      </section>
    </AuthWrapper>
  );
};
