// src/components/PrivacySettings.tsx
import React from 'react';

export const PrivacySettings: React.FC = () => {
  const handleDeleteAccount = () => {
    alert('Você solicitou a exclusão da conta.');
  };

  return (
    <div className="w-full">
      <div className="flex justify-between items-center border-b border-red-200 pb-4 mb-4">
        <span className="text-gray-700">Solicitar Exclusão de Conta</span>
        <button
          onClick={handleDeleteAccount}
          className="bg-red-500 text-white px-6 py-2 rounded-md hover:bg-red-600"
        >
          Excluir
        </button>
      </div>
    </div>
  );
};
