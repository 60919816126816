// SocialLinksManager.tsx
import React, { FormEvent } from 'react';
import { Button } from 'src/components/Button';
import { InputText } from 'src/components/InputText';
import { FormInputSocialMedia } from 'src/features/admin/types';
import { UseFormReturn } from 'src/hooks';

interface SocialLinksManagerProps
  extends Pick<UseFormReturn, 'formData' | 'formErrors' | 'onChangeFormInput'> {
  onSubmitRegisterSocialMedia: (e: FormEvent) => void;
}

export const SocialLinksManager: React.FC<SocialLinksManagerProps> = ({
  formData,
  formErrors,
  onChangeFormInput,
  onSubmitRegisterSocialMedia,
}) => {
  return (
    <div className="p-4">
      <form onSubmit={onSubmitRegisterSocialMedia}>
        <InputText
          name="instagram"
          label="Instagram"
          placeholder="Digite aqui..."
          maxLength={100}
          value={formData[FormInputSocialMedia.instagram]}
          onChange={(e) =>
            onChangeFormInput(FormInputSocialMedia.instagram)(e.target.value)
          }
          error={formErrors.instagram && formErrors.instagram[0]}
          inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
        />
        <InputText
          name="whatsapp"
          label="WhatsApp"
          placeholder="Digite aqui..."
          maxLength={100}
          value={formData[FormInputSocialMedia.whatsapp]}
          onChange={(e) =>
            onChangeFormInput(FormInputSocialMedia.whatsapp)(e.target.value)
          }
          error={formErrors.whatsapp && formErrors.whatsapp[0]}
          inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
        />
        <InputText
          name="tiktok"
          label="TikTok"
          placeholder="Digite aqui..."
          maxLength={100}
          value={formData[FormInputSocialMedia.tiktok]}
          onChange={(e) =>
            onChangeFormInput(FormInputSocialMedia.tiktok)(e.target.value)
          }
          error={formErrors.tiktok && formErrors.tiktok[0]}
          inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
        />
        <InputText
          name="youtube"
          label="Youtube"
          placeholder="Digite aqui..."
          maxLength={100}
          value={formData[FormInputSocialMedia.youtube]}
          onChange={(e) =>
            onChangeFormInput(FormInputSocialMedia.youtube)(e.target.value)
          }
          error={formErrors.youtube && formErrors.youtube[0]}
          inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
        />
        <div>
          <Button
            type="submit"
            title="Criar Nova Categoria"
            buttonStyle="red"
            size="lg"
          />
        </div>
      </form>
    </div>
  );
};
