import React, { FormEvent } from 'react';
import { Button } from 'src/components/Button';
import { HtmlCssInterpreter } from 'src/components/HtmlCssInterpreter';
import TextArea from 'src/components/TextArea';
import { FormContentTextarea } from 'src/features/admin/types';
import { UseFormReturn } from 'src/hooks';

export interface PrivacyManagerUIProps
  extends Pick<UseFormReturn, 'formData' | 'formErrors' | 'onChangeFormInput'> {
  onSubmitRegister: (e: FormEvent) => void;
}

export const PrivacyManagerUI: React.FC<PrivacyManagerUIProps> = ({
  formData,
  formErrors,
  onChangeFormInput,
  onSubmitRegister,
}) => {
  return (
    <>
      <form onSubmit={onSubmitRegister} className="p-4">
        <TextArea
          name={FormContentTextarea.HTML}
          value={formData[FormContentTextarea.HTML]}
          onChange={(e) =>
            onChangeFormInput(FormContentTextarea.HTML)(e.target.value)
          }
          error={
            formErrors[FormContentTextarea.HTML] &&
            formErrors[FormContentTextarea.HTML][0]
          }
          placeholder="<h1 style='color: blue;'>Seu HTML e CSS aqui</h1>"
          className="w-full mb-4"
          textAreaClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
        />
        <Button type="submit" title="Salvar" buttonStyle="red" />
      </form>
      <h3 className="font-semibold mb-2">Pré-visualização:</h3>
      <HtmlCssInterpreter content={formData[FormContentTextarea.HTML]} />
    </>
  );
};
