import React, { FormEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useForm from 'src/hooks/useForm';
import validators from 'src/helpers/validators';
import { api } from 'src/services/api';
import { toast } from 'react-toastify';
import { setLoading } from 'src/redux/loading/loadingSlice';
import { CreateTagUI } from './ui';
import { FormRegisterProduct } from 'src/features/createProduct/types/types';
import { Tags } from 'src/model/Tags';

export const CreateTagScreen: React.FC = () => {
  const dispatch = useDispatch();
  const [tagsOptions, setTagsOptions] = useState<Tags[]>([]);

  // Carrega as tags existentes ao montar o componente
  const getTags = async (): Promise<void> => {
    dispatch(setLoading(true));
    try {
      const response = await api.get<Tags[]>('/tags/find-all');
      if (response.status === 200) {
        setTagsOptions(response.data);
      }
    } catch (error) {
      console.error('Erro ao buscar tags:', error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const { formData, formErrors, onChangeFormInput, isFormValid, resetForm } =
    useForm({
      initialData: {
        tag: '',
      },
      validators: {
        tag: [validators.required],
      },
    });

  // Função para cadastrar uma nova tag
  const handleRegisterTag = async (e: FormEvent): Promise<void> => {
    e.preventDefault();
    if (isFormValid()) {
      dispatch(setLoading(true));
      try {
        const payload = { tag: formData[FormRegisterProduct.tag] };
        const response = await api.post('/tags', payload);
        if (response.status === 201) {
          // Atualiza a lista de tags
          getTags();
          resetForm();
          toast.success('Tag cadastrada com sucesso!');
        } else {
          toast.error('Erro ao cadastrar a tag');
        }
      } catch {
        toast.error('Erro ao cadastrar a tag');
      } finally {
        dispatch(setLoading(false));
      }
    } else {
      toast.error('Preencha o campo da tag.');
    }
  };

  useEffect(() => {
    getTags();
    // eslint-disable-next-line
  }, []);

  return (
    <CreateTagUI
      formData={formData}
      formErrors={formErrors}
      tags={tagsOptions}
      onChangeFormInput={onChangeFormInput}
      onSubmitRegister={handleRegisterTag}
    />
  );
};
