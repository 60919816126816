import React from 'react';
import { Route } from 'src/navigation/Route';
import { TutorialScreen } from './screens/Tutorial';

export const TUTORIAL_ROUTES = {
  itself: '/tutorial',
};

export const TutorialNavigation = (): JSX.Element => (
  <React.Fragment>
    <Route
      exact
      path={TUTORIAL_ROUTES.itself}
      component={TutorialScreen}
      //   isPrivateRoute={true}
    />
  </React.Fragment>
);
