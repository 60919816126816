import React from 'react';

interface HtmlCssInterpreterProps {
  content: string;
}

export const HtmlCssInterpreter: React.FC<HtmlCssInterpreterProps> = ({
  content,
}) => {
  return (
    <div
      className="preview border p-4 rounded shadow"
      dangerouslySetInnerHTML={{ __html: content }}
    ></div>
  );
};
