import React from 'react';
import { Route } from 'src/navigation/Route';
import { ProfilePage } from './screens';

export const PROFILEPAGE_ROUTES = {
  itself: '/profile',
};

export const ProfilePageNavigation = (): JSX.Element => (
  <React.Fragment>
    <Route exact path={PROFILEPAGE_ROUTES.itself} component={ProfilePage} />
  </React.Fragment>
);
