// src/components/OrderSummary.tsx
import React from 'react';
import { Summary } from '..';

interface OrderSummaryProps {
  summary: Summary;
  onContinueToPayment: () => void;
  onReturnToShopping: () => void;
}

export const OrderSummary: React.FC<OrderSummaryProps> = ({
  summary,
  onContinueToPayment,
  onReturnToShopping,
}) => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md w-full border border-gray-200">
      <h2 className="text-lg font-semibold mb-4">Resumo do Pedido</h2>

      {/* Lista de detalhes do pedido */}
      <div className="space-y-2">
        <div className="flex justify-between">
          <span className="text-gray-600">Preço original</span>
          <span className="font-semibold">
            {Number(summary.originalPrice).toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })}
          </span>
        </div>
        <div className="flex justify-between">
          <span className="text-gray-600">Economia</span>
          <span className="font-semibold text-green-500">
            {Number(summary.savings).toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })}
          </span>
        </div>
        <div className="flex justify-between">
          <span className="text-gray-600">Retirada na Loja</span>
          <span className="font-semibold">
            {Number(summary.storePickup).toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })}
          </span>
        </div>
        <div className="flex justify-between">
          <span className="text-gray-600">Imposto</span>
          <span className="font-semibold">
            {Number(summary.tax).toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })}
          </span>
        </div>
      </div>

      {/* Linha de separação */}
      <div className="border-t my-4"></div>

      {/* Total */}
      <div className="flex justify-between mb-4">
        <span className="font-bold">Total</span>
        <span className="font-bold text-lg">
          {Number(summary.total).toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })}
        </span>
      </div>

      {/* Botão de continuar para pagamento */}
      <button
        onClick={onContinueToPayment}
        className="w-full bg-red-500 text-white font-semibold py-2 rounded-lg hover:bg-red-600 focus:outline-none"
      >
        Pagar
      </button>

      {/* Link para retornar às compras */}
      <div className="text-center mt-4">
        <button
          onClick={onReturnToShopping}
          className="text-red-500 text-sm font-semibold hover:underline"
        >
          Voltar às Compras
        </button>
      </div>
    </div>
  );
};
