import React, { FormEvent, useState } from 'react';
import { DeliveryMethodsUI } from './ui';
import { DeliveryOptions } from 'src/model/DeliveryOptions';

interface DeliveryMethodsScreenProps {
  methods: DeliveryOptions[];
  onApplyCoupon: (coupon: string) => void;
}

export const DeliveryMethodsScreen: React.FC<DeliveryMethodsScreenProps> = ({
  methods,
  onApplyCoupon,
}) => {
  const [selectedMethod, setSelectedMethod] = useState<string>(
    methods[0]?.id || ''
  );
  const [coupon, setCoupon] = useState<string>('');

  const handleMethodChange = (id: string) => {
    setSelectedMethod(id);
  };

  const handleCouponChange = (value: string) => {
    setCoupon(value);
  };

  const handleApplyCoupon = () => {
    if (coupon.trim()) {
      onApplyCoupon(coupon);
      setCoupon('');
    } else {
      alert('Por favor, insira um cupom válido.');
    }
  };
  const handleOnSubmitRegister = async (e: FormEvent): Promise<void> => {
    console.log(e);
  };

  return (
    <DeliveryMethodsUI
      onSubmitRegister={handleOnSubmitRegister}
      methods={methods}
      selectedMethod={selectedMethod}
      coupon={coupon}
      onMethodChange={handleMethodChange}
      onCouponChange={handleCouponChange}
      onApplyCoupon={handleApplyCoupon}
    />
  );
};
