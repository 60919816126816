import React, { useRef, useState } from 'react';
import { Category } from 'src/model/Category';

interface CategoryCarouselProps {
  categories: Category[];
  goCategory?: (id: string) => void;
}

export const CategoryCarousel: React.FC<CategoryCarouselProps> = ({
  categories,
  goCategory,
}) => {
  const carouselRef = useRef<HTMLDivElement>(null);
  const [showButtons, setShowButtons] = useState(false);

  const scrollLeft = () => {
    if (carouselRef.current) {
      if (carouselRef.current.scrollLeft === 0) {
        carouselRef.current.scrollTo({
          left: carouselRef.current.scrollWidth,
          behavior: 'smooth',
        });
      } else {
        carouselRef.current.scrollBy({ left: -200, behavior: 'smooth' });
      }
    }
  };

  const scrollRight = () => {
    if (carouselRef.current) {
      if (
        Math.ceil(
          carouselRef.current.scrollLeft + carouselRef.current.clientWidth
        ) >= carouselRef.current.scrollWidth
      ) {
        carouselRef.current.scrollTo({ left: 0, behavior: 'smooth' });
      } else {
        carouselRef.current.scrollBy({ left: 200, behavior: 'smooth' });
      }
    }
  };

  return (
    <div className="md:mt-6 md:mb-6">
      <div
        className="relative grid justify-items-center"
        onMouseEnter={() => setShowButtons(true)}
        onMouseLeave={() => setShowButtons(false)}
      >
        {/* Título */}
        <h2 className="text-xl font-semibold mb-4">CATEGORIAS</h2>

        {/* Botão de rolagem para a esquerda */}
        {showButtons && (
          <button
            className="absolute left-0 top-1/2 transform -translate-y-1/2 z-10 bg-white/30 hover:bg-white/50 text-gray-800 rounded-full p-2 shadow-lg hidden md:block"
            onClick={scrollLeft}
            aria-label="Scroll Left"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </button>
        )}

        {/* Contêiner de categorias com rolagem horizontal */}
        <div
          ref={carouselRef}
          className="w-full grid grid-rows-2 grid-flow-col gap-x-4 gap-y-6 p-4 overflow-x-auto scrollbar-hide"
          style={{
            gridAutoColumns: 'min-content',
            scrollbarWidth: 'none', // Firefox
            msOverflowStyle: 'none', // IE e Edge
          }}
        >
          <style>{`
          div::-webkit-scrollbar {
            display: none;
          }
        `}</style>

          {categories.map((category, index) => (
            <div key={index} className="flex-none">
              <div className="flex flex-col items-center text-center justify-center p-1">
                {goCategory ? (
                  <a
                    onClick={() => goCategory(category.id)}
                    className="cursor-pointer"
                  >
                    <div className="flex items-center text-center justify-center w-[80px] h-[80px] rounded-full overflow-hidden shadow-md">
                      <img
                        src={category.image}
                        alt={category.name}
                        className=" w-full h-full object-contain"
                      />
                    </div>
                    <p className="mt-2 text-center text-sm">{category.name}</p>
                  </a>
                ) : (
                  <>
                    <div className="flex items-center text-center justify-center w-[80px] h-[80px] rounded-full overflow-hidden shadow-md">
                      <img
                        src={category.image}
                        alt={category.name}
                        className=" w-full h-full object-cover "
                      />
                    </div>
                    <p className="mt-2 text-center text-sm">{category.name}</p>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>

        {/* Botão de rolagem para a direita */}
        {showButtons && (
          <button
            className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10 bg-white/30 hover:bg-white/50 text-gray-800 rounded-full p-2 shadow-lg hidden md:block"
            onClick={scrollRight}
            aria-label="Scroll Right"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 5l7 7-7 7"
              />
            </svg>
          </button>
        )}
      </div>
    </div>
  );
};
