import React from 'react';
// import Banner from 'src/components/Banner';
import { BannerList } from 'src/components/BannerList';
import { CarouselBannerUI } from 'src/components/CarouselBanner';
import { CategoryCarousel } from 'src/components/CategoryCarousel';
import { ProductCard3 } from 'src/components/ProductCard3';
import { AuthWrapper } from 'src/features/core/auth/screen/AuthWrapper';
import { Banners } from 'src/model/Banners';
import { Category } from 'src/model/Category';
import { Product } from 'src/model/Product';

interface HomeUIProps {
  product: Product[];
  categories: Category[];
  banners: Banners[];
  bannersMobile: Banners[];
  bannerService: Banners[];
  bannerImages: Banners[];
  bannerMidleImages: Banners[];
  goCategory: (id: string) => void;
  goSlide?: (id: string) => void;
}

export const HomeUI: React.FC<HomeUIProps> = ({
  product,
  categories,
  banners,
  bannersMobile,
  bannerService,
  bannerImages,
  bannerMidleImages,
  goCategory,
  goSlide,
}) => {
  return (
    <AuthWrapper>
      <div className="w-full grid grid-cols-1 justify-items-center">
        <div className="md:block w-full">
          {CarouselBannerUI && (
            <CarouselBannerUI
              slides={banners}
              hideIndicators={false}
              hideControls={false}
              goSlide={goSlide}
              className="aspect-[4/2] md:aspect-[10/3]"
            />
          )}
        </div>
        <div className="hidden w-full">
          {CarouselBannerUI && (
            <CarouselBannerUI
              slides={bannersMobile}
              hideIndicators={false}
              hideControls={false}
              goSlide={goSlide}
              className="aspect-[4/2] md:aspect-[10/3]"
            />
          )}
        </div>

        <div className="w-full flex flex-wrap md:flex-nowrap items-center mb-8 bg-gray-200 max-h-[8rem]">
          <div className="w-full flex justify-center p-6 md:mt-0 md:w-[65%]">
            {BannerList && <BannerList bannerListData={bannerService} />}
          </div>

          <div className=" w-full md:max-w-[448px] ">
            {/* 450px por 180px */}
            {/* {Banner && <Banner images={bannerImages} interval={6000} />} */}
            {CarouselBannerUI && (
              <CarouselBannerUI
                slides={bannerImages}
                hideIndicators={true}
                hideControls={true}
                goSlide={goSlide}
                className="md:aspect-[10/3]  md:h-[180px] h-150px aspect-[4/2]"
              />
            )}
          </div>
        </div>

        <div className="max-w-[1152px] flex justify-center items-center">
          {categories && categories.length > 0 && (
            <CategoryCarousel categories={categories} goCategory={goCategory} />
          )}
        </div>
        <div className="max-w-[1152px] grid  grid-cols-1 mt-12 md:mt-12 justify-items-center">
          <div className="w-full md:flex justify-center items-center mt-6 mb-6 flex-none hidden">
            {/* 1152px por 180px */}
            {/* {bannerMidleImages && bannerMidleImages.length > 0 && (
              <Banner images={bannerMidleImages} interval={3000} />
            )} */}
            {CarouselBannerUI && (
              <CarouselBannerUI
                slides={bannerMidleImages}
                hideIndicators={true}
                hideControls={true}
                goSlide={goSlide}
                className="aspect-[10/3] md:aspect-none md:h-[180px] "
              />
            )}
          </div>

          {product && <ProductCard3 products={product} />}
        </div>
      </div>
    </AuthWrapper>
  );
};
