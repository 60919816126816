import React, { FormEvent, useEffect, useState } from 'react';
import { FormInputSearch } from '../Search/type';
import { UseFormReturn } from 'src/hooks';
import { FiSearch } from 'react-icons/fi';
import { useParams } from 'react-router-dom';
import { api } from 'src/services/api';
import { Product } from 'src/model/Product';

interface Search2Props
  extends Pick<UseFormReturn, 'formData' | 'formErrors' | 'onChangeFormInput'> {
  onSubmitSearch: (e: FormEvent) => void;
  icon: React.ReactNode;
  iconClassName?: string;
  className?: string; // Classe adicional para o formulário
}

export const Search2: React.FC<Search2Props> = ({
  formData,
  onChangeFormInput,
  onSubmitSearch,
  icon,
  iconClassName = '',
  className = '',
}): JSX.Element => {
  const { keyword } = useParams<{ keyword: string }>();
  const [, setProducts] = useState<Product[]>([]);

  useEffect(() => {
    const findProduct = async (): Promise<void> => {
      try {
        const response = await api.get(`/product/find/${keyword}`, {
          params: { query: keyword },
        });

        if (response.status === 200) {
          const productsList = response.data;

          const filteredProducts = productsList.filter(
            (product: { name: string }) =>
              product.name.toLowerCase() === keyword.toLowerCase()
          );
          setProducts(filteredProducts);
        }
      } catch (error) {
        console.log('Erro ao buscar os produtos: ', error);
      }
    };

    if (keyword) {
      findProduct();
    }
  }, [keyword]);

  return (
    <form
      onSubmit={onSubmitSearch}
      className={`flex items-center bg-white p-2 w-full gap-2 rounded-sm shadow-md ${className}`}
    >
      {/* Input de busca */}
      <div className="flex-grow relative">
        <input
          type="text"
          name="search"
          placeholder="Buscar no Buunpo"
          value={formData[FormInputSearch.search]}
          onChange={(e) =>
            onChangeFormInput(FormInputSearch.search)(e.target.value)
          }
          className="w-full text-gray-600 bg-transparent border-none focus:outline-none focus:ring-0 placeholder-gray-400"
        />
      </div>

      {/* Botão de busca com ícone */}
      <button
        type="submit"
        className="hidden md:flex items-center justify-center rounded p-2 bg-red-500 hover:bg-red-600 transition-colors shrink-0"
      >
        {React.cloneElement(icon as React.ReactElement, {
          className: `w-6 h-6 text-white ${iconClassName}`,
        })}
      </button>
      <button
        type="submit"
        className="md:hidden flex items-center justify-center  transition-colors  shrink-0"
      >
        <FiSearch className="w-5 h-5 text-red-500" />
      </button>
    </form>
  );
};
