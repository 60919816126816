import React, { FormEvent } from 'react';
import { InputText } from 'src/components/InputText';
import { Button } from 'src/components/Button';
import { RadioInput } from 'src/components/InputRadio';
import { DeliveryOptions } from 'src/model/DeliveryOptions';

interface DeliveryMethodsUIProps {
  methods: DeliveryOptions[];
  selectedMethod: string;
  coupon: string;
  onMethodChange: (id: string) => void;
  onCouponChange: (value: string) => void;
  onApplyCoupon: () => void;
  onSubmitRegister: (e: FormEvent) => void;
}

export const DeliveryMethodsUI: React.FC<DeliveryMethodsUIProps> = ({
  methods,
  selectedMethod,
  coupon,
  onMethodChange,
  onCouponChange,
  onApplyCoupon,
  onSubmitRegister,
}) => (
  <form
    onSubmit={onSubmitRegister}
    className="w-full bg-white p-6 rounded-lg shadow-md mb-4 border border-gray-200"
  >
    <h2 className="text-lg font-semibold mb-4">Opções de Envio</h2>

    {/* Opções de Métodos de Entrega */}
    <div className="grid grid-cols-1 gap-4 mb-4">
      {methods.map((method) => (
        <label
          key={method.id}
          className={`grid grid-cols-[1fr_auto] items-center p-4 border rounded-lg cursor-pointer ${
            selectedMethod === method.id
              ? 'border-red-500 bg-red-50'
              : 'border-gray-300'
          }`}
        >
          <div className="flex items-center gap-4">
            <RadioInput
              name="deliveryMethod"
              value={method.id}
              checked={selectedMethod === method.id}
              onChange={() => onMethodChange(method.id)}
            />
            <div className="flex flex-col">
              <span className="font-semibold">{method.label}</span>
              <span className="text-sm text-gray-500">
                {method.description}
              </span>
            </div>
          </div>
          <span className="font-semibold text-red-500">{method.price}</span>
        </label>
      ))}
    </div>

    {/* Campo para Cupom e Botão de Aplicar */}
    <div className=" grid grid-cols-[1fr_auto] items-center gap-2">
      <InputText
        name="coupon"
        value={coupon}
        onChange={(e) => onCouponChange(e.target.value)}
        placeholder="Insira o Codigo"
        inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
      />
      <Button
        type="button"
        title="Aplicar"
        onClick={onApplyCoupon}
        buttonStyle="red"
      />
    </div>
  </form>
);
