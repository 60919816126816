import React, { FormEvent } from 'react';
import { RadioInput } from 'src/components/InputRadio';
import { InputText } from 'src/components/InputText';
import { Select } from 'src/components/Select';
import TextArea from 'src/components/TextArea';
import { UseFormReturn } from 'src/hooks';
import { Address } from 'src/model/Address';
import { FormRegisterDelivery } from '../../../../../types';
import { AddressList } from '../../AddressList';
import { Button } from 'src/components/Button';
import { countryOptions } from 'src/components/Country';

interface BillingAddressUIProps
  extends Pick<UseFormReturn, 'formData' | 'formErrors' | 'onChangeFormInput'> {
  address?: Address[];
  selectedAddress?: string;
  showNewAddressForm: () => void;
  onToggleNewAddressForm: () => void;
  onSubmitRegister: (e: FormEvent) => void;
  onSelectAddress: (id: string) => void;
  onChangeCEP: (value: string) => void;
  visible: boolean;
}
export interface OptionProps {
  value?: string | number;
  label: string;
  target?: HTMLInputElement;
}
// const savedAddressesOptions = [
//   { label: 'Bonnie Green - (+1) 234 567 890', value: 'address1' },
//   { label: 'Flowbite LLC - (+1) 234 567 890', value: 'address2' },
// ];

export const BillingAddressUI: React.FC<BillingAddressUIProps> = ({
  address,
  selectedAddress,
  formData,
  formErrors,
  visible,
  onChangeFormInput,
  onSelectAddress,
  showNewAddressForm,
  //   onToggleNewAddressForm,
  onSubmitRegister,
  onChangeCEP,
}) => {
  const stateOptions: OptionProps[] = [
    { value: 'AC', label: 'Acre' },
    { value: 'AL', label: 'Alagoas' },
    { value: 'AP', label: 'Amapá' },
    { value: 'AM', label: 'Amazonas' },
    { value: 'BA', label: 'Bahia' },
    { value: 'CE', label: 'Ceará' },
    { value: 'DF', label: 'Distrito Federal' },
    { value: 'ES', label: 'Espírito Santo' },
    { value: 'GO', label: 'Goiás' },
    { value: 'MA', label: 'Maranhão' },
    { value: 'MT', label: 'Mato Grosso' },
    { value: 'MS', label: 'Mato Grosso do Sul' },
    { value: 'MG', label: 'Minas Gerais' },
    { value: 'PA', label: 'Pará' },
    { value: 'PB', label: 'Paraíba' },
    { value: 'PR', label: 'Paraná' },
    { value: 'PE', label: 'Pernambuco' },
    { value: 'PI', label: 'Piauí' },
    { value: 'RJ', label: 'Rio de Janeiro' },
    { value: 'RN', label: 'Rio Grande do Norte' },
    { value: 'RS', label: 'Rio Grande do Sul' },
    { value: 'RO', label: 'Rondônia' },
    { value: 'RR', label: 'Roraima' },
    { value: 'SC', label: 'Santa Catarina' },
    { value: 'SP', label: 'São Paulo' },
    { value: 'SE', label: 'Sergipe' },
    { value: 'TO', label: 'Tocantins' },
  ];

  return (
    <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-2xl mb-4">
      <div className="flex flex-row items-center mb-4">
        <h2 className="text-lg font-semibold  mr-4">Endereço de Envio</h2>
        <Button
          buttonStyle="outline"
          title="+"
          onClick={showNewAddressForm}
          className="h-4"
        />
      </div>
      <div className="mb-4">
        <AddressList
          addresses={address}
          onSelectAddress={onSelectAddress}
          selectedAddress={selectedAddress}
        />
      </div>

      {visible && (
        <form
          onSubmit={onSubmitRegister}
          className="flex-col items-center w-full space-y-4"
        >
          <h2 className="text-lg font-semibold mb-4 mr-4">
            Cadastrar Novo Endereço
          </h2>
          <div className="flex items-center mb-4 space-x-4">
            <RadioInput
              name="addressType"
              value="Personal"
              checked={
                formData[FormRegisterDelivery.addressType] === 'Personal'
              }
              onChange={(e) =>
                onChangeFormInput(FormRegisterDelivery.addressType)(
                  e.target.value
                )
              }
              label="Pessoal"
              error={formErrors.addressType && formErrors.addressType[0]}
              inputClassName="rounded-full focus:ring-red-500"
              labelClassName="text-gray-700"
            />
            <RadioInput
              name="addressType"
              value="Company"
              checked={formData[FormRegisterDelivery.addressType] === 'Company'}
              onChange={(e) =>
                onChangeFormInput(FormRegisterDelivery.addressType)(
                  e.target.value
                )
              }
              label="Empresa"
              error={formErrors.addressType && formErrors.addressType[0]}
              inputClassName="rounded-full focus:ring-red-500"
              labelClassName="text-gray-700"
            />
          </div>

          {/* Endereços Salvos */}
          {/* <div className="mb-4">
            <Select
              name="savedAddresses"
              label="Endereços salvos"
              value={formData[FormRegisterDelivery.savedAddresses]}
              onChange={(e) =>
                onChangeFormInput(FormRegisterDelivery.savedAddresses)(
                  e.target.value
                )
              }
              error={formErrors.savedAddresses && formErrors.savedAddresses[0]}
              options={savedAddressesOptions}
              selectClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
            />
          </div> */}

          {/* Parte endereço */}
          <div className="grid grid-cols-1 md:grid-cols-2 md:grid-cols-2 gap-4">
            <div>
              <InputText
                name="nameShipping"
                label="Nome do Recebedor"
                value={formData.nameShipping}
                onChange={(e) =>
                  onChangeFormInput(FormRegisterDelivery.nameShipping)(
                    e.target.value
                  )
                }
                error={formErrors.nameShipping && formErrors.nameShipping[0]}
                placeholder="Digite o Nome..."
                inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
              />
            </div>
            <div>
              <InputText
                name="document"
                label="CPF"
                placeholder="Digite seu CPF..."
                value={formData[FormRegisterDelivery.document]}
                onChange={(e) =>
                  onChangeFormInput(FormRegisterDelivery.document)(
                    e.target.value
                  )
                }
                error={formErrors.document && formErrors.document[0]}
                className=""
                inputClassName=" rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
              />
            </div>
            <div>
              <InputText
                name="email"
                label="E-mail"
                placeholder="Digite seu e-mail..."
                value={formData[FormRegisterDelivery.email]}
                onChange={(e) =>
                  onChangeFormInput(FormRegisterDelivery.email)(e.target.value)
                }
                error={formErrors.email && formErrors.email[0]}
                className=""
                inputClassName=" rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
              />
            </div>
            <div>
              <InputText
                name="phoneShipping"
                label="Numero Telefone"
                value={formData.phoneShipping}
                onChange={(e) =>
                  onChangeFormInput(FormRegisterDelivery.phoneShipping)(
                    e.target.value
                  )
                }
                error={formErrors.phoneShipping && formErrors.phoneShipping[0]}
                placeholder="Insira o telefone"
                inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
              />
            </div>
          </div>

          {/* Campos adicionais para Company */}
          {formData[FormRegisterDelivery.addressType] === 'Company' && (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <InputText
                name="companyShippingName"
                label="Nome da Empresa"
                value={formData.companyShippingName}
                onChange={(e) =>
                  onChangeFormInput(FormRegisterDelivery.companyShippingName)(
                    e.target.value
                  )
                }
                error={
                  formErrors.companyShippingName &&
                  formErrors.companyShippingName[0]
                }
                inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
              />

              <InputText
                name="cnpj"
                label="Número CNPJ"
                value={formData.cnpj}
                onChange={(e) =>
                  onChangeFormInput(FormRegisterDelivery.cnpj)(e.target.value)
                }
                error={formErrors.cnpj && formErrors.cnpj[0]}
                inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
              />
            </div>
          )}

          <div className="md:col-span-2">
            <TextArea
              name="complementShipping"
              label="Complemeto de envio*"
              value={formData.complementShipping}
              onChange={(e) =>
                onChangeFormInput(FormRegisterDelivery.complementShipping)(
                  e.target.value
                )
              }
              error={
                formErrors.complementShipping &&
                formErrors.complementShipping[0]
              }
              placeholder="Insira o complemento de envio"
              textAreaClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <InputText
              name="zipCode"
              label="CEP"
              placeholder="00000-000"
              value={formData[FormRegisterDelivery.zipCode]}
              onChange={(e) => {
                onChangeFormInput(FormRegisterDelivery.zipCode)(e.target.value);
                onChangeCEP(e.target.value);
              }}
              error={formErrors.zipCode && formErrors.zipCode[0]}
              className=""
              inputClassName=" rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
            />
            <InputText
              name="street"
              label="Rua/Avenida/Conjunto"
              placeholder="Digite aqui..."
              value={formData[FormRegisterDelivery.street]}
              onChange={(e) =>
                onChangeFormInput(FormRegisterDelivery.street)(e.target.value)
              }
              error={formErrors.street && formErrors.street[0]}
              className=""
              inputClassName=" rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <InputText
              name="number"
              label="Número"
              placeholder="Digite o número..."
              value={formData[FormRegisterDelivery.number]}
              onChange={(e) =>
                onChangeFormInput(FormRegisterDelivery.number)(e.target.value)
              }
              error={formErrors.number && formErrors.number[0]}
              className=""
              inputClassName=" rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
            />
            <InputText
              name="neighborhood"
              label="Bairro"
              placeholder="Digite o bairro..."
              value={formData[FormRegisterDelivery.neighborhood]}
              onChange={(e) =>
                onChangeFormInput(FormRegisterDelivery.neighborhood)(
                  e.target.value
                )
              }
              error={formErrors.neighborhood && formErrors.neighborhood[0]}
              className=""
              inputClassName=" rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <InputText
              name="city"
              label="Cidade"
              placeholder="Digite a cidade..."
              value={formData[FormRegisterDelivery.city]}
              onChange={(e) =>
                onChangeFormInput(FormRegisterDelivery.city)(e.target.value)
              }
              error={formErrors.city && formErrors.city[0]}
              className=""
              inputClassName=" rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
            />
            <Select
              name="state"
              label="Estado"
              value={formData[FormRegisterDelivery.state]}
              onChange={(e) =>
                onChangeFormInput(FormRegisterDelivery.state)(e.target.value)
              }
              error={formErrors.state && formErrors.state[0]}
              options={stateOptions}
              className=""
              selectClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
              optionClassName=""
            />
            <Select
              name="country"
              label="Pais"
              placeholder="Selecione..."
              value={formData[FormRegisterDelivery.country]}
              onChange={(e) =>
                onChangeFormInput(FormRegisterDelivery.country)(
                  e?.target.value as string
                )
              }
              className="col-span-2"
              error={formErrors.country && formErrors.country[0]}
              options={countryOptions}
              selectClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
              optionClassName=""
            ></Select>
          </div>
        </form>
      )}
    </div>
  );
};
