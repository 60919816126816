import React, { FormEvent } from 'react';
import { Button } from 'src/components/Button';
import { InputFile } from 'src/components/InputFile';
import { InputText } from 'src/components/InputText';
import { Select } from 'src/components/Select';
import { NameFiles } from 'src/features/createAccount/screens/CreateAccount/ui';
import { FormRegisterProduct } from 'src/features/createProduct/types/types';
import { UseFormReturn } from 'src/hooks';
import { Category } from 'src/model/Category';
// import TagSelector from '../components/TagSelector';
import CategorySelector from './../components/CategorySelect';
import TextArea from 'src/components/TextArea';
import { GradeForm } from '../components/GradeForm';
import { Grade } from 'src/model/Grade';
import InputTags from '../components/InputsTags';

interface CreateProductUIProps
  extends Pick<UseFormReturn, 'formData' | 'formErrors' | 'onChangeFormInput'> {
  state?: boolean;
  formNameFiles?: NameFiles;
  categoryOptions: Category[];
  selectedCategories: Category[];
  filesImage: File[];
  previewFiles: string[];
  inputTags: string[];
  grade: Grade[];
  onSubmitRegister: (e: FormEvent) => void;
  FileUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeFormFileInput: (
    inputName: string
  ) => (file: File | undefined) => void;
  onDeleteFile: (value: number) => void;
  onAddTag: (tag: string) => void;
  onRemoveTag: (value: string) => void;
  onSelectCategory: (e: React.FocusEvent<HTMLSelectElement>) => void;
  onRemoveCategory: (value: string) => void;
  addQuantiValue: (e: FormEvent) => void;
  removeGrade: (index: number) => void;
}

const statusOptions = [
  { value: 'Ativo', label: 'Ativo' },
  { value: 'Inativo', label: 'Inativo' },
  { value: 'Estoque Zerado', label: 'Estoque Zerado' },
];
const promotionOptions = [
  { value: 'yes', label: 'Sim' },
  { value: 'no', label: 'Não' },
];

export const CreateProductUI: React.FC<CreateProductUIProps> = ({
  formData,
  formErrors,
  selectedCategories,
  categoryOptions,
  inputTags,
  filesImage,
  previewFiles,
  grade,
  onChangeFormInput,
  onSubmitRegister,
  FileUpload,
  onDeleteFile,
  onAddTag,
  onRemoveTag,
  onSelectCategory,
  onRemoveCategory,
  addQuantiValue,
  removeGrade,
}) => {
  return (
    <section className="flex flex-wrap items-center justify-between w-full container m-4 bg-white p-4 rounded-lg shadow-sm">
      <form
        onSubmit={onSubmitRegister}
        className="flex-col items-center w-full space-y-4"
      >
        <h1 className="text-gray-800 text-4xl font-bold mb-8 text-center">
          Cadastro de Produto
        </h1>
        <InputText
          name="name"
          label="Nome do Produto *"
          placeholder="Digite o nome do produto..."
          value={formData[FormRegisterProduct.name]}
          onChange={(e) =>
            onChangeFormInput(FormRegisterProduct.name)(e.target.value)
          }
          error={formErrors.name && formErrors.name[0]}
          inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
        />
        <TextArea
          name="description"
          label="Descrição *"
          placeholder="Descreva o produto..."
          value={formData[FormRegisterProduct.description]}
          onChange={(e) =>
            onChangeFormInput(FormRegisterProduct.description)(e.target.value)
          }
          error={formErrors.description && formErrors.description[0]}
          textAreaClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
        />
        <div className="grid grid-cols-1 md:grid-cols-3 grid-rows-3 md:grid-rows-1 gap-4">
          {/* Status do Produto */}
          <InputText
            name="sku"
            label="SKU *"
            placeholder="Digite o código do SKU..."
            value={formData[FormRegisterProduct.sku]}
            onChange={(e) =>
              onChangeFormInput(FormRegisterProduct.sku)(e.target.value)
            }
            error={formErrors.sku && formErrors.sku[0]}
            inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
          />
          <Select
            name="status"
            label="Status *"
            value={formData[FormRegisterProduct.status]}
            onChange={(e) =>
              onChangeFormInput(FormRegisterProduct.status)(e.target.value)
            }
            error={formErrors.status && formErrors.status[0]}
            options={statusOptions}
            selectClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
          />
          {/* Promoção */}
          <Select
            name="promotion"
            label="Promoção *"
            value={formData[FormRegisterProduct.promotion]}
            onChange={(e) =>
              onChangeFormInput(FormRegisterProduct.promotion)(e.target.value)
            }
            error={formErrors.promotion && formErrors.promotion[0]}
            options={promotionOptions}
            selectClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-4 grid-rows-3 md:grid-rows-1 gap-4">
          <InputText
            name="stock"
            label="Estoque *"
            type="number"
            placeholder="Digite a quantidade em estoque..."
            value={formData[FormRegisterProduct.stock]}
            onChange={(e) =>
              onChangeFormInput(FormRegisterProduct.stock)(e.target.value)
            }
            error={formErrors.stock && formErrors.stock[0]}
            inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
          />
          {/* Preço */}
          <InputText
            name="price"
            label="Preço *"
            type="number"
            placeholder="Digite o Preço..."
            value={formData[FormRegisterProduct.price]}
            onChange={(e) =>
              onChangeFormInput(FormRegisterProduct.price)(e.target.value)
            }
            error={formErrors.price && formErrors.price[0]}
            inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
          />
          <InputText
            name="parcel"
            label="Parcelas *"
            type="number"
            max={12}
            placeholder="Digite o numero de parcelas..."
            value={formData[FormRegisterProduct.parcel]}
            onChange={(e) =>
              onChangeFormInput(FormRegisterProduct.parcel)(e.target.value)
            }
            error={formErrors.parcel && formErrors.parcel[0]}
            inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
          />
          <InputText
            name="discount"
            label="Preço com Desconto"
            type="number"
            placeholder="Digite o Preço..."
            value={formData[FormRegisterProduct.priceDiscount]}
            onChange={(e) =>
              onChangeFormInput(FormRegisterProduct.priceDiscount)(
                e.target.value
              )
            }
            error={formErrors.priceDiscount && formErrors.priceDiscount[0]}
            inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
            disabled={
              formData[FormRegisterProduct.promotion] === 'yes' ? false : true
            }
          />
          {/* SKU */}
        </div>
        {/* Seletor de Categorias */}
        <CategorySelector
          categorys={categoryOptions}
          selectedCategories={selectedCategories}
          selectCategory={onSelectCategory}
          removeCategory={onRemoveCategory}
          error={
            selectedCategories.length === 0
              ? 'Selecione pelo menos uma Categoria'
              : null
          }
        />
        <InputTags
          tags={inputTags}
          addTag={onAddTag}
          removeTag={onRemoveTag}
          error={inputTags.length === 0 ? 'Adicione pelo menos uma tag.' : null}
          inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
        />
        {/* Marca */}
        <InputText
          name="brand"
          label="Marca *"
          placeholder="Digite a marca do produto..."
          value={formData[FormRegisterProduct.brandName]}
          onChange={(e) =>
            onChangeFormInput(FormRegisterProduct.brandName)(e.target.value)
          }
          error={formErrors.brandName && formErrors.brandName[0]}
          inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
        />
        {/* Dimensões */}
        <div className="grid grid-cols-1 md:grid-cols-4 grid-rows-4 md:grid-rows-1 gap-4">
          <InputText
            name="dimensions.width"
            label="Largura *"
            placeholder="Digite a largura..."
            value={formData[FormRegisterProduct.dimensionsWidth]}
            onChange={(e) =>
              onChangeFormInput(FormRegisterProduct.dimensionsWidth)(
                e.target.value
              )
            }
            error={formErrors.dimensionsWidth && formErrors.dimensionsWidth[0]}
            inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
          />

          <InputText
            name="dimensions.height"
            label="Altura *"
            placeholder="Digite a altura..."
            value={formData[FormRegisterProduct.dimensionsHeight]}
            onChange={(e) =>
              onChangeFormInput(FormRegisterProduct.dimensionsHeight)(
                e.target.value
              )
            }
            error={
              formErrors.dimensionsHeight && formErrors.dimensionsHeight[0]
            }
            inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
          />

          <InputText
            name="dimensions.depth"
            label="Profundidade *"
            placeholder="Digite a profundidade..."
            value={formData[FormRegisterProduct.dimensionsDepth]}
            onChange={(e) =>
              onChangeFormInput(FormRegisterProduct.dimensionsDepth)(
                e.target.value
              )
            }
            error={formErrors.dimensionsDepth && formErrors.dimensionsDepth[0]}
            inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
          />

          <InputText
            name="dimensions.weight"
            label="Peso *"
            placeholder="Digite o peso..."
            value={formData[FormRegisterProduct.dimensionsWeight]}
            onChange={(e) =>
              onChangeFormInput(FormRegisterProduct.dimensionsWeight)(
                e.target.value
              )
            }
            error={
              formErrors.dimensionsWeight && formErrors.dimensionsWeight[0]
            }
            inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
          />
        </div>
        <GradeForm
          formData={formData}
          formErrors={formErrors}
          grade={grade}
          onChangeFormInput={onChangeFormInput}
          addQuantiValue={addQuantiValue}
          removeGrade={removeGrade}
        />
        {/* Garantia */}
        <InputText
          name="warranty"
          label="Garantia *"
          placeholder="Digite ..."
          value={formData[FormRegisterProduct.warranty]}
          onChange={(e) =>
            onChangeFormInput(FormRegisterProduct.warranty)(e.target.value)
          }
          error={formErrors.warranty && formErrors.warranty[0]}
          inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
        />
        {/* Imagem */}
        {/* File Upload */}
        <div>
          <label>
            Upload de até{' '}
            <span className="text-red-500 font-semibold">
              4 imagens e 1 vídeo ou 5 imagens
            </span>
            <br />
            tamanho recomendado{' '}
            <span className="text-red-500 font-semibold">
              Min 800px X 800px
            </span>{' '}
            ou{' '}
            <span className="text-red-500 font-semibold">
              Max 1600px X 1600px
            </span>
          </label>
          <InputFile
            name="mediaFiles"
            label="Imagens ou vídeo"
            multiple
            onChange={FileUpload}
            error={filesImage.length > 5 ? 'Máximo de 5 arquivos' : undefined}
          />

          <div className="flex space-x-2 mt-2">
            {previewFiles.map((preview, index) => (
              <div key={index} className="relative">
                <img
                  src={preview}
                  alt={`File ${index}`}
                  className="w-24 h-24 object-cover rounded"
                />
                <button
                  type="button"
                  className="absolute top-0 right-0 bg-red-500 text-white p-1 rounded-full"
                  onClick={() => onDeleteFile(index)}
                >
                  X
                </button>
              </div>
            ))}
          </div>
          {/* YouTube URL */}
          {/* <InputText
              name="youtubeUrl"
              label="URL do YouTube (opcional)"
              placeholder="Insira o link do YouTube"
              value={youtubeUrl}
              onChange={handleYoutubeUrl}
              error={
                files.some((file) => file.type.startsWith('video/'))
                  ? 'Não pode enviar vídeo e YouTube juntos.'
                  : undefined
              }
            /> */}
        </div>
        {/* Botão de Cadastro */}
        <div className="w-full flex justify-center">
          <Button
            type="submit"
            title="Cadastrar Produto"
            buttonStyle="red"
            size="xlg"
            className="w-[80%]"
            disabled={
              formData.name === '' ||
              formData.description === '' ||
              formData.status === '' ||
              formData.price === '' ||
              selectedCategories.length === 0
            }
          />
        </div>
      </form>
    </section>
  );
};
