import React from 'react';
import { ProductInfo } from 'src/components/ProductInfo';
import { ProductSlider } from 'src/components/ProductSlider';
// import { ReviewForm } from 'src/components/ReviewForm';
import { ReviewList } from 'src/components/ReviewList';
import { AuthWrapper } from 'src/features/core/auth/screen/AuthWrapper';
import { Product } from 'src/model/Product';

interface ProductUIProps {
  products: Product;
  quantity: number;
  onAddCart: () => void;
  quantityChange: (increment: boolean) => void;
}

export const ProductUI: React.FC<ProductUIProps> = ({
  products,
  quantity,
  onAddCart,
  quantityChange,
}): JSX.Element => {
  //   const calculateDiscountPercentage = (
  //     originalPrice: number,
  //     discountedPrice: number
  //   ): string => {
  //     const original = originalPrice;
  //     const discount = discountedPrice;

  //     if (original > 0) {
  //       return ((1 - discount / original) * 100).toFixed(0);
  //     }
  //     return '0';
  //   };

  //   const installmentValue = (price: number): string => {
  //     if (price === 0) return '';
  //     const installmentAmount = (price / 12).toFixed(2);
  //     return `ou R$ ${price.toLocaleString('pt-BR', { minimumFractionDigits: 2 })} em 12x de R$ ${parseFloat(installmentAmount).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}`;
  //   };

  return (
    <AuthWrapper>
      {products && (
        <div className="flex flex-wrap w-full container m-4 gap-4 bg-white p-4 rounded-lg shadow-sm">
          <div className="w-full md:w-[65%] md:h-[500px]">
            <ProductSlider imagens={products.imagens} />
          </div>
          <div className="w-full md:w-[33%]">
            <ProductInfo
              products={products}
              addCart={onAddCart}
              quantity={quantity}
              quantityChange={quantityChange}
            />
          </div>

          <div className=" w-full md:container m-4 gap-4">
            <div>
              <hr className="border-t-2 border-gray-300 my-4" />
              <h1 className="text-lg font-bold mb-2 mt-4">{products.name}</h1>
            </div>
            <div className="text-gray-700 p-3 rounded-md">
              {products.description}
            </div>
            {/* Formulário de Avaliação */}
            {/* <ReviewForm
              formData={formData}
              formErrors={formErrors}
              rating={rating}
              onChangeFormInput={onChangeFormInput}
              onSubmitRegister={onSubmitRegister}
              onChangeRating={onChangeRating}
            /> */}
            {products.review && products.review.length > 0 ? (
              <ReviewList reviews={products.review} />
            ) : (
              <>
                <hr className="mt-6 mb-6" />
                <p> Ainda não tem avaliação</p>
              </>
            )}
          </div>
        </div>
      )}
    </AuthWrapper>
  );
};
