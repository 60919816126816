import { FormEvent } from 'react';
import {
  FormErrors,
  IsFormValid,
  OnChangeFormInput,
  RunAllValidators,
  SetErrors,
  SetFormData,
  SetFormErrors,
} from 'src/hooks';

export enum FormInputNameCreditCard {
  number = 'number',
  date = 'date',
  cvv = 'cvv',
  name = 'name',
  document = 'document',
}
export interface UseFormControl {
  onChangeFormInput: OnChangeFormInput;
  formData: FormData;
  formErrors: FormErrors;
  setFormErrors?: SetFormErrors;
  setFormData?: SetFormData;
  isFormValid?: IsFormValid;
  runAllValidators?: RunAllValidators;
  setErrors?: SetErrors;
  resetForm?: () => void;
  onSubmit?: (e: FormEvent) => void;
}
