import React, { useEffect, useState } from 'react';
import { PrivacyPolicyUI } from './ui';
import { useDispatch } from 'react-redux';
import { setLoading } from 'src/redux/loading/loadingSlice';
import { api } from 'src/services/api';
import { PrivacyManager } from 'src/model/PrivacyManager';

export const PrivacyPolicyScreen: React.FC = (): JSX.Element => {
  const [privacyManager, setPrivacyManager] = useState<PrivacyManager>();
  const dispatch = useDispatch();

  const getPrivacyManager = async (): Promise<void> => {
    dispatch(setLoading(true));
    try {
      const response = await api.get<PrivacyManager>(
        '/privacy-policy/find-all'
      );
      if (response.status === 200) {
        setPrivacyManager(response.data);
      }
    } catch (error) {
      console.error('Erro ao buscar categoria:', error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    getPrivacyManager();
    // eslint-disable-next-line
  }, []);
  return <PrivacyPolicyUI privacyManager={privacyManager} />;
};
