import React, { useEffect, useState } from 'react';
import { Product } from 'src/model/Product';
import { useDispatch } from 'react-redux';
import { setLoading } from 'src/redux/loading/loadingSlice';
import { api } from 'src/services/api';
import { Banners } from 'src/model/Banners';
import { ProductsSearchUI } from '../../components/ProductsUI';
import { useHistory, useParams } from 'react-router-dom';

interface Params {
  id: string;
}
export const ProductsByCategoryScreen: React.FC = (): JSX.Element => {
  const { id } = useParams<Params>();
  const [products, setProdutos] = useState<Product[]>([]);
  const dispatch = useDispatch();
  const [banners, setBanners] = useState<Banners[]>([]);
  const [bannersMobile, setBannersMobile] = useState<Banners[]>([]);

  const history = useHistory();

  const goSlide = (id: string) => {
    history.push(`/products-by-category/${id}`);
  };

  const getBannersHome = async (): Promise<void> => {
    dispatch(setLoading(true));
    try {
      const response = await api.get<Banners[]>(
        '/banner/find-all/PRIMARY_HOME'
      );
      if (response.status === 200) {
        console.log(response.data);
        setBanners(response.data);
      }
    } catch (error) {
      console.error('Erro ao buscar banners:', error);
    } finally {
      dispatch(setLoading(false));
    }
  };
  const getBannersHomeMobile = async (): Promise<void> => {
    dispatch(setLoading(true));
    try {
      const response = await api.get<Banners[]>(
        '/banner/find-all/PRIMARY_HOME_MOBILE'
      );
      if (response.status === 200) {
        console.log(response.data);
        setBannersMobile(response.data);
      }
    } catch (error) {
      console.error('Erro ao buscar banners:', error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getProduct = async (): Promise<void> => {
    console.log(id);
    dispatch(setLoading(true));
    try {
      const response = await api.get<Product[]>(`/product/category/${id}`);
      console.log(response.data);
      if (response.status === 200) {
        setProdutos(response.data);
      }
    } catch (error) {
      console.error('Erro ao buscar produtos da categoria:', error);
    } finally {
      dispatch(setLoading(false));
    }
  };
  useEffect(() => {
    getProduct();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getBannersHome();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    getBannersHomeMobile();
    // eslint-disable-next-line
  }, []);
  return (
    <ProductsSearchUI
      banners={banners}
      bannersMobile={bannersMobile}
      product={products}
      goSlide={goSlide}
    />
  );
};
