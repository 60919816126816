import React from 'react';
import { CardVideo } from 'src/components/CardVideo';
import { AuthWrapper } from 'src/features/core/auth/screen/AuthWrapper';

const videoData = [
  {
    numberCard: 1,
    titleCard: 'Passo 1',
    subtitle: 'Apresentação da Plataforma',
    videoUrl: 'https://www.youtube.com/embed/3p1NLb1-rqk',
  },
  {
    numberCard: 2,
    titleCard: 'Passo 2',
    subtitle: 'Cadastro Buunpo',
    videoUrl: 'https://www.youtube.com/embed/a4-wWjprmQw',
  },
  {
    numberCard: 3,
    titleCard: 'Passo 3',
    subtitle: 'Como Anunciar',
    videoUrl: 'https://www.youtube.com/embed/HLEwz1uhwgI',
  },
];

export const TutorialUI: React.FC = (): JSX.Element => {
  return (
    <AuthWrapper>
      <div className="flex flex-wrap items-center justify-between w-[80%] m-4 bg-white px-6 py-10 rounded-lg shadow-sm">
        {videoData.map((video, index) => (
          <CardVideo
            key={index}
            numberCard={video.numberCard}
            titleCard={video.titleCard}
            subtitle={video.subtitle}
            videoUrl={video.videoUrl}
          />
        ))}
      </div>
    </AuthWrapper>
  );
};
