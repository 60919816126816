import { Brand } from 'src/model/Brand';
import { Category } from 'src/model/Category';
import { Dimensions } from 'src/model/Dimensions';
import { Grade } from 'src/model/Grade';
import { Price } from 'src/model/Price';
import { ProductStatus } from 'src/model/ProductStatus';

import { Warranty } from 'src/model/Warranty';

export enum FormRegisterProduct {
  name = 'name',
  description = 'description',
  stock = 'stock',
  price = 'price',
  priceDiscount = 'priceDiscount',
  sku = 'sku',
  status = 'status',
  promotion = 'promotion',
  brandName = 'brandName',
  dimensionsWidth = 'dimensionsWidth',
  dimensionsHeight = 'dimensionsHeight',
  dimensionsDepth = 'dimensionsDepth',
  dimensionsWeight = 'dimensionsWeight',
  discount = 'discount',
  parcel = 'parcel',
  warranty = 'warranty',
  tag = 'tag',
}

export interface ProductBasic {
  name: string;
  description: string;
  stock: string;
  sku: string;
  promotion: boolean;
  status: ProductStatus;
  category: Category[];
  brand: Brand;
  dimensions: Dimensions;
  imagens: string[];
  price: Price;
  tags: string[];
  warranty: Warranty;
  grade: Grade[];
}

export enum FormInputGrade {
  atributo = 'atributo',
  value = 'value',
  quantity = 'quantity',
}
