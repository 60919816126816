// components/HomeSecondaryBannerManagerUI.tsx
import React, { FormEvent } from 'react';
import { InputText } from 'src/components/InputText';
import { InputFile } from 'src/components/InputFile';
import { Button } from 'src/components/Button';
import Banner from 'src/components/Banner';
import { FormInputBannerSecondaryBanner } from 'src/features/admin/types';
import { Banners } from 'src/model/Banners';
import { NameFiles } from '..';
import { UseFormReturn } from 'src/hooks';
import { OptionProps, Select } from 'src/components/Select';

interface HomeSecondaryBannerManagerUIProps
  extends Pick<UseFormReturn, 'formData' | 'formErrors' | 'onChangeFormInput'> {
  formNameFilesSecondary?: NameFiles;
  onSubmitRegister: (e: FormEvent) => void;
  onChangeFormFileInput: (
    inputName: string
  ) => (file: File | undefined) => void;
  //   onEditBanner: (id: string) => void;
  onRemoveBanner: (id: string) => void;
  banners: Banners[];
  categorySecondary: OptionProps[];
}

export const HomeSecondaryBannerManagerUI: React.FC<
  HomeSecondaryBannerManagerUIProps
> = ({
  banners,
  formData,
  formNameFilesSecondary,
  formErrors,
  categorySecondary,
  onSubmitRegister,
  onChangeFormInput,
  onChangeFormFileInput,
  //   onEditBanner,
  onRemoveBanner,
}) => {
  const imageSecondaryBanner64 = React.useRef<HTMLInputElement>(null);

  return (
    <div className="mb-4 space-y-2 grid grid-cols-1 ">
      <form onSubmit={onSubmitRegister} className="space-y-4">
        <InputText
          name="altTextSecondaryBanner"
          label="Texto Alternativo"
          placeholder="Digite o texto alternativo..."
          value={
            formData[FormInputBannerSecondaryBanner.altTextSecondaryBanner]
          }
          onChange={(e) =>
            onChangeFormInput(
              FormInputBannerSecondaryBanner.altTextSecondaryBanner
            )(e.target.value)
          }
          error={
            formErrors.altTextSecondaryBanner &&
            formErrors.altTextSecondaryBanner[0]
          }
          inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
        />

        <InputFile
          refInput={imageSecondaryBanner64}
          name="imageBannerSecondary"
          label="Upload da Imagem Utilize uma imagem de tamanho 450px Larg x 180px Alt"
          fileName={formNameFilesSecondary?.imageSecondaryBanner}
          onChange={(e) =>
            onChangeFormFileInput(
              FormInputBannerSecondaryBanner.imageSecondaryBanner
            )(e.target.files?.[0])
          }
          error={
            formErrors.imageSecondaryBanner &&
            formErrors.imageSecondaryBanner[0]
          }
        />
        <Select
          name="categorySecondaryBanner"
          label="Selecione categoria"
          value={
            formData[FormInputBannerSecondaryBanner.categorySecondaryBanner]
          }
          onChange={(e) =>
            onChangeFormInput(
              FormInputBannerSecondaryBanner.categorySecondaryBanner
            )(e.target.value)
          }
          error={
            formErrors.categorySecondaryBanner &&
            formErrors.categorySecondaryBanner[0]
          }
          options={categorySecondary}
          className=""
          selectClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
          optionClassName=""
        />

        <Button
          type="submit"
          title="Salvar Banner"
          buttonStyle="red"
          size="lg"
        />
      </form>
      <div className="overflow-x-auto pb-4">
        <div className="grid grid-rows-1 gap-4 auto-cols-[minmax(200px,_1fr)] grid-flow-col">
          {banners &&
            banners.map((banner) => (
              <div
                key={banner.id}
                className="relative border p-4 rounded-lg shadow-md"
              >
                <img
                  src={banner.image}
                  alt={banner.altText}
                  className="w-full h-32 object-cover rounded mb-2"
                />
                <p className="text-center text-sm">{banner.altText}</p>
                <div className="flex justify-between mt-2">
                  {/* <button
                onClick={() => onEditBanner(banner.id)}
                className="text-yellow-500"
              >
                Editar
              </button> */}
                  <button
                    onClick={() => onRemoveBanner(banner.id)}
                    className="text-red-500"
                  >
                    Excluir
                  </button>
                </div>
              </div>
            ))}
        </div>
      </div>
      {/* Componente de preview */}
      <div className="w-full">
        {banners.length > 0 ? (
          <Banner images={banners} interval={3000} />
        ) : (
          <p>Nenhuma pré-visualização disponível</p>
        )}
      </div>
    </div>
  );
};
