// src/components/Review.tsx
import dayjs from 'dayjs';
import React from 'react';
import { ProductReview } from 'src/model/ProductReview';

interface RatingStarsProps {
  rating: ProductReview;
}

const RatingStars: React.FC<RatingStarsProps> = ({ rating }) => {
  const maxStars = 5;
  const fullStars = Math.floor(rating.score);
  const hasHalfStar = rating.score % 1 !== 0;

  return (
    <div className="relative flex items-center">
      {/* Estrelas Vazias */}
      {[...Array(maxStars)].map((_, index) => (
        <svg
          key={index}
          className="w-4 h-4 text-gray-300 fill-current"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <path d="M9.049 2.927a.998.998 0 011.902 0l1.92 4.46 4.908.07a1 1 0 01.554 1.784l-3.68 3.22 1.12 4.831a1 1 0 01-1.512 1.05L10 15.15l-4.36 2.194a1 1 0 01-1.512-1.05l1.12-4.831-3.68-3.22a1 1 0 01.554-1.784l4.908-.07 1.92-4.46z" />
        </svg>
      ))}

      {/* Estrelas Preenchidas */}
      <div className="absolute top-0 left-0 flex">
        {[...Array(fullStars)].map((_, index) => (
          <svg
            key={index}
            className="w-4 h-4 text-yellow-400 fill-current"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path d="M9.049 2.927a.998.998 0 011.902 0l1.92 4.46 4.908.07a1 1 0 01.554 1.784l-3.68 3.22 1.12 4.831a1 1 0 01-1.512 1.05L10 15.15l-4.36 2.194a1 1 0 01-1.512-1.05l1.12-4.831-3.68-3.22a1 1 0 01.554-1.784l4.908-.07 1.92-4.46z" />
          </svg>
        ))}
        {hasHalfStar && (
          <svg
            className="w-4 h-4 text-yellow-400 fill-current"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <defs>
              <linearGradient id="half">
                <stop offset="50%" stopColor="#FBBF24" />
                <stop offset="50%" stopColor="transparent" />
              </linearGradient>
            </defs>
            <path
              d="M9.049 2.927a.998.998 0 011.902 0l1.92 4.46 4.908.07a1 1 0 01.554 1.784l-3.68 3.22 1.12 4.831a1 1 0 01-1.512 1.05L10 15.15l-4.36 2.194a1 1 0 01-1.512-1.05l1.12-4.831-3.68-3.22a1 1 0 01.554-1.784l4.908-.07 1.92-4.46z"
              fill="url(#half)"
            />
          </svg>
        )}
      </div>
    </div>
  );
};

// Componente Review para cada avaliação individual
const Review: React.FC<RatingStarsProps> = ({ rating }) => {
  return (
    <div className="bg-white p-4 rounded-lg shadow-md mb-4 w-full ">
      <div className="flex justify-between items-center mb-2">
        <div className="font-semibold text-gray-800">{rating.user.name}</div>
        <span className="text-gray-400 text-sm">
          {dayjs(rating.dateAt).locale('pt-br').format('DD MMM')}
        </span>
      </div>
      <div className="flex items-center mb-2">
        <RatingStars rating={rating} />
        <span className="text-sm text-gray-600 ml-2">
          {rating.score.toFixed(1)}
        </span>
      </div>
      <p className="text-gray-700 text-sm">{rating.description}</p>
    </div>
  );
};

// Lista de Reviews para exibir múltiplas avaliações
interface ReviewListProps {
  reviews: ProductReview[];
}

const ReviewList: React.FC<ReviewListProps> = ({ reviews }) => {
  return (
    <div className="w-full flex flex-col items-center mt-2 space-y-4">
      {reviews.map((review, index) => (
        <Review key={index} rating={review} />
      ))}
    </div>
  );
};

// Exportando os componentes
export { Review, ReviewList };
