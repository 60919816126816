import React, { useState } from 'react';
import Client from 'src/model/User';

interface MenuItem {
  title: string;
  content: JSX.Element;
}

interface ProfileSideMenuProps {
  user?: Client;
  menuItems: MenuItem[];
  subTitle?: string;
}

export const ProfileSideMenu: React.FC<ProfileSideMenuProps> = ({
  user,
  menuItems,
  subTitle,
}) => {
  const [activeItem, setActiveItem] = useState<MenuItem>(menuItems[0]);

  return (
    <div className="flex flex-col md:flex-row ">
      {/* Sidebar */}
      <aside className="md:w-44 border-r border-red-200 p-4 min-h-max">
        <h2 className="text-lg font-semibold mb-6">
          {user ? `Olá ${user.name.split(' ')[0]}` : subTitle}
        </h2>
        <ul className="space-x-4 md:space-x-0 md:space-y-4 flex md:block overflow-x-auto">
          {menuItems.map((item) => (
            <li
              key={item.title}
              className={`border-l-0 md:border-l-4 border-b-4 md:border-b-0 pl-2 md:pl-1 pb-2 md:pb-0 text-gray-700 cursor-pointer whitespace-nowrap text-wrap ${
                activeItem.title === item.title
                  ? 'border-red-500'
                  : 'border-transparent hover:border-red-600'
              }`}
              onClick={() => setActiveItem(item)}
            >
              {item.title}
            </li>
          ))}
        </ul>
      </aside>
      {/* Main Content */}
      <div className="w-full flex-1 px-6 py-3">
        <h1 className="text-2xl font-bold">{activeItem.title}</h1>
        <div className="flex w-full overflow-x-auto">{activeItem.content}</div>
        {/* Renderizando o componente relacionado ao item ativo */}
      </div>
    </div>
  );
};
