import React, { FormEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setLoading } from 'src/redux/loading/loadingSlice';
import useForm from 'src/hooks/useForm';
import validators from 'src/helpers/validators';
import { AdvertisingPolicyManagerUI } from './ui';
import {
  FormContentTextarea,
  FormContentTextareaAdvertising,
} from 'src/features/admin/types';
import { api } from 'src/services/api';
import { toast } from 'react-toastify';
import { AdvertisingPolicies } from 'src/model/AdvertisingPolicies';

export const AdvertisingPolicyManagerScreen: React.FC = () => {
  const dispatch = useDispatch();
  const [id, setId] = useState<string>();

  const {
    formData: formDataAdvertising,
    formErrors: formErrorsAdvertising,
    setErrors: setErrorsPrivacy,
    onChangeFormInput: onChangeFormInputAdvertising,
    isFormValid: isFormValidPrivacy,
    resetForm: resetFormPrivacy,
  } = useForm({
    initialData: {
      [FormContentTextarea.HTML]: '',
    },
    validators: {
      [FormContentTextarea.HTML]: [validators.required],
    },
  });

  const getAdvertisingPolicies = async (): Promise<void> => {
    dispatch(setLoading(true));
    try {
      const response = await api.get<AdvertisingPolicies>(
        '/advertising-policies/find-all'
      );
      if (response.status === 200) {
        const advertisingPolicies: AdvertisingPolicies = response.data;
        onChangeFormInputAdvertising(FormContentTextarea.HTML)(
          advertisingPolicies.description
        );
        setId(advertisingPolicies.id);
      }
    } catch (error) {
      console.error('Erro ao buscar categoria:', error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleRegisterAdvertising = async (e: FormEvent): Promise<void> => {
    e.preventDefault();
    try {
      if (isFormValidPrivacy() && id) {
        dispatch(setLoading(true));
        const payload = {
          id: id,
          description: formDataAdvertising[FormContentTextareaAdvertising.HTML],
        };
        const response = await api.post(
          '/advertising-policies/update',
          payload
        );
        if (response.status === 200 || response.status === 201) {
          toast.success('Política de privacidade atualizada com sucesso!');
          getAdvertisingPolicies();
          resetFormPrivacy();
        } else {
          toast.error('Erro ao atualizar a política de privacidade.');
        }
      } else {
        toast.error('Verifique o conteúdo da política de privacidade.');
      }
    } catch (error) {
      console.error('Erro ao registrar a política de privacidade:', error);
      toast.error('Falha ao atualizar a política, tente novamente mais tarde.');
      setErrorsPrivacy({
        [FormContentTextarea.HTML]: ['Erro ao salvar os dados'],
      });
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    getAdvertisingPolicies();
    // eslint-disable-next-line
  }, []);

  return (
    <AdvertisingPolicyManagerUI
      formData={formDataAdvertising}
      formErrors={formErrorsAdvertising}
      onChangeFormInput={onChangeFormInputAdvertising}
      onSubmitRegister={handleRegisterAdvertising}
    />
  );
};
