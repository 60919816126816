import DOMPurify from 'dompurify';
import React from 'react';
import { AuthWrapper } from 'src/features/core/auth/screen/AuthWrapper';
import { PrivacyManager } from 'src/model/PrivacyManager';

interface PrivacyPolicyUIProps {
  privacyManager?: PrivacyManager;
}

export const PrivacyPolicyUI: React.FC<PrivacyPolicyUIProps> = ({
  privacyManager,
}): JSX.Element => {
  //   const privacyPolicyContent = {
  //     title: 'Política de Privacidade da Buunpo',
  //     lastUpdated: '08 de outubro de 2024',
  //     text: `
  //       <h1 className="text-2xl font-bold mb-4">
  //             Política de Privacidade da Buunpo
  //           </h1>
  //           <p className="mb-4">Última atualização: 08 de outubro de 2024</p>
  //           <p className="mb-4">
  //             A Buunpo valoriza a sua privacidade e está comprometida em proteger
  //             as suas informações pessoais. Esta Política de Privacidade descreve
  //             como coletamos, utilizamos, armazenamos e compartilhamos os seus
  //             dados quando você utiliza nosso site e aplicativo móvel. Ao utilizar
  //             nossos serviços, você concorda com as práticas descritas nesta
  //             política.
  //           </p>

  //           <h2 className="text-xl font-semibold mt-6 mb-2">
  //             1. Informações que Coletamos
  //           </h2>
  //           <p className="mb-4">
  //             Coletamos apenas as informações necessárias para identificar nossos
  //             usuários, incluindo:
  //           </p>
  //           <ul className="list-disc pl-6 mb-4">
  //             <li>
  //               <strong>Dados Pessoais:</strong> Nome, endereço de e-mail, número
  //               de telefone, endereço de entrega e informações de pagamento.
  //             </li>
  //             <li>
  //               <strong>Dados de Uso:</strong> Informações sobre como você utiliza
  //               nosso site e aplicativo, como histórico de compras, preferências e
  //               interações.
  //             </li>
  //             <li>
  //               <strong>Dados Técnicos:</strong> Informações sobre o dispositivo
  //               que você utiliza para acessar nossos serviços, como tipo de
  //               dispositivo, sistema operacional e endereço IP.
  //             </li>
  //           </ul>

  //           <h2 className="text-xl font-semibold mt-6 mb-2">
  //             2. Finalidade da Coleta de Dados
  //           </h2>
  //           <p className="mb-4">
  //             Os dados coletados são utilizados exclusivamente para:
  //           </p>
  //           <ul className="list-disc pl-6 mb-4">
  //             <li>Identificar e autenticar usuários.</li>
  //             <li>Processar e gerenciar suas compras e transações.</li>
  //             <li>Melhorar a experiência de uso do site e aplicativo.</li>
  //             <li>
  //               Enviar comunicações relacionadas aos nossos serviços, como
  //               confirmações de pedidos e atualizações.
  //             </li>
  //           </ul>

  //           <h2 className="text-xl font-semibold mt-6 mb-2">
  //             3. Aplicação da Política
  //           </h2>
  //           <p className="mb-4">
  //             Esta Política de Privacidade é aplicável tanto ao site da Buunpo
  //             quanto ao seu aplicativo móvel disponível nas lojas Google Play e
  //             Apple App Store.
  //           </p>

  //           <h2 className="text-xl font-semibold mt-6 mb-2">
  //             4. Compartilhamento de Dados
  //           </h2>
  //           <p className="mb-4">
  //             Terceiros: Não repassamos seus dados pessoais a terceiros, exceto
  //             nas seguintes situações:
  //           </p>
  //           <ul className="list-disc pl-6 mb-4">
  //             <li>
  //               <strong>Autoridades Legais:</strong> Podemos compartilhar suas
  //               informações com autoridades competentes quando exigido por lei ou
  //               por determinação judicial.
  //             </li>
  //             <li>
  //               <strong>Serviços de Terceiros:</strong> Utilizamos serviços de
  //               hospedagem e processamento de dados que podem estar localizados em
  //               outros países. Nesses casos, garantimos que tais terceiros cumpram
  //               com as normas de proteção de dados aplicáveis.
  //             </li>
  //           </ul>

  //           <h2 className="text-xl font-semibold mt-6 mb-2">
  //             5. Transferência Internacional de Dados
  //           </h2>
  //           <p className="mb-4">
  //             Seus dados podem ser transferidos e processados em servidores
  //             localizados fora do Brasil, dependendo da localização dos nossos
  //             prestadores de serviços de hospedagem. Em outros casos, seus dados
  //             permanecerão armazenados no Brasil, onde a sede da Buunpo está
  //             estabelecida.
  //           </p>

  //           <h2 className="text-xl font-semibold mt-6 mb-2">
  //             6. Segurança dos Dados
  //           </h2>
  //           <p className="mb-4">
  //             Adotamos medidas de segurança técnicas e administrativas para
  //             proteger suas informações contra acessos não autorizados, perdas,
  //             alterações ou divulgações. No entanto, nenhuma transmissão de dados
  //             pela internet ou método de armazenamento eletrônico é 100% seguro, e
  //             não podemos garantir a segurança absoluta dos seus dados.
  //           </p>

  //           <h2 className="text-xl font-semibold mt-6 mb-2">
  //             7. Direitos dos Usuários
  //           </h2>
  //           <p className="mb-4">
  //             Conforme a Lei Geral de Proteção de Dados (LGPD), você tem os
  //             seguintes direitos:
  //           </p>
  //           <ul className="list-disc pl-6 mb-4">
  //             <li>
  //               <strong>Acesso:</strong> Solicitar informações sobre os dados que
  //               possuímos sobre você.
  //             </li>
  //             <li>
  //               <strong>Correção:</strong> Atualizar ou corrigir dados
  //               incompletos, inexatos ou desatualizados.
  //             </li>
  //             <li>
  //               <strong>Eliminação:</strong> Solicitar a exclusão de seus dados
  //               pessoais, salvo se houver obrigação legal de mantê-los.
  //             </li>
  //             <li>
  //               <strong>Oposição:</strong> Se opor ao processamento de seus dados
  //               para determinadas finalidades.
  //             </li>
  //             <li>
  //               <strong>Portabilidade:</strong> Solicitar a transferência de seus
  //               dados para outro serviço ou empresa.
  //             </li>
  //           </ul>
  //           <p>
  //             Para exercer seus direitos, entre em contato conosco através dos
  //             canais fornecidos na seção de Contato.
  //           </p>

  //           <h2 className="text-xl font-semibold mt-6 mb-2">
  //             8. Conformidade com a Defesa do Consumidor
  //           </h2>
  //           <p className="mb-4">
  //             A Buunpo está em conformidade com as normas de defesa do consumidor
  //             vigentes no Brasil, garantindo transparência, segurança e respeito
  //             aos direitos dos nossos usuários em todas as nossas operações.
  //           </p>

  //           <h2 className="text-xl font-semibold mt-6 mb-2">
  //             9. Alterações na Política de Privacidade
  //           </h2>
  //           <p className="mb-4">
  //             Podemos atualizar esta Política de Privacidade periodicamente para
  //             refletir mudanças em nossas práticas ou na legislação aplicável.
  //             Notificaremos você sobre quaisquer alterações significativas através
  //             do nosso site e aplicativo.
  //           </p>

  //           <h2 className="text-xl font-semibold mt-6 mb-2">10. Contato</h2>
  //           <p className="mb-4">
  //             Se você tiver dúvidas, preocupações ou solicitações relacionadas a
  //             esta Política de Privacidade, entre em contato conosco através do
  //             e-mail:
  //             <a
  //               href="mailto:privacidade@buunpo.com.br"
  //               className="text-blue-600 underline"
  //             >
  //               privacidade@buunpo.com.br
  //             </a>
  //             ou pelo endereço físico:
  //           </p>
  //           <p className="mb-4">
  //             Buunpo Endereço: [Endereço Completo da Empresa] Telefone: [Número de
  //             Contato]
  //           </p>

  //           <h2 className="text-xl font-semibold mt-6 mb-2">
  //             11. Aceitação dos Termos
  //           </h2>
  //           <p className="mb-4">
  //             Ao utilizar nossos serviços, você concorda com os termos desta
  //             Política de Privacidade. Se não concordar com algum dos termos aqui
  //             descritos, por favor, não utilize nossos serviços.
  //           </p>

  //           <p className="mt-6 font-semibold">
  //             Agradecemos por confiar na Buunpo!
  //           </p>
  //     `,
  //   };

  return (
    <AuthWrapper>
      <div className="w-[80%] bg-white mt-4 rounded-md border border-red-200">
        <div className="bg-white p-4 rounded-lg shadow-sm">
          <h1 className="text-2xl font-bold">
            Política de Privacidade da Buunpo
          </h1>
          {/* <p className="mb-4">Última atualização: {content.lastUpdated}</p> */}

          <div
            className="privacy-policy-content"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(
                privacyManager ? privacyManager.description : 'carregando'
              ), // Renderiza o HTML sanitizado
            }}
          />
        </div>
      </div>
    </AuthWrapper>
  );
};
