import React from 'react';
import { AuthWrapper } from 'src/features/core/auth/screen/AuthWrapper';

// interface ReclamationUIProps {}

export const ReclamationUI: React.FC = (): JSX.Element => {
  return (
    <AuthWrapper>
      <div className="flex flex-wrap items-center justify-between w-full container m-4 bg-white p-4 rounded-lg shadow-sm"></div>
    </AuthWrapper>
  );
};
