import React from 'react';

interface InputFileProps {
  name: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  fileName?: string | string[]; // Alterado para aceitar uma lista de nomes de arquivos
  style?: React.CSSProperties;
  placeholder?: string;
  ref?: React.Ref<HTMLInputElement>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any;
  label?: string | React.ReactNode;
  wrapperClass?: string;
  labelClassName?: string;
  accept?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  refInput?: React.Ref<HTMLInputElement>;
  multiple?: boolean; // Novo atributo para indicar múltiplos arquivos
  disabled?: boolean;
}

export const InputFile: React.FC<InputFileProps> = ({
  fileName,
  name,
  error,
  label,
  wrapperClass,
  labelClassName,
  accept,
  onChange,
  refInput,
  disabled = false,
  multiple = false, // Novo valor padrão para múltiplos arquivos
}: InputFileProps) => {
  // Formatação dos nomes dos arquivos para exibição
  const formattedFileNames =
    typeof fileName === 'string'
      ? fileName
      : (fileName?.join(', ') ?? 'Nenhum arquivo selecionado');

  return (
    <div
      className={`flex-column input-component space-y-1.5 ${wrapperClass} ${
        error ? 'input-error' : ''
      }`}
    >
      {label && (
        <label
          htmlFor={name}
          className={` text-gray-500 text-base font-dmsans font-regular input-label ${labelClassName}`}
        >
          {label}
        </label>
      )}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '8px 10px',
          background: '#f6f6f6',
          borderRadius: '5px',
          height: '62px',
          border: error ? '1px solid #D8413A' : 'none',
        }}
      >
        <label
          htmlFor={name}
          className="button-label-file"
          style={{
            fontSize: '12px',
            padding: '15px',
            width: '145px',
            backgroundColor: '#b4b4b4',
            color: 'rgba(34, 34, 34, 1)',
            textAlign: 'center',
            margin: 'auto 0',
            borderRadius: '5px',
            display: 'block',
            cursor: 'pointer',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '1',
          }}
        >
          Escolher arquivo{multiple ? 's' : ''}
        </label>
        <input
          type="file"
          accept={accept}
          name={name}
          id={name}
          onChange={onChange}
          style={{ display: 'none' }}
          className={`${error ? 'input-error' : ''}`}
          ref={refInput}
          multiple={multiple} // Define a propriedade `multiple`
          disabled={disabled}
        />
        <p
          style={{
            margin: '0 20px',
            color: fileName ? '#222222' : '#A5A5A5',
            fontSize: '16px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {formattedFileNames}
        </p>
      </div>
      {error && (
        <span role="alert" className="alert-error">
          {error}
        </span>
      )}
    </div>
  );
};
