import React from 'react';

interface MultiSelectProps {
  label: string;
  error?: string | null | undefined;
  options: { value: string; label: string }[];
  selectedValues: string[];
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  onRemove: (value: string) => void;
  placeholder?: string;
  containerClassName?: string;
  labelClassName?: string;
  selectClassName?: string;
}

export const MultiSelect: React.FC<MultiSelectProps> = ({
  label,
  options,
  error,
  selectedValues,
  onChange,
  onRemove,
  placeholder = 'Selecione...',
  containerClassName,
  labelClassName,
  selectClassName,
}) => {
  return (
    <div className={`relative space-y-1.5 ${containerClassName}`}>
      <label
        className={`block  text-gray-500 text-base font-dmsans font-regular ${labelClassName}`}
      >
        {label}
      </label>
      <select
        onChange={onChange}
        className={`block appearance-none w-full border rounded-lg text-input text-base leading-tight focus:outline-none ${
          error ? 'border-red-500' : 'border-none'
        } ${selectClassName}`}
      >
        <option value="">{placeholder}</option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <ul className="flex flex-wrap gap-2 mt-2">
        {selectedValues.map((value, index) => (
          <li
            key={index}
            className="bg-gray-200 rounded-full px-4 py-1 flex items-center"
          >
            {options.find((opt) => opt.value === value)?.label}
            <button
              type="button"
              onClick={() => onRemove(value)}
              className="ml-2 text-red-500 font-bold"
            >
              X
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};
