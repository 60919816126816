import React, { useEffect, useState } from 'react';
import { UserProfileUI } from './UserProfile/ui';
import { getBoolean } from 'src/helpers/common/localStorage';
import { REACT_APP_AUTH } from 'src/utils/config';
import Client from 'src/model/User';
import { useDispatch } from 'react-redux';
import { setLoading } from 'src/redux/loading/loadingSlice';
import { api } from 'src/services/api';

export const UserProfileScreen: React.FC = (): JSX.Element => {
  const [signed, setSigned] = useState<boolean>(
    getBoolean(String(REACT_APP_AUTH))
  );
  const [user, setUser] = useState<Client>();
  const dispatch = useDispatch();

  const isAuth = async (): Promise<void> => {
    setTimeout(async () => {
      if (getBoolean(String(REACT_APP_AUTH)) !== signed) {
        setSigned(getBoolean(String(REACT_APP_AUTH)));
        return;
      } else {
        isAuth();
      }
    }, 500);
  };

  const getUser = async (): Promise<void> => {
    if (signed) {
      dispatch(setLoading(true));
      try {
        const response = await api.get<Client>(`/user/findeone`);
        console.log(response);
        if (response.status === 200) {
          setUser(response.data);
        }
      } catch (error) {
        console.error('Erro ao buscar o usuario:', error);
      } finally {
        dispatch(setLoading(false));
      }
    } else {
      isAuth();
    }
  };

  useEffect(() => {
    isAuth();
    // eslint-disable-next-line
  }, [signed]);

  useEffect(() => {
    getUser();
    // eslint-disable-next-line
  }, []);

  return <>{user ? <UserProfileUI user={user} /> : 'carregando'}</>;
};
