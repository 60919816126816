import React from 'react';

interface RatingStars2Props {
  rating: number; // Valor de classificação
  maxStars?: number; // Número máximo de estrelas (padrão: 5)
  width?: string; // Largura das estrelas
  height?: string; // Altura das estrelas
}

export const RatingStars2: React.FC<RatingStars2Props> = ({
  rating,
  maxStars = 5,
  width = '24px',
  height = '24px',
}) => {
  // Limita o rating ao máximo de estrelas
  const effectiveRating = Math.min(rating, maxStars);
  const fullStars = Math.floor(effectiveRating);
  const hasHalfStar = effectiveRating % 1 !== 0;

  return (
    <div className="relative flex items-center">
      {/* Renderização das Estrelas Vazias */}
      {[...Array(maxStars)].map((_, index) => (
        <svg
          key={index}
          className="text-gray-300 fill-current"
          style={{ width, height }}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <path d="M9.049 2.927a.998.998 0 011.902 0l1.92 4.46 4.908.07a1 1 0 01.554 1.784l-3.68 3.22 1.12 4.831a1 1 0 01-1.512 1.05L10 15.15l-4.36 2.194a1 1 0 01-1.512-1.05l1.12-4.831-3.68-3.22a1 1 0 01.554-1.784l4.908-.07 1.92-4.46z" />
        </svg>
      ))}

      {/* Renderização das Estrelas Preenchidas */}
      <div className="absolute top-0 left-0 flex">
        {[...Array(fullStars)].map((_, index) => (
          <svg
            key={index}
            className="text-yellow-400 fill-current"
            style={{ width, height }}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path d="M9.049 2.927a.998.998 0 011.902 0l1.92 4.46 4.908.07a1 1 0 01.554 1.784l-3.68 3.22 1.12 4.831a1 1 0 01-1.512 1.05L10 15.15l-4.36 2.194a1 1 0 01-1.512-1.05l1.12-4.831-3.68-3.22a1 1 0 01.554-1.784l4.908-.07 1.92-4.46z" />
          </svg>
        ))}
        {hasHalfStar && (
          <svg
            className="text-yellow-400 fill-current"
            style={{ width, height }}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <defs>
              <linearGradient id="half">
                <stop offset="50%" stopColor="#FBBF24" />
                <stop offset="50%" stopColor="transparent" />
              </linearGradient>
            </defs>
            <path
              d="M9.049 2.927a.998.998 0 011.902 0l1.92 4.46 4.908.07a1 1 0 01.554 1.784l-3.68 3.22 1.12 4.831a1 1 0 01-1.512 1.05L10 15.15l-4.36 2.194a1 1 0 01-1.512-1.05l1.12-4.831-3.68-3.22a1 1 0 01.554-1.784l4.908-.07 1.92-4.46z"
              fill="url(#half)"
            />
          </svg>
        )}
      </div>
    </div>
  );
};
