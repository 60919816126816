import React from 'react';
import { AuthWrapper } from '../../../../../features/core/auth/screen/AuthWrapper';
import { BillingAddressScreen } from '../components/BillingAddress/';
import { DeliveryMethodsScreen } from '../components/DeliveryMethods/';
import { OrderSummary } from '../components/OrderSummary';
import { UseFormReturn } from 'src/hooks';
import { Cart } from 'src/model/Cart';
import { DeliveryOptions } from 'src/model/DeliveryOptions';
import Client from 'src/model/User';
import { Summary } from '..';
import { Address } from '../../../../../model/Address';
import { PaymentFormUI } from '../components/PaymentForm';
import { ProductListUI } from '../components/ProductList/ui';

interface CheckoutUIProps
  extends Pick<UseFormReturn, 'formData' | 'formErrors' | 'onChangeFormInput'> {
  addresses: Address[] | undefined;
  selectedAddress?: string;
  carts: Cart[];
  user: Client;
  selectedCard?: number;
  addNewCard: boolean;
  deliveryOptions?: DeliveryOptions[];
  summary: Summary;
  onAddCard: () => void;
  onSelectCard: (index: number) => void;
  onSubmitRegister: (e: React.FormEvent) => void;
  onSelectAddress: (id: string) => void;
}

export const CheckoutUI: React.FC<CheckoutUIProps> = ({
  formData,
  formErrors,
  carts,
  user,
  addresses,
  selectedAddress,
  selectedCard,
  addNewCard,
  deliveryOptions,
  summary,
  onAddCard,
  onSelectCard,
  onSubmitRegister,
  onSelectAddress,
  onChangeFormInput,
}) => {
  const handleApplyCoupon = (coupon: string) => {
    console.log('Coupon Applied:', coupon);
  };
  const handleContinueToPayment = () => {
    console.log('Proceeding to payment...');
  };

  const handleReturnToShopping = () => {
    console.log('Returning to shopping...');
  };

  //   const handleCheckout = (cardDetails: {
  //     cardHolderName: string;
  //     cardNumber: string;
  //     expiryDate: string;
  //     cvv: string;
  //   }) => {
  //     console.log('Payment details:', cardDetails);
  //   };

  return (
    <AuthWrapper>
      <div className="container p-0 m-4  w-full">
        <h2 className="text-xl sm:text-2xl font-semibold mb-4">
          Dados Para Finalizar Compra
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 grid-rows-1 gap-0 sm:gap-4">
          {/* Seção de Endereço de Faturamento e Lista de Produtos */}
          <div className="w-full space-y-4">
            {/* <DeliveryAddressScreen /> */}

            <BillingAddressScreen
              addresses={addresses}
              onSelectAddress={onSelectAddress}
              selectedAddress={selectedAddress}
            />
            {/* Seção de Lista de Produtos e Métodos de Entrega */}
            {carts && carts.length > 0 && (
              <ProductListUI cartsProducts={carts} />
            )}
          </div>

          <div className="w-full  space-y-4">
            {deliveryOptions && deliveryOptions.length > 0 && (
              <DeliveryMethodsScreen
                methods={deliveryOptions}
                onApplyCoupon={handleApplyCoupon}
              />
            )}
            {user && (
              <PaymentFormUI
                addNewCard={addNewCard}
                formData={formData}
                formErrors={formErrors}
                onAddCard={onAddCard}
                onChangeFormInput={onChangeFormInput}
                card={user.cards}
                selectedCard={selectedCard}
                onSelectCard={onSelectCard}
                onSubmitRegister={onSubmitRegister}
              />
            )}
          </div>
        </div>
        {/* aqui */}
        <div className="md:w-full  mt-4 ">
          <OrderSummary
            summary={summary}
            onContinueToPayment={handleContinueToPayment}
            onReturnToShopping={handleReturnToShopping}
          />
        </div>
      </div>
    </AuthWrapper>
  );
};
