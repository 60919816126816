import React, { FormEvent } from 'react';
import { Link } from 'react-router-dom';
import { IconEye, IconEyeClose } from 'src/assets/icons';
import { Button } from 'src/components/Button';
import { InputText } from 'src/components/InputText';
import { FormInputNameLogin } from 'src/features/login/types';
import { UseFormReturn } from 'src/hooks';

export interface LoginUIProps
  extends Pick<UseFormReturn, 'formData' | 'formErrors' | 'onChangeFormInput'> {
  state: boolean;
  shouldShowPasswordToText: boolean;
  onTogglePasswordToText: () => void;
  onSubmit: (e: FormEvent) => void;
}

export const LoginUI: React.FC<LoginUIProps> = ({
  state,
  formData,
  formErrors,
  onChangeFormInput,
  shouldShowPasswordToText,
  onTogglePasswordToText,
  onSubmit,
}): JSX.Element => {
  return (
    <div className="flex items-center justify-center h-screen bg-red-500">
      <div className="bg-white p-8 rounded-md shadow-md w-[360px]">
        <h2 className="text-xl font-semibold mb-6 text-gray-700">
          Fazer Login
        </h2>

        <form onSubmit={onSubmit}>
          {/* Campo de Nome de Usuário/Email */}
          <div className="mb-4">
            <InputText
              label="Login"
              type="text"
              placeholder="Digite o Email"
              value={formData[FormInputNameLogin.email]}
              onChange={(e) =>
                onChangeFormInput(FormInputNameLogin.email)(e.target.value)
              }
              error={formErrors.email && formErrors.email[0]}
              className=""
              inputClassName=" rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
            />
          </div>
          <div className="mb-4 relative">
            <div className="relative">
              <InputText
                name="password"
                label="Senha"
                type={shouldShowPasswordToText ? 'text' : 'password'}
                placeholder="**********"
                value={formData[FormInputNameLogin.password]}
                onChange={(e) =>
                  onChangeFormInput(FormInputNameLogin.password)(e.target.value)
                }
                error={formErrors.password && formErrors.password[0]}
                maxLength={15}
                className=""
                inputClassName=" rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
              />
              <button
                className="absolute right-4 top-[55%] transform cursor-pointer"
                onClick={onTogglePasswordToText}
                type="button"
              >
                {shouldShowPasswordToText ? (
                  <img src={IconEye} alt="Mostrar senha" className="h-5 w-5" />
                ) : (
                  <img
                    src={IconEyeClose}
                    alt="Ocultar senha"
                    className="h-5 w-5"
                  />
                )}
              </button>
            </div>
          </div>

          {/* Botão Entrar */}
          <div className="w-full flex items-center justify-center">
            <Button
              type="submit"
              title="Entrar"
              buttonStyle="red"
              size="lg"
              className="w-[80%]"
              disabled={
                formData[FormInputNameLogin.email] === '' ||
                formData[FormInputNameLogin.password] === '' ||
                state
              }
            />
          </div>
        </form>

        {/* Links Esqueci Senha e Fazer Login com SMS */}
        <div className="flex justify-between text-sm mt-4">
          <Link to={'/'} className="text-blue-500 hover:underline">
            Esqueci minha senha
          </Link>

          {/* <Link to={'/'} className="text-blue-500 hover:underline">
            Fazer login com SMS
          </Link> */}
        </div>

        {/* Separador */}
        {/* <div className="flex items-center my-4">
          <div className="border-t w-full border-gray-300"></div>
          <span className="px-2 text-gray-500 text-sm">OU</span>
          <div className="border-t w-full border-gray-300"></div>
        </div> */}

        {/* Botões de Login Social */}
        {/* <div className="flex justify-around">
          <button className="bg-white border border-gray-300 rounded-full p-2">
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/0/05/Facebook_Logo_%282019%29.png"
              alt="Facebook"
              className="w-6 h-6"
            />
          </button>
          <button className="bg-white border border-gray-300 rounded-full p-2">
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
              alt="Google"
              className="w-6 h-6"
            />
          </button>
          <button className="bg-white border border-gray-300 rounded-full p-2">
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/f/fa/Apple_logo_black.svg"
              alt="Apple"
              className="w-6 h-6"
            />
          </button>
        </div> */}

        {/* Link para Cadastrar */}
        <div className="flex justify-center text-center mt-6 text-sm text-gray-600 space-x-1">
          <p>Novo na plataforma?</p>

          <Link
            to={'/create-account'}
            className="text-red-500 font-semibold hover:underline "
          >
            Cadastrar
          </Link>
        </div>
      </div>
    </div>
  );
};
