import React, { FormEvent } from 'react';
import { Button } from 'src/components/Button';
import { InputText } from 'src/components/InputText';
import { FormInputGrade } from 'src/features/createProduct/types/types';
import { UseFormReturn } from 'src/hooks';
import { Grade } from 'src/model/Grade';

interface GradeFormUI
  extends Pick<UseFormReturn, 'formData' | 'formErrors' | 'onChangeFormInput'> {
  addQuantiValue: (e: FormEvent) => void;
  grade: Grade[];
  removeGrade: (index: number) => void;
}

export const GradeForm: React.FC<GradeFormUI> = ({
  formData,
  formErrors,
  grade,
  onChangeFormInput,
  addQuantiValue,
  removeGrade,
}): JSX.Element => {
  return (
    <>
      <form>
        <h1 className="text-2xl font-bold text-gray-800 mb-4">
          Cadastrar Grade
        </h1>

        {/* Lista de QuantitValues */}
        <div className="grid grid-cols-1 md:grid-cols-4 grid-rows-3 md:grid-rows-1 gap-4">
          <InputText
            label="Tipo"
            type="text"
            id="atributo"
            value={formData[FormInputGrade.atributo]}
            onChange={(e) =>
              onChangeFormInput(FormInputGrade.atributo)(e.target.value)
            }
            error={formErrors.atributo && formErrors.atributo[0]}
            inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
            placeholder="Digite o atributo"
          />

          <InputText
            type="text"
            label="Definição"
            value={formData[FormInputGrade.value]}
            onChange={(e) =>
              onChangeFormInput(FormInputGrade.value)(e.target.value)
            }
            error={formErrors.value && formErrors.value[0]}
            inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
            placeholder="Digite a definição"
          />

          <InputText
            type="number"
            label="Quantidade"
            value={formData[FormInputGrade.quantity]}
            onChange={(e) =>
              onChangeFormInput(FormInputGrade.quantity)(e.target.value)
            }
            error={formErrors.quantity && formErrors.quantity[0]}
            inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
            placeholder="Digite a quantidade"
          />
          <div className="grid  content-center md:mt-5 ">
            <Button
              type="button"
              title="+ Adicionar"
              buttonStyle="text"
              size="sm"
              onClick={addQuantiValue}
              className="text-red-500"
            />
          </div>
        </div>
      </form>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {grade &&
          grade.length > 0 &&
          grade.map((grades, index) => (
            <div
              key={index}
              className="p-2 bg-white shadow-md rounded-lg border border-red-200 transition-transform hover:scale-102"
            >
              <div className="text-lg font-bold text-red-600 mb-1">
                <span className="font-semibold text-black">Tipo:</span>{' '}
                {grades.atributo}
              </div>
              <div className="text-sm text-red-600 mb-1">
                <span className="font-semibold text-black">Tamanho:</span>{' '}
                {grades.value}
              </div>
              <div className="text-sm text-red-600 mb-3">
                <span className="font-semibold  text-black">Quantidade:</span>{' '}
                {grades.quantity}
              </div>
              <Button
                type="button"
                title="- Excluir"
                buttonStyle="outline"
                size="sm"
                onClick={() => removeGrade(index)}
                className="text-red-500"
              />
            </div>
          ))}
      </div>
    </>
  );
};
