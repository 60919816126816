import React from 'react';
import { Route } from '../../navigation/Route';
import { CreateAccountScreen } from './screens/CreateAccount';

export const CREATEACCOUNT_ROUTES = {
  createAccount: '/create-account',
};
export const CreateAccountNavigation = (): JSX.Element => (
  <React.Fragment>
    <Route
      path={CREATEACCOUNT_ROUTES.createAccount}
      exact
      component={CreateAccountScreen}
    />
  </React.Fragment>
);
