import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { onlyNumbers } from '../../../../helpers/common';
import { decrypt, encrypt } from '../../../../helpers/crypt/crypt';
import {
  getCreditCardLabel,
  updateHolderName,
  updateMaskCVV,
  updateMaskCard,
  validateCVV,
} from '../../../../helpers/masks/cardNumber';
import { updateMask as cpfCnpjMask } from '../../../../helpers/masks/cpfCnpj';
import { updateMask as expirationDateMask } from '../../../../helpers/masks/expirationDate';
import validators from '../../../../helpers/validators';
import useForm from '../../../../hooks/useForm';
import Card from '../../../../model/Card';
import CardClient from '../../../../model/CardClient';
import CardClientCrypt from '../../../../model/CardClientCrypt';
import CardDb from '../../../../model/CardDb';
import { setLoading } from '../../../../redux/loading/loadingSlice';
import { api } from '../../../../services/api';
import { FormInputNameCreditCard } from '../../types';
import { CreditCardPaymentAddUI } from './ui';
import { useEffect, useState } from 'react';

export const CreditCardPaymentAddScreen = () => {
  //   const { loading } = useSelector(useLoading);
  const dispatch = useDispatch();
  const [cardSelected, setCardSelected] = useState<CardDb>();

  const {
    formData: formDataAddCreditCard,
    formErrors: formErrorsAddCreditCard,
    setErrors: setErrorsAddCreditCard,
    onChangeFormInput: onChangeFormInputAddCreditCard,
    isFormValid: isFormValidAddCreditCard,
    resetForm: resetFormAddCreditCard,
  } = useForm({
    initialData: {
      number: '',
      date: '',
      cvv: '',
      name: '',
      document: '',
    },
    validators: {
      number: [validators.required, validators.cardNumber],
      date: [validators.required, validators.cardExpirationDate],
      cvv: [validators.required, validators.cvv],
      name: [validators.required],
      document: [validators.required, validators.cpforcnpj],
    },
    formatters: {
      number: updateMaskCard,
      date: expirationDateMask,
      cvv: updateMaskCVV,
      name: updateHolderName,
      document: cpfCnpjMask,
    },
  });

  const onSubmitAddCreditCard = async (): Promise<void> => {
    if (isFormValidAddCreditCard()) {
      if (
        validateCVV(
          formDataAddCreditCard[FormInputNameCreditCard.number],
          formDataAddCreditCard[FormInputNameCreditCard.cvv]
        )
      ) {
        try {
          dispatch(setLoading(true));
          const expDate =
            formDataAddCreditCard[FormInputNameCreditCard.date].split('/');

          const card: Card = {
            holder: formDataAddCreditCard[FormInputNameCreditCard.name],
            number: onlyNumbers(
              formDataAddCreditCard[FormInputNameCreditCard.number]
            ),
            expMonth: expDate[0],
            expYear: `20${expDate[1]}`,
            securityCode: formDataAddCreditCard[FormInputNameCreditCard.cvv],
            document: onlyNumbers(
              formDataAddCreditCard[FormInputNameCreditCard.document]
            ),
            cardType: getCreditCardLabel(
              formDataAddCreditCard[FormInputNameCreditCard.number]
            ),
          };
          const encrypted = encrypt(JSON.stringify(card));

          const cardClientCrypt: CardClientCrypt = {
            data: encrypted,
          };
          const responseCardClient = await api.post<CardClient>(
            '/client-card',
            cardClientCrypt
          );
          //   await getUserData();
          const responseEncrypted = await api.get(
            `/client-card/find-one/${responseCardClient.data.id}`
          );
          const cardDB = JSON.parse(
            decrypt(responseEncrypted.data.data)
          ) as CardDb;
          setTimeout(() => {
            setCardSelected(cardDB);
          }, 500);
          resetFormAddCreditCard();
          //   onSetVisible(false);
          dispatch(setLoading(false));
          toast.success('Cartão adicionado com sucesso!');
          //eslint-disable-next-line
        } catch (error: any) {
          dispatch(setLoading(false));
          setErrorsAddCreditCard({
            ['document']: ['Algo deu errado'],
          });
          toast.error(error);
        }
      } else {
        toast.warn('Código de segurança inválido!');
      }
    }
  };

  useEffect(() => {
    //eslint-disable-next-line
    cardSelected && resetFormAddCreditCard();
    //eslint-disable-next-line
  }, []);

  return (
    <CreditCardPaymentAddUI
      formData={formDataAddCreditCard}
      formErrors={formErrorsAddCreditCard}
      onChangeFormInput={onChangeFormInputAddCreditCard}
      onSubmitRegister={onSubmitAddCreditCard}
    />
  );
};
