import React, { useState, useEffect, useCallback } from 'react';
import { Banners } from 'src/model/Banners';

interface CarouselBannerProps {
  slides: Banners[];
  hideIndicators?: boolean; // Ocultar indicadores de página
  hideControls?: boolean; // Ocultar controles de navegação
  className?: string;
  classNameImg?: string;
  goSlide?: (id: string) => void;
}

export const CarouselBannerUI: React.FC<CarouselBannerProps> = ({
  slides,
  hideIndicators = false,
  hideControls = false,
  className,
  classNameImg,
  goSlide,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showButtons, setShowButtons] = useState(false);

  // Variáveis para armazenar o ponto inicial e final do toque
  let touchStartX: number | null = null;
  let touchEndX: number | null = null;

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    touchStartX = e.touches[0].clientX;
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    touchEndX = e.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    if (touchStartX !== null && touchEndX !== null) {
      const swipeDistance = touchStartX - touchEndX;

      // Definir uma distância mínima para considerar como swipe
      const minSwipeDistance = 50;

      if (swipeDistance > minSwipeDistance) {
        // Swipe para a esquerda (próximo slide)
        nextSlide();
      } else if (swipeDistance < -minSwipeDistance) {
        // Swipe para a direita (slide anterior)
        prevSlide();
      }
    }

    // Resetar as variáveis de toque
    touchStartX = null;
    touchEndX = null;
  };

  const nextSlide = useCallback(() => {
    setCurrentIndex((prevIndex) =>
      prevIndex === slides.length - 1 ? 0 : prevIndex + 1
    );
  }, [slides.length]);

  const prevSlide = useCallback(() => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? slides.length - 1 : prevIndex - 1
    );
  }, [slides.length]);

  useEffect(() => {
    const interval = setInterval(nextSlide, 8000); // Altera a cada 6 segundos
    return () => clearInterval(interval); // Limpa intervalo ao desmontar
  }, [nextSlide]);

  return (
    <div
      className="relative w-full overflow-hidden"
      onMouseEnter={() => setShowButtons(true)}
      onMouseLeave={() => setShowButtons(false)}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      {/* Contêiner com proporção fixa */}
      <div className={`relative w-full ${className}`}>
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`absolute inset-0 transition-transform duration-700 ease-in-out ${
              index === currentIndex ? 'translate-x-0' : 'translate-x-full'
            }`}
          >
            {goSlide ? (
              <a onClick={() => goSlide(slide.id)} className="cursor-pointer">
                <img
                  src={slide.image}
                  alt={slide.altText}
                  className={`w-full h-full md:object-cover ${classNameImg}`}
                />
              </a>
            ) : (
              <img
                src={slide.image}
                alt={slide.altText}
                className="w-full h-full object-cover"
              />
            )}
          </div>
        ))}
      </div>

      {/* Indicadores de Página */}
      {!hideIndicators && (
        <div className="absolute z-30 flex space-x-3 bottom-2 left-1/2 transform -translate-x-1/2">
          {slides.map((_, index) => (
            <button
              key={index}
              type="button"
              onClick={() => setCurrentIndex(index)}
              className={`w-3 h-3 rounded-full transition-colors duration-300 ${
                index === currentIndex ? 'bg-red-300' : 'bg-gray-400'
              }`}
              aria-label={`Slide ${index + 1}`}
            ></button>
          ))}
        </div>
      )}

      {/* Controles de Navegação */}
      {!hideControls && showButtons && (
        <>
          <button
            type="button"
            className="absolute top-1/2 left-2 transform -translate-y-1/2 bg-white/30 hover:bg-white/50 text-gray-800 rounded-full p-2 shadow-lg hidden md:block"
            onClick={prevSlide}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M15 19l-7-7 7-7"
              />
            </svg>
            <span className="sr-only">Previous</span>
          </button>
          <button
            type="button"
            className="absolute top-1/2 right-2 transform -translate-y-1/2 bg-white/30 hover:bg-white/50 text-gray-800 rounded-full p-2 shadow-lg hidden md:block"
            onClick={nextSlide}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 5l7 7-7 7"
              />
            </svg>
            <span className="sr-only">Next</span>
          </button>
        </>
      )}
    </div>
  );
};
