import React from 'react';
import { Route } from 'src/navigation/Route';
import { SearchEnterpriseScreen } from './screens/SearchEnterprise';

export const SEARCHENTERPRISE_ROUTES = {
  itself: '/search-enterprise',
};

export const SearchEnterpriseNavigation = (): JSX.Element => (
  <React.Fragment>
    <Route
      exact
      path={SEARCHENTERPRISE_ROUTES.itself}
      component={SearchEnterpriseScreen}
      //   isPrivateRoute={true}
    />
  </React.Fragment>
);
