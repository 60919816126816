import React from 'react';
import { Route } from 'src/navigation/Route';
import { ReclamationScreen } from './screens/Reclamation';

export const RECLAMATION_ROUTES = {
  itself: '/reclamation',
};

export const ReclamationNavigation = (): JSX.Element => (
  <React.Fragment>
    <Route
      exact
      path={RECLAMATION_ROUTES.itself}
      component={ReclamationScreen}
      //   isPrivateRoute={true}
    />
  </React.Fragment>
);
