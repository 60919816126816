import React, { useEffect, useState } from 'react';
import { Product } from 'src/model/Product';
import { useDispatch } from 'react-redux';
import { setLoading } from 'src/redux/loading/loadingSlice';
import { api } from 'src/services/api';
import { Banners } from 'src/model/Banners';
import { ProductsSearchUI } from '../../components/ProductsUI';
import { useParams } from 'react-router-dom';

interface Params {
  keyword: string;
}
export const ProductsFindScreen: React.FC = (): JSX.Element => {
  const { keyword } = useParams<Params>();
  const [products, setProducts] = useState<Product[]>([]);
  const dispatch = useDispatch();
  const [banners, setBanners] = useState<Banners[]>([]);
  const [bannersMobile, setBannersMobile] = useState<Banners[]>([]);

  const getBannersHome = async (): Promise<void> => {
    dispatch(setLoading(true));
    try {
      const response = await api.get<Banners[]>(
        '/banner/find-all/PRIMARY_HOME'
      );
      if (response.status === 200) {
        console.log(response.data);
        setBanners(response.data);
      }
    } catch (error) {
      console.error('Erro ao buscar banners:', error);
    } finally {
      dispatch(setLoading(false));
    }
  };
  const getBannersHomeMobile = async (): Promise<void> => {
    dispatch(setLoading(true));
    try {
      const response = await api.get<Banners[]>(
        '/banner/find-all/PRIMARY_HOME_MOBILE'
      );
      if (response.status === 200) {
        console.log(response.data);
        setBannersMobile(response.data);
      }
    } catch (error) {
      console.error('Erro ao buscar banners:', error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getProduct = async (): Promise<void> => {
    if (keyword) {
      dispatch(setLoading(true));
      try {
        const response = await api.get<Product[]>(`/product/find/${keyword}`);
        console.log(response.data);
        if (response.status === 200) {
          setProducts(response.data);
        }
      } catch (error) {
        console.error('Erro ao buscar produtos:', error);
      } finally {
        dispatch(setLoading(false));
      }
    } else {
      console.error('Parâmetro keyword não fornecido');
    }
  };

  useEffect(() => {
    if (keyword) {
      getProduct();
    }
    // eslint-disable-next-line
  }, [keyword]);

  useEffect(() => {
    getBannersHome();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    getBannersHomeMobile();
    // eslint-disable-next-line
  }, []);

  return (
    <ProductsSearchUI
      banners={banners}
      bannersMobile={bannersMobile}
      product={products}
    />
  );
};
