import React from 'react';
import { Route } from 'src/navigation/Route';
import { ProductScreen } from './screens/Product';

export const PRODUCT_ROUTES = {
  itself: '/product/:id',
};

export const ProductNavigation = (): JSX.Element => (
  <React.Fragment>
    <Route
      exact
      path={PRODUCT_ROUTES.itself}
      component={ProductScreen}
      //   isPrivateRoute={true}
    />
  </React.Fragment>
);
