import React from 'react';
import { useParams } from 'react-router-dom';
import { CarouselBannerUI } from 'src/components/CarouselBanner';
import { ProductCard3 } from 'src/components/ProductCard3';
import { AuthWrapper } from 'src/features/core/auth/screen/AuthWrapper';
import { Banners } from 'src/model/Banners';
import { Product } from 'src/model/Product';

interface ProductsSearchUIProps {
  product: Product[];
  banners: Banners[];
  bannersMobile: Banners[];
  goSlide?: (id: string) => void;
}

interface Params {
  keyword: string;
}

export const ProductsSearchUI: React.FC<ProductsSearchUIProps> = ({
  product,
  banners,
  bannersMobile,
  goSlide,
}) => {
  const { keyword } = useParams<Params>();

  const filteredProducts = product.filter((p) =>
    p.name.toLowerCase().includes(keyword?.toLowerCase() || '')
  );

  return (
    <AuthWrapper>
      <>
        <div className="hidden w-full ">
          {CarouselBannerUI && (
            <CarouselBannerUI
              slides={banners}
              hideIndicators={false}
              hideControls={false}
              goSlide={goSlide}
              className="aspect-[4/2] md:aspect-[10/3]"
            />
          )}
        </div>
        <div className="hidden w-full ">
          {CarouselBannerUI && (
            <CarouselBannerUI
              slides={bannersMobile}
              hideIndicators={false}
              hideControls={false}
              goSlide={goSlide}
              className="aspect-[4/2] md:aspect-[10/3]"
            />
          )}
        </div>

        {filteredProducts.length === 0 ? (
          <div className="mt-5 text-center">
            <h2 className="text-lg sm:text-2xl font-semibold">
              Nenhum produto encontrado para &quot;{keyword}&quot;
            </h2>

            <p className="text-sm sm:text-lg">
              Por favor, tente outra busca ou verifique se o nome está correto.
            </p>
          </div>
        ) : (
          <div className="mt-5">
            <ProductCard3 products={filteredProducts} />
          </div>
        )}
      </>
    </AuthWrapper>
  );
};
