import React, { FormEvent } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'src/components/Button';
import { CategoryCarousel } from 'src/components/CategoryCarousel';
import { InputFile } from 'src/components/InputFile';
import { InputText } from 'src/components/InputText';
import { UseFormReturn } from 'src/hooks';
import { Category } from 'src/model/Category';
import { FormInputCategory } from '../../../types';

export interface NameFiles {
  [key: string]: string;
}

export interface CategoryCarouselManagerProps
  extends Pick<UseFormReturn, 'formData' | 'formErrors' | 'onChangeFormInput'> {
  formNameFiles?: NameFiles;
  categories: Category[];
  onSubmitRegister: (e: FormEvent) => void;
  onChangeFormFileInput: (
    inputName: string
  ) => (file: File | undefined) => void;
  onEditCategory: (value: string) => void;
  onDeleteCategory: (value: string) => void;
}

export const CategoryCarouselManagerUI: React.FC<
  CategoryCarouselManagerProps
> = ({
  categories,
  formData,
  formErrors,
  formNameFiles,
  onChangeFormInput,
  onSubmitRegister,
  onChangeFormFileInput,
  onEditCategory,
  onDeleteCategory,
}) => {
  const image_url64 = React.useRef<HTMLInputElement>(null);
  return (
    <div className="mb-4 space-y-4 grid grid-cols-1">
      <form onSubmit={onSubmitRegister} className="space-y-4">
        <InputText
          name="name"
          label="Nome da categoria"
          placeholder="Digite aqui..."
          maxLength={100}
          value={formData[FormInputCategory.name]}
          onChange={(e) =>
            onChangeFormInput(FormInputCategory.name)(e.target.value)
          }
          error={formErrors.name && formErrors.name[0]}
          inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
        />
        <InputFile
          refInput={image_url64}
          name="imageBase64"
          label="Upload da Imagem Utilize uma imagem de tamanho 160px Larg x 160px Alt Max 240px Larg 240px Alt"
          fileName={formNameFiles?.image_url}
          onChange={(e) =>
            onChangeFormFileInput(FormInputCategory.image_url)(
              (e.target as HTMLInputElement)?.files?.[0]
            )
          }
          error={formErrors.image_url && formErrors.image_url[0]}
          wrapperClass="file:border file:border-gray-300 file:rounded file:px-4 file:py-2"
        />
        <div>
          <Button
            type="submit"
            title="Criar Nova Categoria"
            buttonStyle="red"
            size="lg"
          />
        </div>
      </form>
      {/* Exibição das categorias inseridas */}
      <div className="max-h-[450px] border-b-[1px] overflow-auto mb-6">
        {categories.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {categories.map((category, index) => (
              <div
                key={index}
                className="relative border p-4 rounded-lg shadow-md"
              >
                <img
                  src={category.image}
                  alt={category.name}
                  className="w-full h-32 object-cover rounded mb-2"
                />
                <p>{category.name}</p>
                <Link to={category.name} className="text-blue-500 underline">
                  {category.name}
                </Link>
                <div className="flex justify-between mt-2">
                  <button
                    onClick={() => onEditCategory(category.id)}
                    className="text-yellow-500"
                  >
                    Editar
                  </button>
                  <button
                    onClick={() => onDeleteCategory(category.id)}
                    className="text-red-500"
                  >
                    Excluir
                  </button>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p>Nenhuma categoria adicionada ainda.</p>
        )}
      </div>

      {/* Preview do carrossel */}
      <div className="w-full flex justify-center items-center">
        <CategoryCarousel categories={categories} />
      </div>
    </div>
  );
};
