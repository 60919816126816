import React from 'react';
import { Link } from 'react-router-dom';
import { Banners } from 'src/model/Banners';

interface BannerListProps {
  bannerListData: Banners[];
}

export const BannerList: React.FC<BannerListProps> = ({ bannerListData }) => (
  <div
    className="flex md:justify-center overflow-x-auto scrollbar-hide md:px-4 mb-4 md:mb-0"
    style={{
      scrollbarWidth: 'none',
      msOverflowStyle: 'none',
    }}
  >
    <div
      className="flex md:items-center  w-full md:gap-10 gap-2"
      style={{
        minWidth: 'max-content',
      }}
    >
      {bannerListData &&
        Array.isArray(bannerListData) &&
        bannerListData.length > 0 &&
        bannerListData.map((banner, index) => (
          <Link
            key={index}
            to={banner.id}
            className="flex flex-col items-center justify-center text-center text-gray-800 md:scale-100 scale-[80%]"
          >
            <div className="flex justify-center items-center w-20  ">
              <img
                src={banner.image}
                alt={banner.altText}
                className="w-12 h-12 object-contain  bg-white rounded-md shadow-md"
              />
            </div>
            <h5 className="mt-2 text-sm leading-tight">{banner.altText}</h5>
          </Link>
        ))}
    </div>
  </div>
);
