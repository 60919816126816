// SocialLinks.tsx
import React from 'react';
import { ReactComponent as InstagramIcon } from '../../assets/icons/instagram.svg';
import { ReactComponent as TiktokIcon } from '../../assets/icons/tiktok.svg';
import { ReactComponent as WhatsappIcon } from '../../assets/icons/whatsapp.svg';
import { ReactComponent as YoutubeIcon } from '../../assets/icons/youtube.svg';

interface SocialLinksProps {
  links: {
    instagram: string;
    whatsapp: string;
    tiktok: string;
    youtube: string;
  };
}

const SocialLinks: React.FC<SocialLinksProps> = ({ links }) => {
  return (
    <div className="flex gap-2">
      <a href={links.instagram} target="_blank" rel="noopener noreferrer">
        <InstagramIcon className="bg-white p-1 rounded-full w-6 h-6" />
      </a>
      <a href={links.whatsapp} target="_blank" rel="noopener noreferrer">
        <WhatsappIcon className="bg-white p-1 rounded-full w-6 h-6" />
      </a>
      <a href={links.tiktok} target="_blank" rel="noopener noreferrer">
        <TiktokIcon className="bg-white p-1 rounded-full w-6 h-6" />
      </a>
      <a href={links.youtube} target="_blank" rel="noopener noreferrer">
        <YoutubeIcon className="bg-white p-1 rounded-full w-6 h-6" />
      </a>
    </div>
  );
};

export default SocialLinks;
