import React from 'react';

// Interface para as notificações
interface Notification {
  id: number;
  type: string;
  message: string;
  orderId?: string;
  date: string;
  time: string;
  detailsUrl?: string;
  actionLabel?: string;
}

// Componente para exibir notificações de pedidos, novidades, etc.
const notifications: Notification[] = [
  {
    id: 1,
    type: 'Pedido concluído',
    message: 'Ei! Você ainda não avaliou seu pedido!',
    orderId: '241001EBWHQ0KP',
    date: '15/10/2024',
    time: '03:18',
    actionLabel: 'Avaliar Agora',
  },
  {
    id: 2,
    type: 'Nova oferta',
    message:
      'terravitta1 fez uma oferta para a solicitação de reembolso. Se aceitar, você receberá o valor de R$10,97.',
    orderId: '2410140GH2MMSFM',
    date: '14/10/2024',
    time: '17:38',
    detailsUrl: '#',
    actionLabel: 'Ver Detalhes',
  },
  {
    id: 3,
    type: 'Devolução aprovada',
    message:
      'Sua solicitação de devolução foi aprovada. Siga as instruções para devolução até 18/10/2024.',
    orderId: '2410140GH2MMSFM',
    date: '13/10/2024',
    time: '22:21',
    detailsUrl: '#',
    actionLabel: 'Ver Detalhes',
  },
  {
    id: 4,
    type: 'Pacote entregue',
    message: 'Seu pacote foi entregue com sucesso. Pedido: BR240609433212YS',
    orderId: '241005P45CGP72',
    date: '11/10/2024',
    time: '15:10',
    detailsUrl: '#',
    actionLabel: 'Ver Detalhes',
  },
];

const NotificationComponentMenor: React.FC = () => {
  return (
    <div className="mt-1 mb-1">
      {/* Lista de notificações */}
      {notifications.map((notification) => (
        <div
          key={notification.id}
          className="border-b border-red-200  bg-white p-3"
        >
          <div className="flex justify-between items-center mb-2 flex-wrap">
            <h3 className="font-semibold text-md ">
              {/* Tamanho do texto ajustado */}
              {notification.type}
            </h3>
            <p className="text-xs text-gray-500 ">
              {notification.date} {notification.time}
            </p>
          </div>
          <p className="text-gray-600 text-sm">{notification.message}</p>
          {/* Renderiza o pedido somente se orderId existir */}
          {notification.orderId && (
            <p className="text-gray-600 text-sm">
              Pedido:{' '}
              <span className="text-black text-sm">{notification.orderId}</span>
            </p>
          )}
          <div className="flex justify-end mt-2">
            {notification.detailsUrl && (
              <a
                href={notification.detailsUrl}
                className="text-blue-500 text-sm mr-4"
              >
                {notification.actionLabel}
              </a>
            )}
            {notification.actionLabel && !notification.detailsUrl && (
              <button className="bg-red-500 text-white px-2 py-1 text-xs rounded">
                {notification.actionLabel}
              </button>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default NotificationComponentMenor;
