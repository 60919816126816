import React from 'react';
import { useHistory } from 'react-router-dom';
import { FaEdit, FaPause, FaPlay, FaTrashAlt } from 'react-icons/fa';

// Interface for products
interface Product {
  id: string;
  name: string;
  imageUrl: string;
  lastModified: string;
  online: boolean;
  stock: string;
  price: string;
}

// Initial products list
const initialProducts: Product[] = [
  {
    id: '1',
    name: 'Proteína Texturizada de Soja Vegana 1kg',
    imageUrl: 'https://via.placeholder.com/100',
    lastModified: '15/10/2024',
    online: true, // Initially online
    stock: '100',
    price: 'R$ 29,90',
  },
  {
    id: '2',
    name: 'Gengibre Moído Importado 250g',
    imageUrl: 'https://via.placeholder.com/100',
    lastModified: '12/10/2024',
    online: false, // Initially offline
    stock: '50',
    price: 'R$ 19,90',
  },
  {
    id: '3',
    name: 'Gengibre Moído Importado 250g',
    imageUrl: 'https://via.placeholder.com/100',
    lastModified: '12/10/2024',
    online: false, // Initially offline
    stock: '50',
    price: 'R$ 19,90',
  },
];

const ProductManager: React.FC = () => {
  const history = useHistory();
  const [products, setProducts] = React.useState<Product[]>(initialProducts);

  // Handle edit product
  const handleEdit = (id: string) => {
    history.push(`/editar-produto/${id}`);
  };

  // Toggle product online/offline status
  const handleToggleOnlineStatus = (id: string) => {
    setProducts((prevProducts) =>
      prevProducts.map((product) =>
        product.id === id
          ? { ...product, online: !product.online } // Toggle online status
          : product
      )
    );
  };

  // Delete product
  const handleDelete = (id: string) => {
    const confirmed = window.confirm(
      'Tem certeza que deseja deletar o produto?'
    );
    if (confirmed) {
      setProducts(products.filter((product) => product.id !== id));
    }
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {products.map((product) => (
        <div
          key={product.id}
          className="border border-gray-100 rounded-sm shadow-sm p-4 bg-white relative"
        >
          {/* Status Indicator */}
          <div
            className={`absolute top-2 right-2 w-4 h-4 rounded-full ${
              product.online ? 'bg-green-500' : 'bg-yellow-500'
            }`}
            title={product.online ? 'Online' : 'Offline'}
          ></div>

          {/* Product Image */}
          <img
            src={product.imageUrl}
            alt={product.name}
            className="w-full h-40 object-cover mb-4"
          />

          {/* Product Name */}
          <h2 className="font-semibold text-lg mb-1 text-gray-800">
            {product.name}
          </h2>

          {/* Valor preço */}
          <h2 className="font-bold text-lg mb-1 text-gray-800">
            {product.price}
          </h2>

          {/* Quantidade de  produtos */}

          <p className="text-sm text-gray-500 mb-1">
            Estoque:{' '}
            <span className="text-red-500 font-semibold">{product.stock}</span>
          </p>
          {/* Last modified date */}
          <p className="text-sm text-gray-500 mb-1">
            Última modificação: {product.lastModified}
          </p>
          {/* Action buttons */}
          <div className="w-full flex justify-around items-center space-x-2">
            <button
              onClick={() => handleEdit(product.id)}
              className="flex items-center text-md text-blue-500 hover:text-blue-600 transition-transform duration-300 transform hover:scale-105"
            >
              <FaEdit className="mr-1" /> Editar
            </button>

            <button
              onClick={() => handleToggleOnlineStatus(product.id)}
              className={`flex items-center text-md transition-transform duration-300 transform hover:scale-105 ${
                product.online
                  ? 'text-yellow-500 hover:text-yellow-600' // Yellow for Online action (current is Offline)
                  : 'text-green-500 hover:text-green-600' // Green for Offline action (current is Online)
              }`}
            >
              {product.online ? (
                <>
                  <FaPause className="mr-1" /> Offline
                </>
              ) : (
                <>
                  <FaPlay className="mr-1" /> Online
                </>
              )}
            </button>

            <button
              onClick={() => handleDelete(product.id)}
              className="flex items-center text-md text-red-500 hover:text-red-600 transition-transform duration-300 transform hover:scale-105"
            >
              <FaTrashAlt className="mr-1" /> Deletar
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProductManager;
