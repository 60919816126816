import { Address } from 'src/model/Address';

// Estrutura do formulário de entrega
export interface DeliveryFormData {
  nameShipping: string;
  phoneShipping: string;
  email: string;
  complementShipping: string;
  companyShippingName?: string;
  document: string;
  cnpj?: string;
  savedAddresses: Address[];
  addressType: 'Personal' | 'Company';
  zipCode: string;
  state: string;
  city: string;
  street: string;
  complement: string;
  country: string;
  number: string;
  neighborhood: string;
}

// Enum para acessar os campos do formulário de entrega
export enum FormRegisterDelivery {
  nameShipping = 'namenameShipping',
  phoneShipping = 'phoneShipping',
  email = 'email',
  complementShipping = 'complementShipping',
  companyShippingName = 'companyShippingName',
  document = 'document',
  cnpj = 'cnpj',
  savedAddresses = 'savedAddresses',
  addressType = 'addressType',
  zipCode = 'zipCode',
  state = 'state',
  city = 'city',
  street = 'street',
  complement = 'complement',
  country = 'country',
  number = 'number',
  neighborhood = 'neighborhood',
}

export enum FormInputNameCreditCard {
  number = 'number',
  date = 'date',
  cvv = 'cvv',
  name = 'name',
  document = 'document',
}
