// src/components/PaymentFormUI.tsx
import React, { FormEvent } from 'react';
import { Button } from 'src/components/Button';
import { InputText } from 'src/components/InputText';
import { FormInputNameCreditCard } from 'src/features/userProfile/types';
import { UseFormReturn } from 'src/hooks';
import CardClient from 'src/model/CardClient';

interface PaymentFormUIProps
  extends Pick<UseFormReturn, 'formData' | 'formErrors' | 'onChangeFormInput'> {
  selectedCard?: number;
  card?: CardClient[];
  addNewCard: boolean;
  onSelectCard: (index: number) => void;
  onAddCard: () => void;
  onSubmitRegister: (e: FormEvent) => void;
}

export const PaymentFormUI: React.FC<PaymentFormUIProps> = ({
  card,
  formData,
  formErrors,
  selectedCard,
  addNewCard,
  onSelectCard,
  onAddCard,
  onChangeFormInput,
  onSubmitRegister,
}) => (
  <div className="bg-white p-6 rounded-lg shadow-md w-full border border-gray-200">
    <h2 className="text-lg font-semibold mb-4">Detalhes de Pagamento</h2>

    {/* Seção de Cartões Salvos */}
    <>
      <Button
        buttonStyle="red"
        title="Adicionar Cartão"
        onClick={onAddCard}
        type="button"
        className="mb-2"
      />
    </>
    <div className="mb-4">
      {card && card.length > 0 ? (
        card.map((card, index) => (
          <>
            <h3 className="text-sm font-semibold mb-2">Cartões Salvos</h3>
            <div className="space-y-2">
              <div
                key={index}
                className={`flex justify-between items-center p-2 border rounded-lg cursor-pointer ${
                  selectedCard === index ? 'border-red-500' : 'border-gray-300'
                }`}
                onClick={() => onSelectCard(index)}
              >
                <div>
                  <p className="text-sm font-medium">{card.cardNumber}</p>
                  <p className="text-xs text-gray-600">
                    {card.cardNumber} — {card.cardExpirationDate}
                  </p>
                </div>
                {selectedCard === index && (
                  <span className="text-red-500 text-xs font-semibold">
                    Selecionado
                  </span>
                )}
              </div>
            </div>
          </>
        ))
      ) : (
        <> Nem um cartão cadastrado</>
      )}
    </div>
    {addNewCard && (
      <form onSubmit={onSubmitRegister}>
        <div className="mb-4">
          <InputText
            name="cardHolderName"
            label="Nome completo do titular do cartão"
            value={formData[FormInputNameCreditCard.name]}
            onChange={(e) =>
              onChangeFormInput(FormInputNameCreditCard.name)(e.target.value)
            }
            error={formErrors.name && formErrors.name[0]}
            placeholder="Digite seu nome completo"
            inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
          />
        </div>
        <div className="mb-4">
          <InputText
            name="cardNumber"
            label="Número do Cartão"
            value={formData[FormInputNameCreditCard.number]}
            onChange={(e) =>
              onChangeFormInput(FormInputNameCreditCard.number)(e.target.value)
            }
            error={formErrors.number && formErrors.number[0]}
            placeholder="0000 0000 0000 0000"
            inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
          />
        </div>
        <div className="grid grid-cols-2 gap-4 mb-4">
          <div>
            <InputText
              name="expiryDate"
              label="Data de Expiração"
              value={formData[FormInputNameCreditCard.date]}
              onChange={(e) =>
                onChangeFormInput(FormInputNameCreditCard.date)(e.target.value)
              }
              error={formErrors.date && formErrors.date[0]}
              placeholder="01/23"
              inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
            />
          </div>
          <div>
            <InputText
              name="cvv"
              label="CVV"
              value={formData[FormInputNameCreditCard.cvv]}
              onChange={(e) =>
                onChangeFormInput(FormInputNameCreditCard.cvv)(e.target.value)
              }
              error={formErrors.cvv && formErrors.cvv[0]}
              placeholder="CVV"
              inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
            />
          </div>
        </div>
        <Button
          type="submit"
          title="Escolher Método de Pagamento"
          buttonStyle="red"
          className="w-full"
        />
      </form>
    )}
  </div>
);
