import React, { useState, ReactNode, useRef } from 'react';

interface Dropdown2Props {
  triggerContent: ReactNode; // Pode ser uma imagem, ícone ou texto
  userName?: string;
  userEmail?: string;
  children?: ReactNode;
  notificationsCount?: number; // Número de notificações
  width?: string;
}

export const Dropdown2: React.FC<Dropdown2Props> = ({
  triggerContent,
  userName,
  userEmail,
  children,
  notificationsCount = 0, // Valor padrão 0 (sem notificações)
  width = '200px',
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null); // Usado para armazenar o timeout

  // Abre o dropdown
  const openDropdown = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current); // Cancela o timeout se o mouse voltar a entrar
    }
    setDropdownOpen(true);
  };

  // Fecha o dropdown com atraso
  const closeDropdownWithDelay = () => {
    timeoutRef.current = setTimeout(() => {
      setDropdownOpen(false);
    }, 300); // Ajusta o tempo de desaparecimento (em milissegundos)
  };

  return (
    <div
      className="relative"
      onMouseEnter={openDropdown} // Abre o dropdown ao passar o mouse
      onMouseLeave={closeDropdownWithDelay} // Fecha o dropdown com atraso
    >
      {/* Botão de Trigger - Imagem, Ícone ou Texto */}
      <button
        type="button"
        className="flex text-sm rounded-full relative"
        aria-expanded={dropdownOpen}
      >
        <span className="sr-only"></span>
        {triggerContent}
        {/* Tooltip de Notificações (bolinha vermelha com número) */}
        {notificationsCount > 0 && (
          <span className="absolute -left-3 -top-1 block h-4 w-4 rounded-full bg-white text-red-600 text-xs items-center justify-center">
            {notificationsCount}
          </span>
        )}
      </button>

      {dropdownOpen && (
        <div
          className="absolute right-0 z-50 mt-2  origin-top-right rounded-md shadow-lg bg-white divide-y divide-gray-100"
          style={{
            width,
          }}
        >
          {userName && (
            <span className="block text-sm text-black px-4 py-2">
              {userName}
            </span>
          )}
          {userEmail && (
            <span className="block text-sm text-gray-500 truncate px-4 ">
              {userEmail}
            </span>
          )}

          {/* Área com limite de altura e scroll */}
          <div className="max-h-96  overflow-y-auto">{children}</div>
        </div>
      )}
    </div>
  );
};
