import React from 'react';
import { Route } from 'src/navigation/Route';
import { ElogioScreen } from './screens/Elogio';

export const ELOGIO_ROUTES = {
  itself: '/elogio',
};

export const ElogioNavigation = (): JSX.Element => (
  <React.Fragment>
    <Route
      exact
      path={ELOGIO_ROUTES.itself}
      component={ElogioScreen}
      //   isPrivateRoute={true}
    />
  </React.Fragment>
);
