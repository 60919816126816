import React, { FormEvent } from 'react';
import { CalendarGrey } from 'src/assets/icons';
import { Button } from 'src/components/Button';
import { InputText } from 'src/components/InputText';
import { OptionProps, Select } from 'src/components/Select';
import { FormInputNameRegister } from 'src/features/core/auth/types';
import { UseFormReturn } from 'src/hooks';
import Client from 'src/model/User';

export interface NameFiles {
  [key: string]: string;
}
interface UsersManagerProps
  extends Pick<UseFormReturn, 'formData' | 'formErrors' | 'onChangeFormInput'> {
  users: Client[];
  editingUser: boolean;
  searchTerm: string;
  onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onEditUser: (client: Client) => void;
  onDeleteUser: (id: string) => void;
  onSaveEdit: (e: FormEvent) => void;
}

export const UsersManager: React.FC<UsersManagerProps> = ({
  users,
  editingUser,
  formErrors,
  formData,
  searchTerm,
  onSearchChange,
  onEditUser,
  onDeleteUser,
  onSaveEdit,
  onChangeFormInput,
}) => {
  const genderOption: OptionProps[] = [
    { value: 'MALE', label: 'Masculino' },
    { value: 'FAMELE', label: 'Feminino' },
    { value: 'OTHER', label: 'Não Declarar' },
  ];
  const roleOption: OptionProps[] = [
    { value: 'USER', label: 'User' },
    { value: 'ADMIN', label: 'Admin' },
    { value: 'SELLER', label: 'Seller' },
  ];
  return (
    <div className="w-full mt-4">
      {/* Barra de Pesquisa */}
      <div className="mb-4">
        <InputText
          name="search"
          label="Pesquisar Usuários"
          placeholder="Digite o nome ou email..."
          value={searchTerm}
          onChange={onSearchChange}
          inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
        />
      </div>
      {/* Lista de Usuários */}
      <div className="overflow-x-auto">
        <table className="min-w-full table-auto bg-white border rounded-lg shadow-md">
          <thead>
            <tr className="text-left bg-red-100">
              <th className="p-2">Nome</th>
              <th className="p-2">Documento</th>
              <th className="p-2">Email</th>
              <th className="p-2">Telefone</th>
              <th className="p-2">Nível</th>
              <th className="p-2">Ações</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.id} className="border-t border-red-100">
                <td className="p-2">{user.name}</td>
                <td className="p-2">{user.cpf}</td>
                <td className="p-2">{user.email}</td>
                <td className="p-2">{user.cellPhone}</td>
                <td className="p-2">{user.type}</td>
                <td className="p-2 flex space-x-2">
                  <button
                    className="text-blue-500 hover:underline"
                    onClick={() => onEditUser(user)}
                  >
                    Editar
                  </button>
                  <button
                    className="text-red-500 hover:underline"
                    onClick={() => onDeleteUser(user.id)}
                  >
                    Deletar
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* Formulário de Edição */}
      {editingUser && (
        <form onSubmit={onSaveEdit}>
          <div className="mt-6 p-6 border bg-white rounded-lg shadow-md">
            <h3 className="font-semibold mb-4">Editar Usuário</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="flex flex-col space-y-2">
                <InputText
                  name="name"
                  label="Nome"
                  placeholder="Digite o nome..."
                  value={formData[FormInputNameRegister.name]}
                  onChange={(e) =>
                    onChangeFormInput(FormInputNameRegister.name)(
                      e.target.value
                    )
                  }
                  error={formErrors.name && formErrors.name[0]}
                  inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
                />
              </div>

              <div className="flex flex-col space-y-2">
                <InputText
                  name="document"
                  label="Documento"
                  placeholder="Digite o CPF..."
                  value={formData[FormInputNameRegister.document]}
                  onChange={(e) =>
                    onChangeFormInput(FormInputNameRegister.document)(
                      e.target.value
                    )
                  }
                  error={formErrors.document && formErrors.document[0]}
                  inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
                />
              </div>

              <div className="flex flex-col space-y-2">
                <InputText
                  name="email"
                  label="Email"
                  placeholder="Digite o Email..."
                  value={formData[FormInputNameRegister.email]}
                  onChange={(e) =>
                    onChangeFormInput(FormInputNameRegister.email)(
                      e.target.value
                    )
                  }
                  error={formErrors.email && formErrors.email[0]}
                  inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
                />
              </div>

              <div className="flex flex-col space-y-2">
                <Select
                  name="gender"
                  label="Sexo"
                  value={formData[FormInputNameRegister.gender]}
                  onChange={(e) =>
                    onChangeFormInput(FormInputNameRegister.gender)(
                      e.target.value
                    )
                  }
                  error={formErrors.gender && formErrors.gender[0]}
                  options={genderOption}
                  selectClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
                />
              </div>

              <div className="flex flex-col space-y-2">
                <InputText
                  name="date"
                  label="Data de Nascimento"
                  placeholder="DD/MM/AAAA"
                  value={formData[FormInputNameRegister.date]}
                  onChange={(e) =>
                    onChangeFormInput(FormInputNameRegister.date)(
                      e.target.value
                    )
                  }
                  error={formErrors.date && formErrors.date[0]}
                  inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
                  renderForward={
                    <button
                      type="button"
                      className="absolute right-4 top-[50%]"
                    >
                      <img src={CalendarGrey} alt="Selecionar data" />
                    </button>
                  }
                />
              </div>

              <div className="flex flex-col space-y-2">
                <InputText
                  name="phone"
                  label="Telefone"
                  placeholder="Digite numero de telefone..."
                  value={formData[FormInputNameRegister.phone]}
                  onChange={(e) =>
                    onChangeFormInput(FormInputNameRegister.phone)(
                      e.target.value
                    )
                  }
                  error={formErrors.phone && formErrors.phone[0]}
                  inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
                />
              </div>

              {/* Nível de Acesso */}
              <div className="flex flex-col space-y-2">
                <Select
                  name="role"
                  label="Nível de Acesso"
                  value={formData[FormInputNameRegister.role]}
                  onChange={(e) =>
                    onChangeFormInput(FormInputNameRegister.role)(
                      e.target.value
                    )
                  }
                  error={formErrors.role && formErrors.role[0]}
                  options={roleOption}
                  selectClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
                />
              </div>
            </div>
            <div className="w-full flex justify-center mt-6">
              <Button
                type="submit"
                title="Salvar"
                buttonStyle="red"
                size="lg"
                className="w-[30%]"
              />
            </div>
          </div>
        </form>
      )}
    </div>
  );
};
